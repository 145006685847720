const INSTRUCTIONS = [
  {
    text:
      "Utilizar um <strong>celular com câmera</strong> e, de preferência, com <strong>fones de ouvido</strong> (para minimizar ruídos);",
    icon: "stay_current_portrait",
  },
  {
    text:
      "Escolher um <strong>lugar silêncioso</strong>, confortável, claro (para que o profissional possa te ver) e <strong>reservado</strong>, para garantir o sigilo da consulta;",
    icon: "hearing",
  },
  {
    text:
      "Para pacientes <strong>menores de 18 anos</strong>, as teleconsultas serão realizadas somente com a autorização do responsável;",
    icon: "supervised_user_circle",
  },
  {
    text:
      "Certifique-se que o aparelho celular e/ou computador esta carregado e com conexão a internet;",
    icon: "battery_alert",
  },
  {
    text: "Qualquer dúvida, é só nos perguntar. Tenha um ótima consulta;",
    icon: "live_help",
  },
];

export default INSTRUCTIONS;
