import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  Select,
  MenuItem,
  Divider,
  InputLabel,
  FormControl,
  FormHelperText,
  Typography,
  Tooltip,
  Button,
  Icon
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Content from "../../../components/Content";
import Calendar from "react-calendar";
import pt_BR from "../../../locale/material-table-locale";
import MaterialTable from "@material-table/core";
import PatientRoomLInkDialog from "../../../components/PatientRoomLInkDialog";
import {
  getScheduleByDate,
} from "../../../services/endpoints/scheduling/endpoints";
import {
  defaultAlert
} from "../../../helpers/validation/alerts";
import { getProfessional } from "../../../services/endpoints/professionals/endpoints";
import { getCalendar } from "../../../services/endpoints/availability/endpoint";
import {
  getFormatedDateUI,
  getFormatedDateTimeUI,
  getFormatedDateSystem,
} from "../../../helpers/dateHelpers";
import {
  exportXlsx,
  exportPDF,
} from "../../../services/endpoints/report/endpoints";
import { getMobilePhoneBrFormated } from "../../../helpers/stringHelpers";
import DropDownMenuStatus from "../../../components/DropDownMenuStatus";
import DropDownMenuActionsSchedule from "../../../components/DropDownMenuActionsSchedule";
import { useUserData } from "../../../context/UserContext";

export default function ListSchedules() {
  const classes = useStyles();
  const history = useHistory();
  const [schedule, setSchedule] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [isLoadding, setIsLoading] = useState(true);
  const [enblaEmbed, setEnblaEmbed] = useState(true);
  const [isFirstRun, setIsFirstRun] = useState(true);
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [coloredDates, setColoredDates] = useState({
    scheduled: [],
    free: [],
  });
  const [patienteRoomLink, setPatienteRoomLink] = useState({
    open: false,
    link: '',
  });
  const [userIsHelthcareProfessional, setUserIsHelthcareProfessional] = useState(false);
  const [idProfessional, setIdProfessional] = useState("");
  const { userData } = useUserData();

  useEffect(() => {
    const fetchSchedule = async () => {
      if (userData.is_healthcare_professional) {
        setUserIsHelthcareProfessional(true);
        setIdProfessional(userData.id_professional);
        await getScheduleByDate(
          getFormatedDateSystem(calendarDate),
          userData.id_professional
        ).then(({ data }) => {
          setSchedule(data.list_agenda);
        });
      } else {
        if (idProfessional) {
          await getScheduleByDate(
            getFormatedDateSystem(calendarDate),
            idProfessional
          ).then(({ data }) => {
            setSchedule(data.list_agenda);
          });
        } else {
          if (!isFirstRun) {
            defaultAlert(
              "Para pesquisar uma data, primeiro selecione o profissional de saúde!",
              true,
              "OK",
              "question",
              "Nenhum profissional de saúde selecionado"
            );
          }
        }
      }
      setIsFirstRun(false);
      setIsLoading(false);
    };
    fetchSchedule();
  }, [calendarDate, idProfessional, userData]);

  useEffect(() => {
    const fetchAllProfessionals = async () => {
      await getProfessional()
        .then(({ data }) => {
          setProfessionals(data.list_prestador);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };
    fetchAllProfessionals();
    return () => {};
  }, []);

  useEffect(() => {
    const fetchCalendar = async () => {
      if (userData.is_healthcare_professional) {
        await getCalendar()
          .then(({ data }) => {
            setColoredDates({
              scheduled: data.calendario.list_atende,
              free: data.calendario.list_nao_atende,
            });
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      } else {
        await getCalendar(idProfessional)
          .then(({ data }) => {
            setColoredDates({
              scheduled: data.calendario.list_atende,
              free: data.calendario.list_nao_atende,
            });
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      }
    };
    fetchCalendar();
    return () => {};
  }, [calendarDate, idProfessional]);

  useEffect(() => {
    const now = new Date();
    const calendar = new Date(calendarDate);
    if (
      now.getFullYear() === calendar.getFullYear() &&
      now.getDate() === calendar.getDate() &&
      now.getMonth() + 1 === calendar.getMonth() + 1 &&
      idProfessional
    ) {
      setEnblaEmbed(true);
    } else {
      setEnblaEmbed(false);
    }
    return () => {};
  }, [calendarDate, idProfessional]);

  function colorDate(date) {
    if (coloredDates.scheduled.length > 0) {
      if (coloredDates.scheduled.find((d) => d === getFormatedDateUI(date))) {
        return "date-with-schedule";
      } else if (coloredDates.free.length > 0) {
        if (coloredDates.free.find((d) => d === getFormatedDateUI(date))) {
          return "date-without-schedule";
        }
      }
    } else {
      if (coloredDates.free.length > 0) {
        if (coloredDates.free.find((d) => d === getFormatedDateUI(date))) {
          return "date-without-schedule";
        }
      }
    }
  }

  function handleEmbedding() {
    if (idProfessional && calendarDate) {
      const now = new Date();
      const timeStrig = (
        now.getHours().toString().padStart(2, 0) +
        ":" +
        now.getMinutes().toString().padStart(2, 0)
      ).toString();
      history.push(
        `/scheduling/embed/${idProfessional}/${getFormatedDateSystem(
          calendarDate
        )}/${timeStrig}`
      );
    }
  }

  async function handleRefresh() {
    setIsLoading(true);
    if (userData.is_healthcare_professional) {
      await getScheduleByDate(
        getFormatedDateSystem(calendarDate),
        userData.id_professional
      ).then(({ data }) => {
        setSchedule(data.list_agenda);
      });
    } else {
      if (idProfessional) {
        await getScheduleByDate(
          getFormatedDateSystem(calendarDate),
          idProfessional
        ).then(({ data }) => {
          setSchedule(data.list_agenda);
        });
      }
    }
    setIsLoading(false);
  }

  async function handleExportXlsx() {
    setIsLoading(true);
    if (userData.is_healthcare_professional) {
      await exportXlsx(
        getFormatedDateSystem(calendarDate),
        userData.id_professional
      ).then(({ data }) => {
        const blobContent = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const generatedUrl =
          window.URL && window.URL.createObjectURL
            ? window.URL.createObjectURL(blobContent)
            : window.webkitURL.createObjectURL(blobContent);

        const tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = generatedUrl;
        tempLink.setAttribute("download", "Relatorio de agendamentos.xlsx");

        if (typeof tempLink.download === "undefined") {
          tempLink.setAttribute("target", "_blank");
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        setTimeout(function () {
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(generatedUrl);
        }, 200);
      });
    } else {
      if (idProfessional) {
        await exportXlsx(
          getFormatedDateSystem(calendarDate),
          idProfessional
        ).then(({ data }) => {
          const blobContent = new Blob([data], {
            type: "application/vnd.ms-excel",
          });

          const generatedUrl =
            window.URL && window.URL.createObjectURL
              ? window.URL.createObjectURL(blobContent)
              : window.webkitURL.createObjectURL(blobContent);

          const tempLink = document.createElement("a");
          tempLink.style.display = "none";
          tempLink.href = generatedUrl;
          tempLink.setAttribute("download", "Relatório de agendamento.xlsx");

          if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
          }

          document.body.appendChild(tempLink);
          tempLink.click();

          setTimeout(function () {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(generatedUrl);
          }, 200);
        });
      }
    }
    setIsLoading(false);
  }

  async function handleExportPDF() {
    setIsLoading(true);
    if (userData.is_healthcare_professional) {
      await exportPDF(
        getFormatedDateSystem(calendarDate),
        userData.id_professional
      ).then(({ data }) => {
        const blobContent = new Blob([data], { type: "text/html" });

        const generatedUrl =
          window.URL && window.URL.createObjectURL
            ? window.URL.createObjectURL(blobContent)
            : window.webkitURL.createObjectURL(blobContent);
        window.open(generatedUrl, "", "width=800,height=600");
      });
    } else {
      if (idProfessional) {
        await exportPDF(
          getFormatedDateSystem(calendarDate),
          idProfessional
        ).then(({ data }) => {
          const blobContent = new Blob([data], { type: "text/html" });

          const generatedUrl =
            window.URL && window.URL.createObjectURL
              ? window.URL.createObjectURL(blobContent)
              : window.webkitURL.createObjectURL(blobContent);
          window.open(generatedUrl, "", "width=800,height=600");
        });
      }
    }
    setIsLoading(false);
  }

  const COLUMNS = [
    {
      title: "Data e Hora",
      field: "data_hora",
      render: (data) => getFormatedDateTimeUI(data.data_hora),
      customFilterAndSearch: (term, rowData) =>
        getFormatedDateTimeUI(rowData.data_hora).indexOf(term) > -1,
    },
    {
      title: "Nome e Nome Social",
      field: "nome_social",
      render: (data) => (
        <Box
          display="flex"
          flexDirection="row"
          style={{ height: "35px", alignItems: "center" }}
        >
          <Box p={1}>
            {data.paciente.nome_social !== ""
              ? data.paciente.nome_social
              : data.paciente.nome}
          </Box>
          {data.importado === "1" ? (
            <Box p={0.5} style={{ paddingLeft: "-10px", paddingTop: 12 }}>
              <div>
                <Tooltip title="Agendado via Saúde Simples">
                  <CheckBoxIcon
                    style={{ justifyContent: "center", color: "green" }}
                  />
                </Tooltip>
              </div>
            </Box>
          ) : (
            ""
          )}
        </Box>
      ),
      customFilterAndSearch: (term, rowData) => {
        if (rowData.paciente?.nome_social) {
          return (
            rowData.paciente.nome_social
              .toUpperCase()
              .indexOf(term.toUpperCase()) > -1
          );
        } else if (rowData.paciente?.nome) {
          return (
            rowData.paciente.nome.toUpperCase().indexOf(term.toUpperCase()) > -1
          );
        } else {
          return null;
        }
      },
    },
    {
      title: "Médico",
      field: "nome_prestador",
      render: (data) => data.nome_prestador,
      customFilterAndSearch: (term, rowData) =>
        rowData.nome_prestador
          ? rowData.nome_prestador.toUpperCase().indexOf(term.toUpperCase()) >
            -1
          : null,
    },
    {
      title: "Celular",
      field: "celular",
      render: (data) =>
        data.paciente ? getMobilePhoneBrFormated(data.paciente.celular) : "",
      customFilterAndSearch: (term, rowData) =>
        rowData.paciente.celular
          ? rowData.paciente.celular.toUpperCase().indexOf(term.toUpperCase()) >
            -1
          : null,
    },
    {
      title: "Status",
      field: "status",
      width: "10%",
      render: (data) => (
        <DropDownMenuStatus
          data={data}
          schedule={schedule}
          setSchedule={setSchedule}
          setIsLoading={setIsLoading}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.descricao_status.toUpperCase().indexOf(term.toUpperCase()) > -1,
    },
    {
      title: "Ações",
      tooltip: "Ações",
      searchable: false,
      width: "5%",
      headerStyle: {
        paddingRight: 0,
        textAlign: 'right',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (data) => (
        <DropDownMenuActionsSchedule 
          schedule={data} 
          setPatienteRoomLink={setPatienteRoomLink} 
          idProfessional={idProfessional}
          calendarDate={calendarDate}
          setSchedule={setSchedule}
          setIsLoading={setIsLoading}/>
      ),
    }
  ];

  function handleCalendarDateSelected(value) {
    setCalendarDate(value);
    setIsLoading(true);
  }

  return (
    <>
      <Content>
        <Grid container className={classes.gridRoot} spacing={0}>
          <Grid item xs={12} md={3}>
            <Box
              className={classes.calendarPaper}
              component={Paper}
              elevation={2}
            >
              <Box
                style={{ display: userIsHelthcareProfessional ? "none" : "" }}
              >
                <Box style={{ padding: "5px" }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink>Profissional de saúde</InputLabel>
                    <Select
                      disabled={userIsHelthcareProfessional}
                      onChange={(e) => {setIdProfessional(e.target.value)}}
                      defaultValue=""
                    >
                      <MenuItem value={""}>Selecione</MenuItem>
                      {professionals.map((type, index) => (
                        <MenuItem
                          key={type.id_prestador}
                          value={type.id_prestador}
                        >
                          {type.prestador}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      Selecione o profissional de saúde para pesquisar
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Divider className={classes.divider} />
              </Box>
              <Box className={classes.calendar}>
                <Calendar
                  value={calendarDate}
                  next2Label={null}
                  prev2Label={null}
                  onChange={(value) => handleCalendarDateSelected(value)}
                  tileClassName={({ date, view }) => colorDate(date)}
                />
              </Box>
              <Box paddingBottom={1}>
                <Box className={classes.legendBox}>
                  <Box className={classes.legendColorSquareGreen} />
                  <Typography variant="body2">
                    {" "}
                    - Datas com agendamentos
                  </Typography>
                </Box>
                <Box className={classes.legendBox}>
                  <Box className={classes.legendColorSquareOrange} />
                  <Typography variant="body2">
                    {" "}
                    - Datas sem agendamentos
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={9} style={{ paddingLeft: 6 }}>
            <div style={{ overflowX: "hidden" }}>
              <MaterialTable
                style={{ marginBottom: "20px" }}
                title={
                  <Box>
                    <Typography variant="h6" component="div">
                      Agendamentos - {getFormatedDateUI(calendarDate)}
                    </Typography>
                    <Box display="flex" flexDirection="row">
                      <GreenButton
                        disabled={
                          !(idProfessional !== "" && calendarDate !== "") ||
                          !enblaEmbed
                        }
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleEmbedding();
                        }}
                        className={classes.button}
                      >
                        <Icon>event_available</Icon> Encaixe
                      </GreenButton>
                      <GreenButton
                        disabled={
                          !(
                            idProfessional !== "" &&
                            calendarDate !== "" &&
                            schedule.length > 0
                          )
                        }
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleRefresh();
                        }}
                        className={classes.button}
                      >
                        <Icon>refresh</Icon> Atualizar
                      </GreenButton>
                      <XslsButton
                        disabled={
                          !(
                            idProfessional !== "" &&
                            calendarDate !== "" &&
                            schedule.length > 0
                          )
                        }
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleExportXlsx();
                        }}
                        className={classes.button}
                      >
                        <Icon>description</Icon> Excel
                      </XslsButton>
                      <PdfButton
                        disabled={
                          !(
                            idProfessional !== "" &&
                            calendarDate !== "" &&
                            schedule.length > 0
                          )
                        }
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleExportPDF();
                        }}
                        className={classes.button}
                      >
                        <Icon>description</Icon> Pdf
                      </PdfButton>
                    </Box>
                  </Box>
                }
                isLoading={isLoadding}
                localization={pt_BR}
                columns={COLUMNS}
                data={schedule}
                options={{
                  search: true,
                  actionsColumnIndex: -1,
                }}
              />
            </div>
          </Grid>
        </Grid>
        <PatientRoomLInkDialog
          open={patienteRoomLink.open}
          close={() => {setPatienteRoomLink({open: false, link: ''})}}
          link={patienteRoomLink.link}
        />
      </Content>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    padding: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  calendar: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(1),
  },
  titleDatePaper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    margin: "0px 10px",
    [theme.breakpoints.between("xs", "sm")]: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
    },
  },
  divider: {
    marginTop: theme.spacing(2),
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    width: "95%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  legendBox: {
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      justifyContent: "center",
      flexDirection: "row",
    },
    width: "100%",
    alignItems: "center",
  },
  legendColorSquareGreen: {
    backgroundColor: "#0cec04",
    margin: theme.spacing(1),
    width: "30px",
    height: "10px",
  },
  legendColorSquareOrange: {
    backgroundColor: "#ee6b00",
    margin: theme.spacing(1),
    height: "10px",
    width: "30px",
  },
  checkBoxImported: {
    marginTop: 35,
  },
}));

const GreenButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
    marginRight: 10,
  },
}))(Button);

const XslsButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: "#1A7343",
    "&:hover": {
      backgroundColor: "#229b58",
    },
    marginRight: 10,
  },
}))(Button);

const PdfButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: "#AD0C01",
    "&:hover": {
      backgroundColor: "#dd0b00",
    },
    marginRight: 10,
  },
}))(Button);
