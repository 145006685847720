import Swal from "sweetalert2";

export const defaultAlert = (
  messageBody,
  isDismissible = true,
  confirmationButtonText = "Entendi",
  iconText = "warning",
  titleText = "Atenção",
  configs = {}
) => {
  return Swal.fire({
    position: "center",
    icon: iconText,
    title: titleText,
    text: messageBody,
    showConfirmButton: true,
    confirmButtonText: confirmationButtonText,
    allowOutsideClick: isDismissible,
    allowEscapeKey: isDismissible,
    allowEnterKey: isDismissible,
    ...configs,
  });
};

export const confirmationAlert = ({
  messageBody,
  isDismissible = false,
  confirmationButtonText = "CONFIRMAR",
  cancelButtonText = "Cancelar",
  footerMessage,
  iconText = "question",
  titleText = "ATENÇÃO",
  showCancelButton = true,
  configs = {},
  htmlMessage,
}) => {
  return Swal.fire({
    position: "center",
    icon: iconText,
    title: titleText,
    text: messageBody,
    html: htmlMessage,
    showConfirmButton: true,
    showCancelButton: showCancelButton,
    cancelButtonText: cancelButtonText,
    confirmButtonText: confirmationButtonText,
    allowOutsideClick: isDismissible,
    allowEscapeKey: isDismissible,
    allowEnterKey: isDismissible,
    footer: footerMessage,
    ...configs,
  });
};

export const confirmationSendMailAlert = ({
  messageBody,
  email,
  isDismissible = false,
  confirmationButtonText = "CONFIRMAR",
  cancelButtonText = "Cancelar",
  footerMessage,
  iconText = "question",
  titleText = "ATENÇÃO",
  showCancelButton = true,
  configs = {},
  htmlMessage,
}) => {
  return Swal.fire({
    position: "center",
    html: '<label for="email">E-mail:</label>',
    input: 'email',
    inputValue: email,
    inputAttributes: {
      autocapitalize: 'off',
      id: 'email'
    },
    title: titleText,
    text: messageBody,
    showConfirmButton: true,
    showCancelButton: showCancelButton,
    cancelButtonText: cancelButtonText,
    confirmButtonText: confirmationButtonText,
    allowOutsideClick: isDismissible,
    allowEscapeKey: isDismissible,
    allowEnterKey: isDismissible,
    footer: footerMessage,
    ...configs,
  });
};


export const toastAlert = (
  messageBody,
  configs = {},
  iconText = "warning",
  titleText = "Atenção",
  showConfirmationButton = false
) => {
  return Swal.fire({
    position: "top-end",
    icon: iconText,
    title: titleText,
    text: messageBody,
    showConfirmButton: showConfirmationButton,
    timer: 6000,
    toast: true,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
    ...configs,
  });
};

export const toastAlertSuccess = (
  messageBody,
  configs = {},
  iconText = "success",
  titleText = "Sucesso",
  showConfirmationButton = false
) => {
  return Swal.fire({
    position: "bottom-start",
    icon: iconText,
    title: titleText,
    text: messageBody,
    showConfirmButton: showConfirmationButton,
    timer: 4000,
    toast: true,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
    ...configs,
  });
};

export const errorApiAlert = (message) => {
  return Swal.fire({
    position: "center",
    icon: "warning",
    title: "Encontramos um problema...",
    text: message,
    showConfirmButton: true,
    confirmButtonText: "Entendi",
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
};

export const errorSessionAlert = (message) => {
  return Swal.fire({
    position: "center",
    icon: "warning",
    title: "Sessão expirada...",
    text: message,
    showConfirmButton: true,
    confirmButtonText: "Entendi",
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
};

