import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Backdrop,
  CircularProgress,
  Paper,
  FormHelperText,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Input,
  Button,
  Avatar,
} from "@material-ui/core";
import Content from "../../components/Content";
import MaterialTable from "@material-table/core";
import pt_BR from "../../locale/material-table-locale";
import { getFormatedDateTimeUI } from "../../helpers/dateHelpers";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import SurveyFacesLayout from "../../components/SurveyFacesLayout";
import SurveyAssessmentsLayout from "../../components/SurveyAssessmentsLayout";
import {
  SentimentDissatisfied,
  SentimentVeryDissatisfied,
  SentimentSatisfiedAlt,
  SentimentSatisfied,
  SentimentVerySatisfied,
} from "@material-ui/icons";

import { SurveyManagementSchema } from "../../helpers/validation/Schemas/SurveyManagementSchema/schema";
import { getProfessionalHealthUnity } from "../../services/endpoints/professionals/endpoints";
import {
  getIndexRerpot,
  getFilterReport,
} from "../../services/endpoints/satisfactionReport/endpoints";

export default function SurveyManagement() {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [surveyAssessmentsValues, setSurveyAssessmentsValues] = useState({});
  const [surveyFacesValues, setSurveyFacesValues] = useState({});
  const [professionals, setProfessionals] = useState([]);
  const [surveyResults, setSurveyResults] = useState([]);

  const { register, errors, control, reset, handleSubmit } = useForm({
    validationSchema: SurveyManagementSchema,
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      prestador: "",
      data_inicio: "",
      data_fim: "",
    },
  });

  useEffect(() => {
    const fetchAllAssessments = async () => {
      getIndexRerpot()
        .then(({ data }) => {
          setSurveyAssessmentsValues({
            total: data.estatisticas.total_agendamento,
            negativas: data.estatisticas.negativas,
            positivas: data.estatisticas.positivas,
            respondidas: data.estatisticas.avaliacao_respondidas,
            taxa_resposta: data.estatisticas.taxa_de_resposta,
          });
          setSurveyFacesValues({
            muito_insatisfeito: data.estatisticas.count_muito_insatisfeito,
            insatisfeito: data.estatisticas.count_pouco_insatisfeito,
            neutro: data.estatisticas.count_neutro,
            satisfeito: data.estatisticas.count_pouco_satisfeito,
            muito_satisfeito: data.estatisticas.count_muito_satisfeito,
          });
          setSurveyResults(data.estatisticas.list_pesquisa);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };
    fetchAllAssessments();
  }, []);

  useEffect(() => {
    const fetchAllProfessionals = async () => {
      await getProfessionalHealthUnity().then(({ data }) => {
        setProfessionals(data.list_prestador);
      });
    };
    fetchAllProfessionals();
  }, []);

  const renderFace = (typeId) => {
    switch (typeId) {
      case "1":
        return (
          <Avatar className={classes.red}>
            <SentimentVeryDissatisfied />
          </Avatar>
        );
      case "2":
        return (
          <Avatar className={classes.softRed}>
            <SentimentDissatisfied />
          </Avatar>
        );
      case "3":
        return (
          <Avatar className={classes.yellow}>
            <SentimentSatisfiedAlt />
          </Avatar>
        );
      case "4":
        return (
          <Avatar className={classes.softGreen}>
            <SentimentSatisfied />
          </Avatar>
        );
      case "5":
        return (
          <Avatar className={classes.green}>
            <SentimentVerySatisfied />
          </Avatar>
        );
      default:
        return "Não encontrado";
    }
  };

  function filterResults(data) {
    setIsLoading(true);
    getFilterReport(
      data.prestador ? data.prestador : "null",
      data.data_inicio ? data.data_inicio : "null",
      data.data_fim ? data.data_fim : "null"
    )
      .then(({ data }) => {
        setSurveyAssessmentsValues({
          total: data.estatisticas.total_agendamento,
          negativas: data.estatisticas.negativas,
          positivas: data.estatisticas.positivas,
          respondidas: data.estatisticas.avaliacao_respondidas,
          taxa_resposta: data.estatisticas.taxa_de_resposta,
        });
        setSurveyFacesValues({
          muito_insatisfeito: data.estatisticas.count_muito_insatisfeito,
          insatisfeito: data.estatisticas.count_pouco_insatisfeito,
          neutro: data.estatisticas.count_neutro,
          satisfeito: data.estatisticas.count_pouco_satisfeito,
          muito_satisfeito: data.estatisticas.count_muito_satisfeito,
        });
        setSurveyResults(data.estatisticas.list_pesquisa);
        setIsLoading(false);
      })
      .catch(() => {
        setSurveyAssessmentsValues({});
        setSurveyFacesValues({});
        setSurveyResults([]);
        setIsLoading(false);
      });
  }

  function clearFilterFields() {
    reset();
    setSurveyAssessmentsValues({});
    setSurveyFacesValues({});
    setSurveyResults([]);
  }

  const COLUMNS = [
    {
      title: "Médico",
      render: (data) => data.nome_prestador,
    },
    {
      title: "Avaliação",
      render: (data) => renderFace(data.classificacao),
    },
    {
      title: "Mensagem",
      render: (data) => data.comentario,
    },
    {
      title: "Data/hora",
      render: (data) => getFormatedDateTimeUI(data.dh_cadastro),
    },
  ];

  return (
    <>
      <Content>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className={classes.paperStyle} component={Paper} elevation={2}>
              <Typography
                variant="h6"
                style={{ textAlign: "center", paddingBottom: "20px" }}
                component="h1"
              >
                Pesquisa de Satisfação
              </Typography>
              <SurveyAssessmentsLayout
                surveyAssessmentsValues={surveyAssessmentsValues}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.paperStyle} component={Paper} elevation={2}>
              <SurveyFacesLayout surveyFacesValues={surveyFacesValues} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <form
              method="POST"
              onSubmit={handleSubmit((data) => filterResults(data))}
            >
              <Box
                className={classes.paperStyle}
                component={Paper}
                elevation={2}
              >
                <Typography variant="h6" component="h2">
                  Filtros
                </Typography>
                <Box className={classes.flexContainer}>
                  <FormControl
                    error={!!errors.prestador}
                    className={classes.flexItems}
                  >
                    <InputLabel>Médico</InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value={""}>Selecione</MenuItem>
                          {professionals.map((type, index) => (
                            <MenuItem
                              key={type.id_prestador}
                              value={type.id_prestador}
                            >
                              {type.prestador}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      rules
                      control={control}
                      name="prestador"
                    />
                    <FormHelperText>
                      {errors?.prestador?.message}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    error={!!errors.data_inicio}
                    className={classes.flexItems}
                  >
                    <InputLabel shrink={true}>Período</InputLabel>
                    <Input
                      name="data_inicio"
                      type="date"
                      inputProps={{
                        min: "01/01/2020",
                        max: new Date().toISOString().split("T")[0],
                      }}
                      inputRef={register({ required: true })}
                    />
                    <FormHelperText>
                      {errors?.data_inicio?.message}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    error={!!errors.data_fim}
                    className={classes.flexItems}
                  >
                    <InputLabel shrink={true}>{""}</InputLabel>
                    <Input
                      name="data_fim"
                      type="date"
                      inputProps={{
                        min: "01/01/2020",
                        max: new Date().toISOString().split("T")[0],
                      }}
                      inputRef={register({ required: true })}
                    />
                    <FormHelperText>{errors?.data_fim?.message}</FormHelperText>
                  </FormControl>
                </Box>
                <Box className={classes.flexContainerButton}>
                  <Button
                    size="large"
                    variant="contained"
                    type="button"
                    color="secondary"
                    onClick={clearFilterFields}
                  >
                    Limpar
                  </Button>
                  <Button
                    size="large"
                    variant="contained"
                    type="submit"
                    color="primary"
                  >
                    Filtrar
                  </Button>
                </Box>
              </Box>
            </form>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.boxDefault} elevation={2}>
              <MaterialTable
                style={{ marginBottom: "20px" }}
                title="Avaliações"
                isLoading={isLoading}
                localization={pt_BR}
                columns={COLUMNS}
                data={surveyResults}
                options={{
                  search: true,
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportFileName: "Alterar aqui",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Content>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  boxDefault: {
    margin: theme.spacing(0, 2),
  },
  paperStyle: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
  },
  flexContainerFaces: {
    display: "flex",
    justifyContent: "center",
    gap: "40px",
  },
  faceItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  flexContainer: {
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  flexItems: {
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
    },
    width: "100%",
  },
  flexContainerButton: {
    marginTop: theme.spacing(5),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "flex-end",
    gap: "10px",
  },
  softRed: {
    backgroundColor: theme.status.danger(300),
  },
  red: {
    backgroundColor: theme.status.danger(),
  },
  green: {
    backgroundColor: theme.status.success(),
  },
  softGreen: {
    backgroundColor: theme.status.success(300),
  },
  yellow: {
    backgroundColor: theme.status.warning(),
  },
}));
