import api from "../../api";

export const getDocumentsComplete = async () => {
  return api.get("documento");
};

export const getDocumentsShortList = async () => {
  return api.get("documento/list_docs");
};

export const getDocumentFields = async (documentId) => {
  return api.get(`documento/get_fields/${documentId}`);
};

export const getDocumentTags = async () => {
  return api.get("documento/list_tags");
};

export const getDocumentById = async (documentId) => {
  return api.get(`documento/id/${documentId}`);
};

export const createDocument = async (params) => {
  return api.post("documento/create", params);
};

export const updateDocument = async (documentId, params) => {
  return api.put(`documento/update/${documentId}`, params);
};

export const deleteDocument = async (documentId) => {
  return api.delete(`documento/delete/${documentId}`);
};
