import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  CircularProgress,
  Backdrop,
  FormControlLabel,
  Switch,
  Divider,
} from "@material-ui/core";
import Content from "../../../../components/Content";
import { defaultAlert, confirmationAlert } from "../../../../helpers/validation/alerts";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { SmsSchema } from "../../../../helpers/validation/Schemas/Configuration/Communication/SmsSchema";
import {
  getConfigurationSMS,
  updateConfigurationSMS,
  checkService
} from "../../../../services/endpoints/configuration/communication/endpoints";

export default function SmsParameters() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [countSMSAgendamento, setCountSMSAgendamento] = useState(0);
  const [countSMSCancelamento, setCountSMSCancelamento] = useState(0);
  const [countSMSReagendamento, setCountSMSReagendamento] = useState(0);
  const [countSMSLinkDocumentos, setCountSMSLinkDocumentos] = useState(0);
  const [countSMSReenvioLink, setCountSMSReenvioLink] = useState(0);
  const [countSMSLembrete, setCountSMSLembrete] = useState(0);
  const [countSMSLembreteDia, setCountSMSLembreteDia] = useState(0);
  const [enableServiceSMS, setEnableServiceSMS] = useState(false);
  const [enableScheduleSMS, setEnableScheduleSMS] = useState(false);
  const [enableSchedule48SMS, setEnableSchedule48SMS] = useState(false);
  const [enableSchedule24SMS, setEnableSchedule24SMS] = useState(false);
  const [enableSchedule10mSMS, setEnableSchedule10mSMS] = useState(false);
  const [enableResendLink, setEnableResendLink] = useState(true);

  const inputRef = useRef(null);

  const {
    register,
    errors,
    setError,
    handleSubmit,
    reset,
    control,
    setValue,
  } = useForm({
    validationSchema: SmsSchema,
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      usuario: "",
      senha: "",
      sms_agendar: false,
      sms_48_lembrete: false,
      sms_24_lembrete: false,
      sms_10_lembrete: false,
      sms_reenvio_link: false,
      msg_agendamento: "  ",
      msg_cancelamento: " ",
      msg_reagendamento: " ",
      msg_lembrete_agendamento: " ",
      msg_lembrete_agendamento_10: " ",
      msg_link_documentos: " ",
      msg_reenvio_link: " ",
      status: false,
    },
  });

  useEffect(() => {
    setIsLoading(true);
    const handleFetchConfigsValues = async () => {
      await getConfigurationSMS()
        .then(({ data }) => {
          const {
            usuario,
            senha,
            msg_agendamento,
            msg_cancelamento,
            msg_reagendamento,
            msg_lembrete_agendamento,
            msg_lembrete_agendamento_10,
            msg_link_documentos,
            msg_reenvio_link,
            status,
          } = data.configuracoes;
          setEnableServiceSMS(data.configuracoes.status === "1" ? true : false);
          setEnableScheduleSMS(data.configuracoes.sms_agendar === "1" ? true : false);
          setEnableSchedule48SMS(data.configuracoes.sms_48_lembrete === "1" ? true : false);
          setEnableSchedule24SMS(data.configuracoes.sms_24_lembrete === "1" ? true : false);
          setEnableSchedule10mSMS(data.configuracoes.sms_10_lembrete === "1" ? true : false);
          setEnableResendLink(data.configuracoes.msg_reenvio_link === "1" ? true : false);
          setCountSMSAgendamento(msg_agendamento.length);
          setCountSMSCancelamento(msg_cancelamento.length);
          setCountSMSReagendamento(msg_reagendamento.length);
          setCountSMSLembrete(msg_lembrete_agendamento.length);
          setCountSMSLembreteDia(msg_lembrete_agendamento_10.length);
          setCountSMSLinkDocumentos(msg_link_documentos.length);
          setCountSMSReenvioLink(msg_reenvio_link.length);
          reset(data.configuracoes);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };
    handleFetchConfigsValues();
    // eslint-disable-next-line
  }, []);

  function handleChangeStatus(status) {
    if (status[1]) {
      setIsLoading(true);
      checkService("whatsapp")
        .then(({ data }) => {
          setIsLoading(false);
          if (data.status_service) {
            confirmationAlert({
              messageBody:
                "O serviço de envio de notificações via WhatsApp já esta ativo, deseja ativar também o serviço de notificações via SMS ?",
                footerMessage:
                "<div style='text-align:center'><i>Se os dois serviços estiverem habilitados simultaneamente, os pacientes receberam mensagens de notificações via SMS e WhatsApp, gerando custos duplicados.</i></div>",
            }).then(async (result) => {
              if (result.value) {
                setEnableServiceSMS(true);
                return true;
              } else {
                setEnableServiceSMS(false);
                return false;
              }
            });
          } else {
            setEnableServiceSMS(true);
            return true;
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setEnableServiceSMS(false);
      return false;
    }
  }

  async function handleForm(data) {
    data.status = enableServiceSMS;
    data.sms_agendar = enableScheduleSMS;
    data.sms_48_lembrete = enableSchedule48SMS;
    data.sms_24_lembrete = enableSchedule24SMS;
    data.sms_10_lembrete = enableSchedule10mSMS;
    data.sms_reenvio_link = enableResendLink;
    setIsLoading(true);
    updateConfigurationSMS(data)
      .then(() => {
        setIsLoading(false);
        defaultAlert(
          "Parâmetros de comunicação via SMS atualizados com sucesso!",
          true,
          "Entendi",
          "success"
        );
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let { errors } = error.response.data;
          Object.keys(errors).map((erro) => {
            return setError(erro, "notMatch", errors[erro]);
          });
        }
        setIsLoading(false);
      });
  }

  return (
    <Content>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container className={classes.gridRoot}>
        <Box className={classes.paperStyle} component={Paper} elevation={2}>
          <Typography component="h1" variant="h6">
            Editar parâmetros comunicação de SMS do sistema
          </Typography>
          <form
            method="POST"
            onSubmit={handleSubmit((data) => handleForm(data))}
          >
            <Box className={classes.flexContainer}>
              <FormControlLabel
                control={
                  <Controller
                    as={<Switch color="primary" />}
                    control={control}
                    name="status"
                    type="checkbox"
                    onChange={handleChangeStatus}
                    checked={enableServiceSMS}
                    value={enableServiceSMS}
                  />
                }
                defaultValue={false}
                style={{ marginLeft: "0px" }}
                labelPlacement="start"
                label="Status do serviço de notificações via SMS"
              />
            </Box>
            <Box className={classes.flexTopicSeparator}>
              <Typography component="p" variant="button">
                Configurações de autenticação do serviço de SMS do Zenvia
              </Typography>
            </Box>
            <Box className={classes.flexContainer}>
              <FormControl
                error={!!errors.usuario}
                className={classes.flexItems}
              >
                <InputLabel>Usuário Zenvia</InputLabel>
                <Controller
                  as={<Input />}
                  rules={{ required: true }}
                  control={control}
                  name="usuario"
                  disabled={!enableServiceSMS}
                />
                <FormHelperText>{errors?.usuario?.message}</FormHelperText>
              </FormControl>
              <FormControl error={!!errors.senha} className={classes.flexItems}>
                <InputLabel>Senha Zenvia</InputLabel>
                <Controller
                  as={<Input />}
                  rules={{ required: true }}
                  control={control}
                  name="senha"
                  disabled={!enableServiceSMS}
                />
                <FormHelperText>{errors?.senha?.message}</FormHelperText>
              </FormControl>
            </Box>
            <Divider style={{ marginTop: "25px" }} />
            <Box className={classes.flexTopicSeparator}>
              <Typography component="p" variant="button">
                Configurações de envio de SMS
              </Typography>
            </Box>
            <Box className={classes.flexContainer}>
              <FormControlLabel
                control={
                  <Controller
                    as={<Switch color="primary" />}
                    control={control}
                    name="sms_agendar"
                    type="checkbox"
                    disabled={!enableServiceSMS}
                    onChange={(e) => {
                      // eslint-disable-next-line no-lone-blocks
                      {
                        setEnableScheduleSMS(e[1]);
                      }
                      return e[1];
                    }}
                    checked={enableScheduleSMS}
                    value={enableScheduleSMS}
                  />
                }
                defaultValue={false}
                style={{ marginLeft: "0px" }}
                labelPlacement="start"
                label="Enviar SMS ao agendar"
              />
              <FormControlLabel
                control={
                  <Controller
                    as={<Switch color="primary" />}
                    control={control}
                    name="sms_48_lembrete"
                    type="checkbox"
                    disabled={!enableServiceSMS}
                    onChange={(e) => {
                      // eslint-disable-next-line no-lone-blocks
                      {
                        setEnableSchedule48SMS(e[1]);
                      }
                      return e[1];
                    }}
                    checked={enableSchedule48SMS}
                    value={enableSchedule48SMS}
                  />
                }
                defaultValue={false}
                style={{ marginLeft: "0px" }}
                labelPlacement="start"
                label="Enviar SMS de lembrete 48h antes"
              />
              <FormControlLabel
                control={
                  <Controller
                    as={<Switch color="primary" />}
                    control={control}
                    name="sms_24_lembrete"
                    type="checkbox"
                    disabled={!enableServiceSMS}
                    onChange={(e) => {
                      // eslint-disable-next-line no-lone-blocks
                      {
                        setEnableSchedule24SMS(e[1]);
                      }
                      return e[1];
                    }}
                    checked={enableSchedule24SMS}
                    value={enableSchedule24SMS}
                  />
                }
                defaultValue={false}
                style={{ marginLeft: "0px" }}
                labelPlacement="start"
                label="Enviar SMS de lembrete 24h antes"
              />
              <FormControlLabel
                control={
                  <Controller
                    as={<Switch color="primary" />}
                    control={control}
                    name="sms_10_lembrete"
                    type="checkbox"
                    disabled={!enableServiceSMS}
                    onChange={(e) => {
                      // eslint-disable-next-line no-lone-blocks
                      {
                        setEnableSchedule10mSMS(e[1]);
                      }
                      return e[1];
                    }}
                    checked={enableSchedule10mSMS}
                    value={enableSchedule10mSMS}
                  />
                }
                defaultValue={false}
                style={{ marginLeft: "0px" }}
                labelPlacement="start"
                label="Enviar SMS de lembrete 10m antes"
              />
            </Box>
            <Box className={classes.flexContainer}>
              <FormControl
                error={!!errors.msg_agendamento}
                className={classes.flexItems}
              >
                <InputLabel>SMS de agendamento</InputLabel>
                <Input
                  multiline
                  name="msg_agendamento"
                  inputRef={register({ required: true })}
                  disabled={!enableServiceSMS}
                  onChange={(event) =>
                    setCountSMSAgendamento(event.target.value.length)
                  }
                />
                <FormHelperText>
                  {errors?.msg_agendamento?.message}
                </FormHelperText>
                <Typography component="p" variant="caption">
                  Quantidade de caracteres: {countSMSAgendamento} <br />
                  <strong>Tags de substituição disponiveis: </strong>
                  [prestador] - Nome do Médico vinculado ao agendamento;
                  [paciente] - Nome do paciente que receberá o SMS; [data] -
                  Data do agendamento; [hora] - Hora do agendamento;
                </Typography>
              </FormControl>
            </Box>
            <Box className={classes.flexContainer}>
              <FormControl
                error={!!errors.msg_cancelamento}
                className={classes.flexItems}
              >
                <InputLabel>SMS de cancelamento</InputLabel>
                <Input
                  multiline
                  name="msg_cancelamento"
                  inputRef={register({ required: true })}
                  disabled={!enableServiceSMS}
                  onChange={(event) =>
                    setCountSMSCancelamento(event.target.value.length)
                  }
                />
                <FormHelperText>
                  {errors?.msg_cancelamento?.message}
                </FormHelperText>
                <Typography component="p" variant="caption">
                  Quantidade de caracteres: {countSMSCancelamento} <br />
                  <strong>Tags de substituição disponiveis: </strong>
                  [prestador] - Nome do Médico vinculado ao agendamento;
                  [paciente] - Nome do paciente que receberá o SMS; [data] -
                  Data do agendamento; [hora] - Hora do agendamento;
                </Typography>
              </FormControl>
            </Box>
            <Box className={classes.flexContainer}>
              <FormControl
                error={!!errors.msg_reagendamento}
                className={classes.flexItems}
              >
                <InputLabel>SMS de reagendamento</InputLabel>
                <Input
                  multiline
                  name="msg_reagendamento"
                  inputRef={register({ required: true })}
                  disabled={!enableServiceSMS}
                  onChange={(event) =>
                    setCountSMSReagendamento(event.target.value.length)
                  }
                />
                <FormHelperText>
                  {errors?.msg_reagendamento?.message}
                </FormHelperText>
                <Typography component="p" variant="caption">
                  Quantidade de caracteres: {countSMSReagendamento} <br />
                  <strong>Tags de substituição disponiveis: </strong>
                  [prestador] - Nome do Médico vinculado ao agendamento;
                  [paciente] - Nome do paciente que receberá o SMS; [data] -
                  Data do agendamento; [hora] - Hora do agendamento;
                </Typography>
              </FormControl>
            </Box>
            <Box className={classes.flexContainer}>
              <FormControl
                error={!!errors.msg_lembrete_agendamento}
                className={classes.flexItems}
              >
                <InputLabel>SMS's de Lembrete de 24h e 48h</InputLabel>
                <Input
                  multiline
                  name="msg_lembrete_agendamento"
                  inputRef={register({ required: true })}
                  disabled={!enableServiceSMS}
                  onChange={(event) =>
                    setCountSMSLembrete(event.target.value.length)
                  }
                />
                <FormHelperText>
                  {errors?.msg_lembrete_agendamento?.message}
                </FormHelperText>
                <Typography component="p" variant="caption">
                  Quantidade de caracteres: {countSMSLembrete} <br />
                  <strong>Tags de substituição disponiveis: </strong>
                  [paciente] - Nome do paciente que receberá o SMS;
                  [data] - Data do agenamento; [hora] Hora do agendamento;
                </Typography>
              </FormControl>
            </Box>
            <Box className={classes.flexContainer}>
              <FormControl
                error={!!errors.msg_lembrete_agendamento_10}
                className={classes.flexItems}
              >
                <InputLabel>SMS de lembrete do dia do agenamento (enviada 10 minutos antes)</InputLabel>
                <Input
                  multiline
                  name="msg_lembrete_agendamento_10"
                  inputRef={register({ required: true })}
                  disabled={!enableServiceSMS}
                  onChange={(event) =>
                    setCountSMSLembrete(event.target.value.length)
                  }
                />
                <FormHelperText>
                  {errors?.msg_lembrete_agendamento_10?.message}
                </FormHelperText>
                <Typography component="p" variant="caption">
                  Quantidade de caracteres: {countSMSLembrete} <br />
                  <strong>Tags de substituição disponiveis: </strong>
                  [prestador] - Nome do Médico vinculado ao agendamento;
                  [paciente] - Nome do paciente que receberá o SMS;
                  [hora] Hora do agendamento; [link] - Link da teleconsulta;
                </Typography>
              </FormControl>
            </Box>
            <Box className={classes.flexContainer}>
              <FormControl
                error={!!errors.msg_link_documentos}
                className={classes.flexItems}
              >
                <InputLabel>
                  SMS de link público de documento do paciente
                </InputLabel>
                <Input
                  multiline
                  name="msg_link_documentos"
                  inputRef={register({ required: true })}
                  disabled={!enableServiceSMS}
                  onChange={(event) =>
                    setCountSMSLinkDocumentos(event.target.value.length)
                  }
                />
                <FormHelperText>
                  {errors?.msg_link_documentos?.message}
                </FormHelperText>
                <Typography component="p" variant="caption">
                  Quantidade de caracteres: {countSMSLinkDocumentos} <br />
                  <strong>Tags de substituição disponiveis: </strong>
                  [paciente] - Nome do paciente que receberá o SMS; [link] -
                  Link público de documento do paciente; [senha] - Senha de
                  acesso aos documentos;
                </Typography>
              </FormControl>
            </Box>
            <Box className={classes.flexContainer}>
              <FormControl
                error={!!errors.msg_reenvio_link}
                className={classes.flexItems}
              >
                <InputLabel>
                  SMS de reenvio de link de consulta
                </InputLabel>
                <Input
                  multiline
                  name="msg_reenvio_link"
                  inputRef={register({ required: true })}
                  disabled={!enableServiceSMS}
                  onChange={(event) =>
                    setCountSMSLinkDocumentos(event.target.value.length)
                  }
                />
                <FormHelperText>
                  {errors?.msg_reenvio_link?.message}
                </FormHelperText>
                <Typography component="p" variant="caption">
                  Quantidade de caracteres: {countSMSLinkDocumentos} <br />
                  <strong>Tags de substituição disponiveis: </strong>
                  [paciente] - Nome do paciente que receberá o SMS; 
                  [link] - Link da teleconsulta;
                </Typography>
              </FormControl>
            </Box>
            <Box className={classes.flexContainerButton}>
              <Button
                size="large"
                variant="contained"
                type="submit"
                color="primary"
              >
                Atualizar parâmetros
              </Button>
            </Box>
          </form>
        </Box>
      </Grid>
    </Content>
  );
}

const useStyles = makeStyles((theme) => ({
  gridRoot: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paperStyle: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  flexTopicSeparator: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-2),
  },
  clientImage: {
    borderRadius: "4px",
    height: "200px",
    width: "200px",
    objectFit: "contain",
    cursor: "pointer",
  },
  flexImageContainer: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  flexContainer: {
    marginTop: theme.spacing(1),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  flexContainerButton: {
    marginTop: theme.spacing(5),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "center",
  },
  flexItems: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
    },
    width: "100%",
  },
  flexItemImage: {
    alignItems: "center",
  },
  flexItem: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
      maxWidth: "33%",
    },
    width: "100%",
  },
}));
