const pt_BR = {
  pagination: {
    labelRowsSelect: "Linhas",
    labelDisplayedRows: "{from} de {count}",
    firstTooltip: "Primeiro",
    previousTooltip: "Anterior",
    nextTooltip: "Próximo",
    lastTooltip: "Último",
  },
  toolbar: {
    nRowsSelected: "{0} linhas(s) selecionadas",
    searchTooltip: "Buscar",
    searchPlaceholder: "Buscar",
    exportTitle: "Exportar",
    exportAriaLabel: "Exportar",
    exportCSVName: "Exportar CSV",
    exportPDFName: "Exportar PDF"
  },
  header: {
    actions: "Ações",
  },
  body: {
    emptyDataSourceMessage: "Sem resultados para exibir",
    filterRow: {
      filterTooltip: "Filtrar",
    },
  },
};

export default pt_BR;
