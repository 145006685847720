import api from "../../api";

export const getHealthUnits = async () => {
  return api.get("unidade");
};

export const getHealthUnitById = async (unitId) => {
  return api.get(`unidade/id/${unitId}`);
};

export const createHealthUnit = async (params) => {
  return api.post("unidade/create/", params);
};

export const updateHealthUnit = async (unitId, params) => {
  return api.put(`unidade/update/${unitId}`, params);
};

export const deleteHealthUnit = async (unitId, params) => {
  return api.delete(`unidade/delete/${unitId}`, params);
};
