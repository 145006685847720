import React from "react";
import { Paper, Button, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import logoImage from "../../../assets/images/logo.png";
import image404 from "../../../assets/images/not_found.png";

export default function NotFound404(props) {
  const classes = useStyles();

  const history = useHistory();

  function handleBackHistory() {
    return history.goBack();
  }

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={0} square>
        <div className={classes.logoCompanyBox}>
          <img
            className={classes.logoCompanyImage}
            src={logoImage}
            alt="Logotipo - OM30 - Tecnologia movida por pessoas"
          />
        </div>
        <div className={classes.paper}>
          <Typography component="h1" variant="h2">
            Ops...
          </Typography>
          <img
            className={classes.image404}
            src={image404}
            alt="ilustração mostrada quando página não é encontrada ou não existe"
          />
          <Typography component="h1" variant="h5">
            A página solicitada não existe!
          </Typography>
          <br />
          <Button
            color="primary"
            onClick={handleBackHistory}
            variant="contained"
          >
            VOLTAR
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logoCompanyBox: {
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    top: 0,
    height: "120px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoCompanyImage: {
    height: "100px",
    marginTop: "-12px",
  },
  image404: {
    minHeight: "200px",
    height: "100%",
    width: "100%",
    maxWidth: "300px",
    marginTop: "10px",
    marginBottom: "20px",
  },
  paper: {
    margin: theme.spacing(6, 4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
}));
