import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  getCurrentTerms,
  acceptTerms,
} from "../../services/endpoints/terms/endpoints";
import { useUserData } from "../../context/UserContext";
import { useHistory } from "react-router-dom";
import {
  userLogout,
  getToken,
  setLocalUserData,
  getLocalUserData,
} from "../../services/endpoints/auth/endpoints";
import { toastAlert, defaultAlert } from "../../helpers/validation/alerts";

export default function TermsDialog(props) {
  const history = useHistory();
  const { setUserData } = useUserData();
  const [isLoadding, setIsLoading] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [term, setTerm] = useState({
    nome_termo: "",
    descricao: "",
  });

  useEffect(() => {
    if (props.open) {
      const fechTerms = async () => {
        await getCurrentTerms().then(({ data }) => {
          const { nome_termo, descricao } = data.termo_de_uso;
          setTerm({ nome_termo, descricao });
        });
        setIsLoading(false);
      };

      fechTerms();
    }
    return () => {
      return false;
    };
  }, [props.open]);

  function handleTermCheckBox() {
    setIsChecked(!isChecked);
  }

  async function handleAcceptTerms() {
    await acceptTerms().then(async () => {
      const data = await getLocalUserData();
      data.accept_terms = true;
      setLocalUserData(data);
      setUserData(data);
      defaultAlert(
        "Termos aceitos com sucesso!",
        true,
        "Entendi",
        "success",
        "Tudo certo!"
      );
    });
  }

  async function handleRejectTerms() {
    const token = await getToken();
    userLogout({ token: token }).then(() => {
      history.push("/");
      toastAlert("Para acessar o sistema é necessário aceitar os termos.");
    });
  }

  return (
    <>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        scroll="paper"
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {isLoadding ? <Skeleton variant="text" /> : term.nome_termo}
        </DialogTitle>
        <DialogContent dividers={true}>
          <Typography component="div" variant="body1">
            {isLoadding ? (
              <>
                {[...Array(10).keys()].map((index) => {
                  return <Skeleton key={index} width={400} variant="text" />;
                })}
              </>
            ) : (
              <>
                <div dangerouslySetInnerHTML={{ __html: term.descricao }} />
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        color="primary"
                        onChange={() => handleTermCheckBox()}
                      />
                    }
                    label="Estou ciente e aceito os termos"
                  />
                </Box>
              </>
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRejectTerms} color="default">
            Não aceito
          </Button>
          <Button
            variant="contained"
            disabled={!isChecked}
            color="primary"
            onClick={handleAcceptTerms}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
