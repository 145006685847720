import api from "../../api";

export const getCurrentTerms = async () => {
  return api.get("termo/usuario/current");
};

export const getAllVersionedTerms = async () => {
  return api.get("termo/usuario");
};

export const acceptTerms = async () => {
  return api.get("termo/usuario/accept");
};

export const createTerm = async (params) => {
  return api.post("termo/usuario/create", params);
};

export const updateTerm = async (termId, params) => {
  return api.put(`termo/usuario/update/${termId}`, params);
};
