import { createTheme } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";
import { green, red, yellow, blue, brown } from "@material-ui/core/colors";

const theme = createTheme(
  {
    status: {
      danger: (variant) => (variant ? red[variant] : red[500]),
      success: (variant) => (variant ? green[variant] : green[500]),
      warning: (variant) => (variant ? yellow[variant] : yellow[500]),
      info: (variant) => (variant ? blue[variant] : blue[500]),
      custom: (variant) => (variant ? brown[variant] : brown[500]),
    },
    palette: {
      primary: { main: "#77021F", contrastText: "#ffffff" },
      secondary: { main: "#FFFFFF", contrastText: "#000000" },
    },
  },
  ptBR
);

export default theme;
