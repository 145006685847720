import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

export default function SurveyAssessmentsLayout(props) {
  const classes = useStyles();

  return (
    <Box className={classes.flexContainer}>
       <div className={classes.items}>
        <Typography className={classes.custom2} variant="h6" component="span">
          {props.surveyAssessmentsValues?.total || "..."}
        </Typography>
        <Typography variant="subtitle1" component="span">
          Agendamentos finalizados
        </Typography>
      </div>
      <div className={classes.items}>
        <Typography className={classes.red} variant="h6" component="span">
          {props.surveyAssessmentsValues?.negativas || "..."}
        </Typography>
        <Typography variant="subtitle1" component="span">
          Avaliações negativas
        </Typography>
      </div>
      <div className={classes.items}>
        <Typography className={classes.green} variant="h6" component="span">
          {props.surveyAssessmentsValues?.positivas || "..."}
        </Typography>
        <Typography variant="subtitle1" component="span">
          Avaliações positivas
        </Typography>
      </div>
      <div className={classes.items}>
        <Typography className={classes.custom} variant="h6" component="span">
          {props.surveyAssessmentsValues?.respondidas || "..."}
        </Typography>
        <Typography variant="subtitle1" component="span">
          Avaliações respondidas
        </Typography>
      </div>
      <div className={classes.items}>
        <Typography className={classes.blue} variant="h6" component="span">
          {props.surveyAssessmentsValues?.taxa_resposta
            ? `${props.surveyAssessmentsValues?.taxa_resposta}`
            : "..."}
        </Typography>
        <Typography variant="subtitle1" component="span">
          Taxa de resposta
        </Typography>
      </div>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "40px",
    flexWrap: "wrap",
  },
  items: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  red: {
    color: theme.status.danger(),
  },
  green: {
    color: theme.status.success(),
  },
  blue: {
    color: theme.status.info(),
  },
  custom: {
    color: theme.status.custom(),
  },
  custom2:{
    color: 'black',
  }
}));
