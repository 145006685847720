import api from "../../api";

export const getHistoryByPatientId = async (patientId) => {
  return api.get(`registro/paciente/${patientId}`);
};

export const getPatientDocumentPDFReadableRegisteredByRegisterId = async (
  registerDocumentId
) => {
  return api.get(`/registro/read/${registerDocumentId}`, {
    responseType: "arraybuffer",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
