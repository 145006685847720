export const MENU_ADMIN = {
  patients: true,
  schedule: false,
  scheduling: false,
  registers: false,
  user: true,
  configs: true,
  reports: true,
};

export const MENU_USUARIOS = {
  patients: true,
  schedule: true,
  scheduling: true,
  registers: false,
  user: false,
  configs: false,
  surveyManagement: true,
  reports: true,
};

export const MENU_PROFISSIONAL_DE_SAUDE = {
  patients: true,
  schedule: true,
  scheduling: true,
  registers: true,
  user: false,
  configs: false,
  surveyManagement: true,
  reports: true,
};

export const SCHEDULE_STATUS = {
  agendado: "A",
  atendido: "AT",
  em_espera: "C",
  desmarcado: "CA",
  encaixe: "E",
  falta: "FA"
};

export const AVAILABILITY_STATUS = {
  disponivel: "D",
  indisponivel: "I",
};
