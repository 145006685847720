import React, { useEffect, useState } from "react";
import MaterialTable from "@material-table/core";
import { Icon, Typography, Button, Box } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { green } from "@material-ui/core/colors";
import Content from "../../../../components/Content";
import pt_BR from "../../../../locale/material-table-locale";
import {
  confirmationAlert,
  defaultAlert,
} from "../../../../helpers/validation/alerts";
import {
  getDocumentsShortList,
  deleteDocument,
} from "../../../../services/endpoints/documents/endpoints";

export default function ListDocuments() {
  const classes = useStyles();
  const history = useHistory();

  const [documents, setDocuments] = useState([]);
  const [isLoadding, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDocuments = async () => {
      await getDocumentsShortList()
        .then(({ data }) => {
          setDocuments(data.list_documento);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };

    fetchDocuments();
    return () => {};
  }, []);

  async function handleDeleteDocument(idDocument) {
    setIsLoading(true);
    confirmationAlert({
      messageBody: "Deseja realmente excluir este documento?",
    }).then(async (result) => {
      if (result.value) {
        await deleteDocument(idDocument)
          .then(() => {
            defaultAlert(
              "Documento excluído!",
              true,
              "OK",
              "success",
              "Sucesso!"
            );
            setDocuments((documents) =>
              documents.filter(
                (document) => document.id_documento !== idDocument
              )
            );
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
          });
      }
      setIsLoading(false);
    });
  }

  function handleEditDocument(idDocument) {
    history.push(`/documents/edit/${idDocument}`);
  }

  const COLUMNS = [
    { title: "Documento", field: "titulo" },
    { title: "Tipo de documento", field: "tipo_de_documento" },
  ];

  const TABLE_ACTIONS_ADMIN = [
    {
      icon: "edit",
      tooltip: "Editar documento",
      onClick: (event, rowData) => {
        handleEditDocument(rowData.id_documento);
      },
    },
    {
      icon: "delete",
      tooltip: "Excluir documento",
      onClick: (event, rowData) => {
        handleDeleteDocument(rowData.id_documento);
      },
    },
  ];

  return (
    <>
      <Content>
        <MaterialTable
          style={{ margin: "0px 20px", marginBottom: "20px" }}
          title={
            <Box className={classes.titleBox}>
              <Typography variant="h6" component="div">
                Listagem de documentos
              </Typography>
              <GreenButton
                className={classes.newButton}
                onClick={() => history.push("/documents/new")}
                variant="outlined"
                color="default"
                size="small"
                endIcon={<Icon>add</Icon>}
              >
                Criar
              </GreenButton>
            </Box>
          }
          isLoading={isLoadding}
          localization={pt_BR}
          columns={COLUMNS}
          actions={TABLE_ACTIONS_ADMIN}
          data={documents}
          options={{
            search: true,
            actionsColumnIndex: -1,
          }}
        />
      </Content>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  newButton: {
    marginLeft: theme.spacing(4),
  },
  titleBox: {
    display: "flex",
    maxHeight: "30px",
  },
}));

const GreenButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
