import * as yup from "yup";
import moment from "moment";
import ptBr from "../../../../locale/yup-translation-pt-BR";
import { RGX_NOME_COMPLETO, RGX_NAO_CONTEM_NUMEROS } from "../../../regex";
yup.setLocale(ptBr);

const videoDomain = process.env.REACT_APP_VIDEOIFRAME_DOMAIN;

export const CreateScheduleSchema = yup.object().shape({
  nome: yup
    .string()
    .min(3) // eslint-disable-next-line
    .matches(RGX_NAO_CONTEM_NUMEROS, "${path} não pode conter números", {
      excludeEmptyString: false,
    }) // eslint-disable-next-line
    .matches(RGX_NOME_COMPLETO, "${path} deve conter Nome e Sobrenome", {
      excludeEmptyString: false,
    })
    .label("Nome")
    .required(),
  nome_social: yup.string().when({
    is: (e) => e.length > 0,
    then: yup
      .string()
      .min(3) // eslint-disable-next-line
      .matches(RGX_NAO_CONTEM_NUMEROS, "${path} não pode conter números", {
        excludeEmptyString: false,
      }) // eslint-disable-next-line
      .matches(RGX_NOME_COMPLETO, "${path} deve conter Nome  e Sobrenome", {
        excludeEmptyString: false,
      })
      .label("Nome Social")
      .notRequired(),
  }),
  email: yup.string().email().label("Email"),
  data_nascimento: yup
    .date()
    .min(moment(new Date(1900, 0, 1)).format("DD/MM/YYYY"))
    .max(
      moment(new Date()).format("YYYY-MM-DD"),
      `A Data de Nascimento deve ser menor que ${moment(new Date()).format(
        "DD/MM/YYYY"
      )}`
    )
    .label("Data de Nascimento")
    .required() // eslint-disable-next-line
    .typeError("${path} não contém uma data valida"),
  cpf: yup
    .string() // eslint-disable-next-line
    .label("CPF")
    .min(11)
    .required(),
  cartao_nacional_de_saude: yup.string().when({
    is: (e) => e.length > 0,
    then: yup
      .string() // eslint-disable-next-line
      .label("Cartão Nacional de Saúde")
      .min(15)
      .notRequired(),
  }),
  id_prestador: yup
    .string()
    .min(1, "Selecionar um profissional de saúde é obrigatório")
    .label("Profissional de saúde")
    .required(),
  celular: yup.string().min(11).label("Celular").required(),
  cep: yup.string().max(9).min(9).label("CEP").required(),
  cidade: yup.string().max(100).label("Cidade").required(),
  n_logradouro: yup
    .string()
    .max(9)
    .label("Número")
    .matches(/^[0-9]*$/, "Este campo aceita somente números")
    .nullable(),
  endereco: yup.string().max(200).label("Logradouro").required(),
  bairro: yup.string().max(100).label("Bairro").nullable(),
  estado: yup.string().max(2).label("Estado").required(),
  complemento: yup.string().max(200).label("Complemento").nullable(),
  cd_status: yup
    .string()
    .min(1, "Selecionar o Status é obrigatório")
    .label("Status")
    .required(),
  cd_procedimento: !!videoDomain ?  
    yup.string()
      .min(1, "Selecionar o procedimento é obrigatório")
      .label("Procedimento")
      .required() :
    yup.string().nullable(),
  data: yup.string().label("Datas disponíveis").required(), // eslint-disable-next-line
  hora: yup.string().required(),
});
