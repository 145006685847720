import api from "../../api";

export const getAllProcedure = async () => {
  return api.get("procedimento");
};

export const getProcedureById = async (procedureId) => {
  return api.get(`procedimento/id/${procedureId}`);
};

export const createProcedure = async (params) => {
  return api.post("procedimento/create", params);
};

export const updateProcedure = async (procedureId, params) => {
  return api.put(`procedimento/update/${procedureId}`, params);
};

export const deleteProcedure = async (procedureId) => {
  return api.delete(`procedimento/delete/${procedureId}`);
};
