import api from "../../../api";

export const getConfigurationMail = async () => {
  return api.get("configs/comunicacao/mail");
};

export const getConfigurationSMS = async () => {
  return api.get("configs/comunicacao/sms");
};

export const getConfigurationWhats = async () => {
  return api.get("configs/comunicacao/whats");
};

export const updateConfigurationMail = async (params) => {
  return api.put("configs/comunicacao/update_email", params);
};

export const updateConfigurationSMS = async (params) => {
  return api.put("configs/comunicacao/update_sms", params);
};

export const updateConfigurationWhats = async (params) => {
  return api.put("configs/comunicacao/update_whats", params);
};

export const getServiceCost = async () => {
  return api.get("configs/comunicacao/custo_twilio");
};

export const checkService = async (sevice) => {
  return api.get(`configs/comunicacao/check_service/${sevice}`);
};


