import api from "../../api";

export const getTerm = async () => {
  return api.get("publico/get_term");
};

export const getPrivacyPolicy = async () => {
  return api.get("publico/get_policy");
};

export const getPatientTerm = async () => {
  return api.get("room/current_term");
};

export const acceptPatientTerms = async (patientToken) => {
  return api.post(`room/accept_term/${patientToken}`);
};
