import React, { useState } from "react";
import {
  Button,
  Dialog,
  Box,
  DialogActions,
  DialogContent,
  CircularProgress,
  Backdrop,
  Typography,
  TextField,
  DialogTitle,
  Tooltip,
  FormLabel,
} from "@material-ui/core";

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SentimentDissatisfieIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import { createSurvey } from "../../services/endpoints/patientSurvey/endpoints";
import { defaultAlert } from "../../helpers/validation/alerts";

export default function PatientSurveyModal(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [surveyInformations, setSurveyInformations] = useState({
    classificacao: "",
    comentario: "",
    token_sala: props.tokenId,
  });

  function handleChangeSatisfactionFacesLevel(event, value) {
    if (value) {
      setSurveyInformations((prevState) => ({
        ...prevState,
        classificacao: value,
      }));
    }
  }

  function handleChangeSatisfactionComments(event) {
    event.persist();
    setSurveyInformations((prevState) => ({
      ...prevState,
      comentario: event.target.value,
    }));
  }

  function handleSubmitSurvey() {
    if (surveyInformations.token_sala) {
      createSurvey(surveyInformations)
        .then(({ data }) => {
          props.handleClosePatientSurvey();
          defaultAlert(
            "Agradecemos sua participação em nossa pesquisa!",
            true,
            "Fechar"
          );
        })
        .catch((data) => {
          props.handleClosePatientSurvey();
        });
    }
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={props.open}
        onClose={(event, reason) => {
          if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
              props.handleCloseModal()
          }
        }}
        className={classes.dialog}
      >
        <DialogTitle>Pesquisa de satisfação</DialogTitle>
        <DialogContent dividers>
          <Box className={classes.subtitleBox}>
            <Typography variant="subtitle1">
              {props.surveyMessage
                ? props.surveyMessage
                : `Ajude-nos a melhorar sempre marcando abaixo seu nivel de
            satisfação com o atendimento que você acabou de realizar!`}
            </Typography>
          </Box>
          <StyledToggleButtonGroup
            value={surveyInformations.classificacao}
            exclusive
            onChange={handleChangeSatisfactionFacesLevel}
            aria-label="botões de faces representativas de nivel satisfação"
          >
            <RedButton value="1" size="medium">
              <LightTooltip
                title="Muito Insatisfeito"
                open={surveyInformations.classificacao === "1"}
                placement="top"
              >
                <SentimentVeryDissatisfiedIcon />
              </LightTooltip>
            </RedButton>
            <SoftRedButton value="2" size="medium">
              <LightTooltip
                title="Pouco Insatisfeito"
                open={surveyInformations.classificacao === "2"}
                placement="top"
              >
                <SentimentDissatisfieIcon />
              </LightTooltip>
            </SoftRedButton>
            <YellowButton value="3" size="medium">
              <LightTooltip
                title="Neutro"
                open={surveyInformations.classificacao === "3"}
                placement="top"
              >
                <SentimentSatisfiedIcon />
              </LightTooltip>
            </YellowButton>
            <SoftGreenButton value="4" size="medium">
              <LightTooltip
                title="Pouco Satisfeito"
                open={surveyInformations.classificacao === "4"}
                placement="top"
              >
                <SentimentSatisfiedAltIcon />
              </LightTooltip>
            </SoftGreenButton>
            <GreenButton value="5" size="medium">
              <LightTooltip
                title="Muito Satisfeito"
                open={surveyInformations.classificacao === "5"}
                placement="top"
              >
                <SentimentVerySatisfiedIcon />
              </LightTooltip>
            </GreenButton>
          </StyledToggleButtonGroup>
          <Box className={classes.commentBox}>
            <FormLabel>Sugestões/Reclamações/Elogios</FormLabel>
            <TextField
              multiline
              rows={4}
              fullWidth
              maxLength="400"
              value={surveyInformations.texto}
              onChange={handleChangeSatisfactionComments}
              variant="outlined"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClosePatientSurvey} color="default">
            Cancelar
          </Button>
          <GreenButtonSubmit
            disabled={surveyInformations.classificacao === ""}
            variant="contained"
            color="primary"
            onClick={handleSubmitSurvey}
          >
            Enviar
          </GreenButtonSubmit>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  commentBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: theme.spacing(4, 0),
    gap: "5px",
  },
  subtitleBox: {
    marginBottom: theme.spacing(3),
    maxWidth: "380px",
  },
  surveyButtonsBox: {
    margin: theme.spacing(0.5),
    border: "none",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
  dialog: {
    zIndex: `${theme.zIndex.mobileStepper} !important`,
  },
  toggleButtonSelected: {
    boxShadow: `2px 2px${theme.palette.primary.main}`,
  },
}));

const SoftRedButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: theme.status.danger(300),
    "&:hover": {
      backgroundColor: theme.status.danger(),
    },
  },
  selected: {
    border: "unset",
  },
}))(ToggleButton);

const RedButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: theme.status.danger(),
    "&:hover": {
      backgroundColor: theme.status.danger(700),
    },
  },
  selected: {
    border: "unset",
  },
}))(ToggleButton);

const GreenButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: theme.status.success(),
    "&:hover": {
      backgroundColor: theme.status.success(700),
    },
  },
  selected: {
    border: "unset",
  },
}))(ToggleButton);

const SoftGreenButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: theme.status.success(300),
    "&:hover": {
      backgroundColor: theme.status.success(),
    },
  },
  selected: {
    border: "unset",
  },
}))(ToggleButton);

const YellowButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: theme.status.warning(),
    "&:hover": {
      backgroundColor: theme.status.warning(700),
    },
  },
  selected: {
    border: "unset",
  },
}))(ToggleButton);

const GreenButtonSubmit = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: theme.status.success(),
    "&:hover": {
      backgroundColor: theme.status.success(700),
    },
  },
}))(Button);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[0],
    fontSize: 11,
  },
}))(Tooltip);

const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      gap: "3px",
      justifyContent: "space-between",
    },
    gap: "15px",
    justifyContent: "space-around",
    border: "none",
  },
  grouped: {
    "&:not(:first-child)": {
      borderLeft: "unset",
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);
