import axios from "axios";
import { getToken } from "./endpoints/auth/endpoints";
import {
  defaultAlert,
  errorApiAlert,
  errorSessionAlert,
} from "../helpers/validation/alerts";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN,
  timeout: 20000,
});

api.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.authorization = `${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    switch (error.response?.status) {
      case 400: {
        if (error.response?.request.responseType !== "arraybuffer") {
          let { errors, msg } = error.response.data;
          if (errors) {
            let errorList = "";
            Object.keys(errors).map((erro) => {
              return (errorList += `${errors[erro]} `);
            });
            defaultAlert(
              `Encontramos erros nas informações! 
              ${errorList}`
            );
          } else {
            defaultAlert(`${msg}`);
          }
        } else {
          defaultAlert(`Os documentos são de preenchimento obrigatório`);
        }
        break;
      }
      case 401:
        if (!getToken() || error.response?.data?.status_token === false) {
          switch (error.response?.data?.error) {
            case 0:
              errorApiAlert(
                "Sessão inválida! Você precisa entrar novamente para continuar"
              ).then(async (result) => {
                if (result.value) {
                  localStorage.clear();
                  window.location.href = "/";
                  if (window.Tawk_API) {
                    window.Tawk_API.hideWidget();
                  }
                }
              });
              break;
            case 1:
              errorSessionAlert(
                "Sua sessão expirou por inatividade! Você precisa entrar novamente para continuar"
              ).then(async (result) => {
                if (result.value) {
                  localStorage.clear();
                  window.location.href = "/";
                  if (window.Tawk_API) {
                    window.Tawk_API.hideWidget();
                  }
                }
              });
              break;
            default:
          }
        } else {
          const { msg } = error.response.data;
          defaultAlert(msg, true, "Entendi", "warning");
        }
        break;
      case 403:
        defaultAlert(error.response.data.msg);
        break;
      case 404:
        let { msg } = error.response.data;
        defaultAlert(msg);
        break;
      case 405:
      case 500:
        defaultAlert(
          `Erro inesperado, por favor entre em contato com o suporte e informe o código do erro: COD: ${error.response.status}`
        );
        break;
      case undefined:
      default:
        errorApiAlert(
          "Erro na comunicação com a API, por favor entre em contato com o suporte."
        );
        break;
    }
    return Promise.reject(error);
  }
);

export default api;
