import * as yup from "yup";
import ptBr from "../../../../locale/yup-translation-pt-BR";
yup.setLocale(ptBr);


export const ImportCertificateSchema = yup.object().shape({
  certificado: yup
    .mixed()
    .required("O certificado é obrigatório")
    .test('fileType', 'Formato não suportado', (value) => {
      return value.match(/.pfx/) || value.match(/.p12/);
    }),
  pin: yup
    .string() // eslint-disable-next-line
    .min(4, "${path} deve conter pelo menos 4 dígitos") // eslint-disable-next-line
    .label("Pin do certificado")
    .required(),
});
