import * as yup from "yup";
import ptBr from "../../../../locale/yup-translation-pt-BR";
yup.setLocale(ptBr);

export const ImportSignatorySchema = yup.object().shape({
  cpf: yup
    .string() // eslint-disable-next-line
    .label("CPF")
    .min(14)
    .required(),
    pin: yup
    .string() // eslint-disable-next-line
    .label("Pin do certificado")
    .required(),
});
