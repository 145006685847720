import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ReplayIcon from "@material-ui/icons/Replay";
import LinkIcon from "@material-ui/icons/Link";
import EditIcon from "@material-ui/icons/Edit";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import DeleteIcon from "@material-ui/icons/Delete";
import CameraFrontIcon from "@material-ui/icons/CameraFront";
import { makeStyles } from "@material-ui/core/styles";
import {
  defaultAlert,
  confirmationAlert,
} from "../../helpers/validation/alerts";
import {
  deleteSchedule,
  getRoomNameByScheduledId,
  getResendMsgLink,
  getPatientRoomLink,
} from "../../services/endpoints/scheduling/endpoints";
import { getFormatedDateSystem } from "../../helpers/dateHelpers";
import { SCHEDULE_STATUS, AVAILABILITY_STATUS } from "../../helpers/constants";

const videoDomain = process.env.REACT_APP_VIDEOIFRAME_DOMAIN;

export default function DropDownMenuActionsSchedule({
  schedule,
  setPatienteRoomLink,
  idProfessional,
  calendarDate,
  setSchedule,
  setIsLoading,
}) {
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  async function handleResendLink(idSchedule) {
    confirmationAlert({
      messageBody:
        "Deseja realmente reenviar o link desta consulta para o paciente?",
    }).then(async (result) => {
      if (result.value) {
        setIsLoading(true);
        await getResendMsgLink(idSchedule)
          .then((data) => {
            defaultAlert(
              "Link enviado com sucesso!",
              true,
              "OK",
              "success",
              "Sucesso!"
            );
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
          });
      }
      setIsLoading(false);
    });
  }

  async function handlePatientRoomLink(idSchedule) {
    setIsLoading(true);
    await getPatientRoomLink(idSchedule)
      .then(({ data }) => {
        setPatienteRoomLink({
          open: true,
          link: data.link,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        defaultAlert(
          "Não foi possivel obter o link da videoconferência",
          true,
          "OK",
          "error",
          "Ops! Algo deu errado"
        );
        setIsLoading(false);
      });
  }

  function handleDeleteSchedule(scheduleId) {
    setIsLoading(true);
    confirmationAlert({
      messageBody: "Deseja realmente excluir esta agenda?",
      footerMessage: `<div style="text-align:center">
        <i>A agenda não será deletada, para fins de relatórios, registros e rastreabilidade a consulta será possível.</i>
        </div>`,
    }).then(async (result) => {
      if (result.value) {
        await deleteSchedule(scheduleId)
          .then((data) => {
            defaultAlert(
              "Agendamento excluída!",
              true,
              "OK",
              "success",
              "Sucesso!"
            );
            setSchedule((schedules) =>
              schedules.filter((schedule) => schedule.id_agenda !== scheduleId)
            );
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
          });
      }
      setIsLoading(false);
    });
  }

  async function handleMettingRoomLink(schedule) {
    if (
      schedule.cd_status === SCHEDULE_STATUS.desmarcado ||
      schedule.cd_status === SCHEDULE_STATUS.atendido
    ) {
      defaultAlert(
        `Você não pode realizar uma consulta para um agendamento com status ${schedule.descricao_status}`,
        true,
        "Entendi",
        "info"
      );
    } else {
      setIsLoading(true);
      const { data } = await getRoomNameByScheduledId(schedule.id_agenda);
      if (data.sala) {
        return history.push(`/teleconsult/room/${data.sala.room_name}`, {
          id_agenda: schedule.id_agenda,
          patientData: schedule.paciente,
          professional: data.usuario,
          id_sala: data.sala.id_sala,
        });
      }
      setIsLoading(false);
    }
  }

  function checkIsScheduled(data) {
    return data.status === AVAILABILITY_STATUS.disponivel
      ? true
      : data.cd_status !== SCHEDULE_STATUS.desmarcado &&
        data.cd_status !== SCHEDULE_STATUS.atendido &&
        data.cd_status !== SCHEDULE_STATUS.falta
      ? false
      : true;
  }

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={Boolean(anchorEl) ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) ? "true" : undefined}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu-actions"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {!!videoDomain ? (
          <>
            <MenuItem
              onClick={() => {
                handleResendLink(schedule.id_agenda);
              }}
              disabled={checkIsScheduled(schedule)}
            >
              <ListItemIcon>
                <ReplayIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Reenviar link ao paciente" />
            </MenuItem>

            <MenuItem
              onClick={(event) => {
                handlePatientRoomLink(schedule.id_agenda);
              }}
              disabled={checkIsScheduled(schedule)}
            >
              <ListItemIcon>
                <LinkIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Obter link do paciente" />
            </MenuItem>

            <Divider className={classes.divider} />
          </>
        ) : null}
        <MenuItem
          onClick={() => {
            history.push(
              `/scheduling/new/${idProfessional}/${getFormatedDateSystem(
                calendarDate
              )}/${schedule.hora}`
            );
          }}
          disabled={!checkIsScheduled(schedule)}
        >
          <ListItemIcon>
            <EventAvailableIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText primary="Agendar" />
        </MenuItem>

        <MenuItem
          onClick={() => {
            history.push(`/scheduling/edit/${schedule.id_agenda}`);
          }}
          disabled={checkIsScheduled(schedule)}
        >
          <ListItemIcon>
            <EditIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText primary="Editar" />
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleDeleteSchedule(schedule.id_agenda);
          }}
          disabled={
            schedule.status === AVAILABILITY_STATUS.disponivel
              ? true
              : schedule.cd_status
              ? false
              : true
          }
        >
          <ListItemIcon>
            <DeleteIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText primary="Excluir" />
        </MenuItem>

        <Divider className={classes.divider} />

        <MenuItem
          onClick={() => {
            handleMettingRoomLink(schedule);
          }}
          disabled={checkIsScheduled(schedule)}
        >
          <ListItemIcon>
            <CameraFrontIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText primary="Entrar" />
        </MenuItem>
      </Menu>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
