import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  Box,
  DialogActions,
  DialogContent,
  CircularProgress,
  StepConnector,
  StepLabel,
  Stepper,
  Step,
  Typography,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Icon,
  Backdrop,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import StarsIcon from "@material-ui/icons/Stars";
import {
  getPatientTerm,
  acceptPatientTerms,
} from "../../services/endpoints/public/endpoints";
import INSTRUCTIONS from "../../locale/instructions-patient-screen";
import termSuccessImage from "../../assets/images/success_patient_terms.png";

export default function PatientTermsModal(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [termTitle, setTermTitle] = useState("");
  const [termContent, setTermContent] = useState("");
  const [termAccepted, setTermAccepted] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  useEffect(() => {
    setIsLoading(true);
    const fetcPatientTerm = () => {
      getPatientTerm().then(({ data }) => {
        setTermTitle(data.termo_de_uso_paciente.nome_termo);
        setTermContent(data.termo_de_uso_paciente.descricao);
        setIsLoading(false);
      });
    };
    fetcPatientTerm();
  }, []);

  function handleNextStep() {
    if (activeStep < 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setIsLoadingSubmit(true);
      handleSubmitAcceptTerm(props.patientToken);
    }
  }

  function handleBackStep() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  function handleAcceptTerm(checked) {
    setTermAccepted(checked);
  }

  function handleSubmitAcceptTerm(patientToken) {
    acceptPatientTerms(patientToken)
      .then(() => {
        props.handleChangeTermsState();
        props.handleCloseTerms();
        setIsLoadingSubmit(false);
      })
      .catch(() => {
        setIsLoadingSubmit(false);
      });
  }

  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      "& $line": {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    completed: {
      "& $line": {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: "#eaeaf0",
      borderRadius: 1,
    },
  })(StepConnector);

  const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: "#ccc",
      zIndex: 1,
      color: "#fff",
      width: 50,
      height: 50,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
    },
    active: {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    },
  });

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
      1: <AssignmentTurnedInIcon />,
      2: <BeenhereIcon />,
      3: <StarsIcon />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }

  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
  };

  function getSteps() {
    return ["Termos de uso", "Instruções de uso da plataforma", "Confirmação"];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Box className={classes.flexTermsBox}>
            <Typography variant="h6">{termTitle}</Typography>
            <Box dangerouslySetInnerHTML={{ __html: termContent }}></Box>
            {isLoading ? (
              ""
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    name="checkedB"
                    value={termAccepted}
                    checked={termAccepted}
                    onChange={(e) => { handleAcceptTerm(e.target.checked)}}
                    color="primary"
                  />
                }
                label="Eu aceito os termos de uso"
              />
            )}
          </Box>
        );
      case 1:
        return (
          <Box>
            <List className={classes.listInstructions}>
              {INSTRUCTIONS.map((item, index) => (
                <ListItem
                  key={index}
                  className={classes.listInstructionsItem}
                  alignItems="center"
                >
                  <ListItemAvatar>
                    <Avatar className={classes.avatarOutlined}>
                      <Icon>{item.icon}</Icon>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText className={classes.listInstructionsText}>
                    <span
                      dangerouslySetInnerHTML={{ __html: item.text }}
                    ></span>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
        );
      case 2:
        return (
          <Box className={classes.termsImageSuccessBox}>
            <Typography variant="h4">Tudo certo!</Typography>
            <img
              src={termSuccessImage}
              className={classes.termsImageSuccess}
              alt="Imagem representativa informando que os termos foram aceitos com sucesso"
            />
          </Box>
        );
      default:
        break;
    }
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoadingSubmit}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        
        open={props.open}
        onClose={(event, reason) => {
          if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
              props.handleCloseModal()
          }
        }}
        className={classes.dialog}
      >
        <DialogContent>
          <Box className={classes.flexContainer}>
            <Box className={classes.root}>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                className={classes.stepper}
                connector={<ColorlibConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Box>
                <Box>
                  {getStepContent(activeStep)}
                  <Box className={classes.flexBottomButtons}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBackStep}
                      color="default"
                      className={classes.button}
                    >
                      Voltar
                    </Button>
                    <GreenButton
                      disabled={!termAccepted}
                      variant="contained"
                      color="primary"
                      onClick={handleNextStep}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1
                        ? "Iniciar Consulta"
                        : "Prosseguir"}
                    </GreenButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  flexBottomButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
  flexTermsBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "300px",
    overflow: "overlay",
  },
  listInstructions: {
    height: "300px",
    overflow: "overlay",
  },
  listInstructionsItem: {
    paddingTop: 0,
  },
  listInstructionsText: {
    backgroundColor: "#f5bec4",
    padding: theme.spacing(1),
    borderRadius: 4,
  },
  avatarOutlined: {
    backgroundColor: "unset",
    border: `${theme.palette.primary.main} solid 2px`,
    color: theme.palette.primary.main,
  },
  termsImageSuccessBox: {
    height: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  termsImageSuccess: {
    maxHeight: "200px",
    margin: theme.spacing(2, 0),
  },
  stepper: {
    padding: theme.spacing(1),
  },
  root: {
    width: "100%",
  },
  button: {
    width: "100%",
    margin: theme.spacing(0, 1),
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: theme.palette.primary.main,
  },
  dialog: {
    zIndex: `${theme.zIndex.mobileStepper} !important`,
  },
}));

const GreenButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
