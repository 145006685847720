import React, { useState, useEffect } from "react";
import { importSignatory } from "../../services/endpoints/profile/endpoint";
import { useForm, Controller } from "react-hook-form";
import { ImportSignatorySchema } from "../../helpers/validation/Schemas/ImportSignatorySchema/schema";
import { getCpfFormated } from "../../helpers/stringHelpers";
import { defaultAlert } from "../../helpers/validation/alerts";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Box,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const ImportSignatoryDialog = ({
  open,
  close,
  cpfSignatory,
  setUserCertificateStatus
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const {
    errors,
    handleSubmit,
    control,
    setError,
    setValue,
  } = useForm({
    validationSchema: ImportSignatorySchema,
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      cpf: getCpfFormated(cpfSignatory),
      pin: ""
    },
  });

  useEffect(() => {
    setValue('cpf', getCpfFormated(cpfSignatory));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function handleClose() {
    close();
  }

  async function handleForm(data) {
    setIsLoading(true);
    await importSignatory({
      cpf: data.cpf,
      pin: data.pin,
    })
      .then(({ data }) => {
        if (data.status) {
          setUserCertificateStatus(true);
          defaultAlert(
            "Signatáro importado Sucesso! Agora você já pode assinar documentos e registros de pacientes.",
            false,
            "OK",
            "success",
            "Tudo certo!"
          ).then((result) => {
            if (result.value) {
              close();
            }
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let { errors } = error.response.data;
          Object.keys(errors).map((erro) => {
            return setError(erro, "notMatch", errors[erro]);
          });
        }
        setIsLoading(false);
      });
  }
  return (
    <>
      <Dialog
        scroll="paper"
        open={open}
        fullWidth={true}
        maxWidth="sm"
        style={{zIndex:1}}
        aria-labelledby="Importar signatário"
        aria-describedby="Caixa de dialogo para importar signatário"
      >
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <form
          method="POST"
          encType="multipart/form-data"
          onSubmit={handleSubmit((data) => handleForm(data))}
        >
          <DialogTitle id="alert-dialog-title">
            Importar signatário
        </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText>
              Confirme o PIN do ceriticado
          </DialogContentText>
            <Box className={classes.flexContainer}>
              <FormControl
                error={!!errors.cpf}
                className={classes.flexItems}
              >
                <InputLabel>CPF do signatário</InputLabel>
                <Controller
                  as={<Input />}
                  type="text"
                  rules={{ required: true }}
                  control={control}
                  name="cpf"
                  readOnly={true}
                  defaultValue={getCpfFormated(cpfSignatory)}
                />
                <FormHelperText>{errors?.cpf?.message}</FormHelperText>
              </FormControl>
            </Box>
            <Box className={classes.flexContainer}>
              <FormControl
                error={!!errors.pin}
                className={classes.flexItems}
              >
                <InputLabel>Pin do Certificado</InputLabel>
                <Controller
                  as={<Input />}
                  type="password"
                  autoComplete="new-password"
                  rules={{ required: true }}
                  control={control}
                  name="pin"
                />
                <FormHelperText>{errors?.pin?.message}</FormHelperText>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              size="large"
              variant="contained"
              type="submit"
              color="primary"
            >
              Importar
          </Button>
            <Button onClick={handleClose} color="default">
              Fechar
          </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  gridRoot: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paperStyle: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  flexContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  flexContainerButton: {
    marginTop: theme.spacing(5),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "flex-start",
    width: "100%",
    gap: "10px",
    margin: theme.spacing(2, 0),
  },
  flexItems: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2),
    },
    width: "100%",
  },
}));
export default ImportSignatoryDialog;
