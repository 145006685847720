import api from "../../api";

export const createSettingsSchedule = async (params) => {
  return api.post("agenda/config/create", params);
};

export const updateSettingsSchedule = async (settings_schedule_id, params) => {
  return api.put(`agenda/config/update/${settings_schedule_id}`, params);
};

export const deleteSettingsSchedule = async (settings_schedule_id) => {
  return api.delete(`agenda/config/delete/${settings_schedule_id}`);
};

export const getSettingsScheduleId = async (settings_schedule_id) => {
  return api.get(`agenda/config/id/${settings_schedule_id}`);
};

export const getSettingsScheduleProfessional = async (idProfessional) => {
  return api.get(`agenda/config/all_prestador/${idProfessional}`);
};

export const getSettingsScheduleMyProfessional = async () => {
  return api.get(`agenda/config/all_prestador/`);
};

export const getCheckDateScheduleProfessional = async (
  idProfessional,
  date
) => {
  return api.get(
    `agenda/config/existe_agenda_corrente/${idProfessional}/${date}`
  );
};
