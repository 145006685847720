import api from "../../api";

export const getSystemParameters = async () => {
  return api.get("config");
};

export const updateSystemParameters = async (params) => {
  return api.put("config/update", params);
};

export const checkSignatureService = async (params) => {
  return api.get("config/check_servico_assinatura", params);
};
