import React, { useEffect, useState } from "react";
import MaterialTable from "@material-table/core";
import { Icon, Typography, Button, Box, Chip } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { green } from "@material-ui/core/colors";
import Content from "../../../../components/Content";
import pt_BR from "../../../../locale/material-table-locale";
import {
  confirmationAlert,
  defaultAlert,
} from "../../../../helpers/validation/alerts";
import {
  deleteDocumentType,
  getAllDocumentTypes,
} from "../../../../services/endpoints/documentTypes/endpoints";
import { getFormatedDateTimeUI } from "../../../../helpers/dateHelpers";

export default function DocumentTypeList() {
  const classes = useStyles();
  const history = useHistory();

  const [documentTypes, setDocumentTypes] = useState([]);
  const [isLoadding, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDocumentTypes = async () => {
      await getAllDocumentTypes()
        .then(({ data }) => {
          setDocumentTypes(data.list);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };

    fetchDocumentTypes();
    return () => {};
  }, []);

  async function handleDeleteDocumentType(idDocumentType) {
    setIsLoading(true);
    confirmationAlert({
      messageBody: "Deseja realmente excluir este tipo de documento?",
    }).then(async (result) => {
      if (result.value) {
        await deleteDocumentType(idDocumentType)
          .then(() => {
            defaultAlert(
              "Tipo de documento excluído!",
              true,
              "OK",
              "success",
              "Sucesso!"
            );
            setDocumentTypes((documentTypesData) =>
              documentTypesData.filter(
                (documentType) => documentType.id_tipo_doc !== idDocumentType
              )
            );
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
          });
      }
      setIsLoading(false);
    });
  }

  function handleEditTypeDoc(idDocumentType) {
    history.push(`/documentTypes/edit/${idDocumentType}`);
  }

  const COLUMNS = [
    { title: "Tipo", field: "nome_tipo" },
    { title: "Descrição", field: "descricao" },
    {
      title: "Pertence ao prontuário",
      render: (data) => (
        <Chip
          size="small"
          label={data.pertence_prontuario === "0" ? "Não" : "Sim"}
          color={data.pertence_prontuario === "0" ? "primary" : "default" }
          style={
            data.pertence_prontuario === "0"
              ? {}
              : { borderColor: "green", color: "green" }
          }
          variant="outlined"
        />
      ),
    },
    {
      title: "Criado em",
      render: (data) => getFormatedDateTimeUI(data.created_at),
    },
  ];

  const TABLE_ACTIONS_ADMIN = [
    {
      icon: "edit",
      tooltip: "Editar tipo de documento",
      onClick: (event, rowData) => {
        handleEditTypeDoc(rowData.id_tipo_doc);
      },
    },
    {
      icon: "delete",
      tooltip: "Excluir tipo de documento",
      onClick: (event, rowData) => {
        handleDeleteDocumentType(rowData.id_tipo_doc);
      },
    },
  ];

  return (
    <>
      <Content>
        <MaterialTable
          style={{ margin: "0px 20px", marginBottom: "20px" }}
          title={
            <Box className={classes.titleBox}>
              <Typography variant="h6" component="div">
                Listagem de tipos de documentos
              </Typography>
              <GreenButton
                className={classes.newButton}
                onClick={() => history.push("/documentTypes/new")}
                variant="outlined"
                color="default"
                size="small"
                endIcon={<Icon>add</Icon>}
              >
                Criar
              </GreenButton>
            </Box>
          }
          isLoading={isLoadding}
          localization={pt_BR}
          columns={COLUMNS}
          actions={TABLE_ACTIONS_ADMIN}
          data={documentTypes}
          options={{
            search: true,
            actionsColumnIndex: -1,
          }}
        />
      </Content>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  newButton: {
    marginLeft: theme.spacing(4),
  },
  titleBox: {
    display: "flex",
    maxHeight: "30px",
  },
}));

const GreenButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
