import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { defaultAlert } from "../../helpers/validation/alerts";

export default function VideoIframe({ roomId , user, styleVideo, dr}) {
  const classes = useStyles();
  function startConference() {
    try {
      const domain = process.env.REACT_APP_VIDEOIFRAME_DOMAIN;
      const options = {
        roomName: roomId,
        userInfo:{
          email: user.email ?? '',
          displayName: dr ? user.nome : 'Paciente: '+user.nome
        },
        configOverwrite: {
          startWithAudioMuted: false,
          disableDeepLinking: true,
          disableRemoteMute: true,
          enableNoisyMicDetection: false,
          disableInviteFunctions: true,
          defaultLanguage: "ptBR",
          doNotStoreRoom: true,
          remoteVideoMenu: {
            disableKick: true,
          },
          prejoinPageEnabled: false
        },
        interfaceConfigOverwrite: {
          APP_NAME: "Telesaúde",
          SHOW_INVITE_MORE_HEADER: false,
          DEFAULT_LOCAL_DISPLAY_NAME: "Eu",
          DEFAULT_REMOTE_DISPLAY_NAME: dr ? user.nome : 'Paciente: '+user.nome,
          DEFAULT_LOGO_URL: "http://www.om30.com.br/wp-content/uploads/2020/06/LOGO-VINHO.png",
          BRAND_WATERMARK_LINK: 'http://www.om30.com.br',
          GENERATE_ROOMNAMES_ON_WELCOME_PAGE: false,
          JITSI_WATERMARK_LINK: "",
          LIVE_STREAMING_HELP_LINK: "",
          SHOW_JITSI_WATERMARK: false,
          SHOW_BRAND_WATERMARK: true,
          SHOW_WATERMARK_FOR_GUESTS: false,
          DISABLE_VIDEO_BACKGROUND: true,
          SHOW_CHROME_EXTENSION_BANNER: false,
          MOBILE_APP_PROMO: false,
          LOCAL_THUMBNAIL_RATIO: 1,
          VIDEO_QUALITY_LABEL_DISABLED: true,
          TOOLBAR_BUTTONS: [
            "microphone",
            "camera",
            "fullscreen",
            "fodeviceselection",
            "filmstrip",
          ],
        },
        parentNode: document.querySelector("#containerVideo"),
      };
      // eslint-disable-next-line
      const jApi = new window.JitsiMeetExternalAPI(domain, options);
    } catch (error) {
      console.error("Failed to load Jitsi API", error);
    }
  }

  useEffect(() => {
    if (window.JitsiMeetExternalAPI) {
      startConference();
      if (
        window.navigator.userAgent.match(/iPad/i) ||
        window.navigator.userAgent.match(/iPhone/i)
      ) {
        defaultAlert(
          `Para usuários de dispositivos Apple é necessário habilitar as permissões de video e audio para o navegador Safari. Toque 2 vezes nos icones de audio e video para exibir!`,
          false,
          "Entendi",
          "info",
          "Atenção"
        );
      }
    } else {
      console.log("Jitsi Meet API script not loaded");
    }
    // eslint-disable-next-line
  }, []);

  return <div className={classes.video} style={styleVideo} id="containerVideo" />;
}

const useStyles = makeStyles((theme) => ({
  video: {
    margin: theme.spacing(0, 1),
    width: "100%",
    maxWidth: "1000px",
    height: "100%",
    minHeight: "400px",
    maxHeight: "650px",
    border: "none",
    borderRadius: "5px",
  },
}));