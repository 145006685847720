import React, { useState, useEffect } from "react";
import Noty from "noty";
import "../../styles/metroui.css";
import "../../styles/noty.css";
import { useParams } from "react-router-dom";
import {
  Grid,
  Box,
  Backdrop,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PatientTermsModal from "../../components/PatientTermsModal";
import PatientSurveyModal from "../../components/PatientSurveyModal";
import VideoIframe from "../../components/VideoIframe";
import { getRoomByToken } from "../../services/endpoints/room/endpoints";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

import io from "socket.io-client";
import api from "../../services/api";

import logoImage from "../../assets/images/logo.png";
import notFoundImage from "../../assets/images/roomId_notfound_or_expired.png";

var socket;

export default function MeetingRoom(props) {
  const classes = useStyles();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isInvalid, setIsInvalid] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [openPatientSurvey, setOpenPatientSurvey] = useState(false);
  const [isConsultEnded, setIsConsultEnded] = useState(false);
  const [roomId, setRoomId] = useState("");
  const [patientToken, setPatientToken] = useState("");
  const [surveyMessage, setSurveyMessage] = useState("");
  const [clientImage, setClientImage] = useState("");
  const [roomInternalId, setRoomInternalId] = useState("");
  const [user, setUser] = useState({});

  useEffect(() => {
    if (id) {
      socket = !!process.env.REACT_APP_SOCKET_DOMAIN ? io(process.env.REACT_APP_SOCKET_DOMAIN) : null;
      (async () => {
        await getRoomByToken(id)
          .then(({ data }) => {
            const { sala, paciente } = data;
            setRoomId(sala.room_name);
            setRoomInternalId(sala.id_sala);
            if (sala.termo_de_uso_aceito) {
              setTermsAccepted(!termsAccepted);
            } else {
              setOpenTerms(!openTerms);
              setPatientToken(sala.token);
            }
            if (sala.logo_b64) {
              setClientImage(sala.logo_b64);
            }
            setSurveyMessage(sala.msg_pesquisa_de_satisfacao);
            setUser(paciente);
            // handleCloseRoom(sala.id_sala);
          })
          .catch(() => {
            setIsInvalid(true);
          });
        setIsLoading(false);
      })();
    } else {
      setIsLoading(false);
      setIsInvalid(true);
      if(socket){
        socket.disconnect();
      }
    }
    return () => {
      if(socket){
        socket.disconnect();
      }
    }
  }, [id]);

  useEffect(() => {
    if(!!socket && !!roomInternalId){
      socket.on(`finish_call_room_${roomInternalId}`, function (data) {
        setOpenPatientSurvey(true);
        setRoomId("");
        setIsConsultEnded(true);
        setIsInvalid(true);
        navigator.sendBeacon(`${api.defaults.baseURL}room/close_room/${roomInternalId}`, "");
      });
    }
    if(!!roomInternalId){
      window.addEventListener("beforeunload", () => {
        navigator.sendBeacon(`${api.defaults.baseURL}room/close_room/${roomInternalId}`, "");
      });
    }
  }, [roomInternalId]);

  function handleCloseTerms() {
    setOpenTerms(false);
  }

  function handleClosePatientSurvey() {
    setOpenPatientSurvey(!openPatientSurvey);
  }

  function handleChangeTermsState() {
    setTermsAccepted(!termsAccepted);
  }

  function getUploadParams(props) {
    return {
      url: `${process.env.REACT_APP_API_DOMAIN}/room/upload_file/${id}`,
    };
  }

  function handleChangeStatus({ meta, file, xhr }, status) {
    if (status === "done") {
      new Noty({
        text: `Arquivo: ${meta.name} enviado com sucesso`,
        type: "success",
        theme: "metroui",
        timeout: 3000,
      }).show();
      try {
        const { status, id_arquivo } = JSON.parse(xhr.response);
        if (status) {
          if(!!socket){
            socket.emit("upload_file", {
              id_sala: roomInternalId,
              id_arquivo,
            });
          }
        }
      } catch (error) {
        return false;
      }
    }

    if (status === "aborted") {
      new Noty({
        text: `Upload abortado: ${meta.name}`,
        type: "error",
        theme: "metroui",
        timeout: 3000,
      }).show();
    }

    if (status === "error_upload") {
      new Noty({
        text: `Arquivo não suportado: ${meta.name}`,
        type: "error",
        theme: "metroui",
        timeout: 3000,
      }).show();
    }
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box component="nav" className={classes.header}>
        <img
          className={classes.logoCompanyImage}
          src={logoImage}
          alt="Logotipo - OM30 - Tecnologia movida por pessoas"
        />
        {clientImage ? (
          <img
            className={classes.logoClient}
            src={clientImage}
            alt="Logo da Instituição"
          />
        ) : (
          ""
        )}
      </Box>
      <Grid container className={classes.boxMeetingRoom}>
        {roomId && termsAccepted ? (
          <>
            <Grid item xs={12} sm={8} md={8}>
              {user && Object.keys(user).length !== 0 ? <VideoIframe roomId={roomId} user={user} styleVideo={{ margin: 0 }}/> : null }
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              className={classes.gridUploadFiles}
            >
              <Typography component="h1" variant="h6">
                Enviar arquivos
              </Typography>
              <Dropzone
                styles={{
                  dropzone: { overflow: "overlay", padding: "10px" },
                }}
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                accept="image/*,audio/*,video/*,application/pdf"
                inputContent="Arraste ou clique para adicionar arquivos"
                inputWithFilesContent="Adicionar arquivos"
                submitButtonDisabled={true}
                SubmitButtonComponent={null}
                canRemove={false}
                maxSizeBytes={60000000}
              />
              <legend>
                <i>Tamanho máximo por arquivo: 60mb.</i>
                <br />
                <i>Arquivos suportados: JPEG, JPG, PNG, GIF, PDF.</i>
                <br />
              </legend>
            </Grid>
          </>
        ) : (
          <PatientTermsModal
            open={openTerms}
            handleCloseTerms={handleCloseTerms}
            handleChangeTermsState={handleChangeTermsState}
            patientToken={patientToken}
          />
        )}
        {isInvalid ? (
          <Box className={classes.boxInformationsWhenInvalid}>
            <Typography component="h1" variant="h5">
              {isConsultEnded
                ? "Sua consulta terminou!"
                : "Sala de conferência não encontrada ou expirada"}
            </Typography>
            <img
              src={notFoundImage}
              alt="Ilustração de pessoa confusa com o horarário"
            />
            <Typography component="h2" color="primary" variant="h6">
              Verifique o link acessado e tente novamente!
            </Typography>
          </Box>
        ) : (
          ""
        )}
        <PatientSurveyModal
          open={openPatientSurvey}
          tokenId={id}
          surveyMessage={surveyMessage}
          handleClosePatientSurvey={handleClosePatientSurvey}
        />
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  boxMeetingRoom: {
    minHeight: "600px",
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    height: "60px",
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  logoCompanyImage: {
    height: "60px",
    marginTop: "-12px",
  },
  logoClient: {
    height: "60px",
    objectFit: "contain",
    padding: "4px",
  },
  boxInformationsWhenInvalid: {
    marginTop: "80px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    padding: theme.spacing(1, 0.5),
  },
  gridUploadFiles: {
    margin: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
    maxHeight: "600px",
    overflow: "overlay",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
}));
