import * as yup from "yup";
import ptBr from "../../../../locale/yup-translation-pt-BR";
yup.setLocale(ptBr);

export const SystemParametersSchema = yup.object().shape({
  nome_global: yup
    .string()
    .min(5)
    .max(255)
    .label("Nome da Instituição")
    .required(),
  logo_b64: yup.string().label("Imagem da Instituição").required(),
  link_call: yup
    .string()
    .url()
    .label("URL Videoconferência")
    .required(),
  link_documentos: yup
    .string()
    .url()
    .label("URL Videoconferência")
    .required(),
  link_forgot_pass: yup
    .string()
    .url()
    .label("URL Recuperação de senha")
    .required(),
  max_tentativas_login: yup
    .string()
    .label("Número máximo de tentativas consecutivas de login")
    .max(3)
    .matches(/^[0-9]*$/, "Este campo aceita somente números")
    .required(),
  tempo_de_sessao: yup
    .string()
    .label("Tempo de sessão do usuário (minutos)")
    .max(4)
    .matches(/^[0-9]*$/, "Este campo aceita somente números")
    .required(),
  validade_senha: yup
    .string()
    .label("Validade de senha (meses)")
    .max(2)
    .matches(/^[0-9]*$/, "Este campo aceita somente números")
    .required(),
  hash_assinatura_simples: yup
    .string()
    .label("Hash de autenticação"),
});
