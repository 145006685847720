import api from "../../api";

export const getCurrentPatientTerm = async () => {
  return api.get("termo/paciente/current");
};

export const getAllVersionedPatientTerms = async () => {
  return api.get("termo/paciente");
};

export const createPatientTerms = async (params) => {
  return api.post("termo/paciente/create", params);
};

export const updatePatientTerms = async (termId, params) => {
  return api.put(`termo/paciente/update/${termId}`, params);
};
