import api from "../../api";

export const createSchedule = async (params) => {
  return api.post("agenda/agendamento/create", params);
};

export const createFitSchedule = async (params) => {
  return api.post("agenda/agendamento/create_encaixe", params);
};

export const updateSchedule = async (schedule_id, params) => {
  return api.put(`agenda/agendamento/update/${schedule_id}`, params);
};

export const patchSchedule = async (schedule_id, params) => {
  return api.patch(`agenda/agendamento/update/${schedule_id}`, params);
};

export const deleteSchedule = async (schedule_id, params) => {
  return api.delete(`agenda/agendamento/delete/${schedule_id}`, params);
};

export const getRoomNameByScheduledId = async (schedule_id) => {
  return api.get(`agenda/agendamento/get_room/${schedule_id}`);
};

export const setFinalizeConsult = async (schedule_id) => {
  return api.get(`agenda/agendamento/set_attended/${schedule_id}`);
};

export const updateStatusScheduled = async (schedule_id, params) => {
  return api.patch(`agenda/agendamento/update_status/${schedule_id}`, params);
};

export const getScheduleById = async (schedule_id) => {
  return api.get(`agenda/agendamento/id/${schedule_id}`);
};

export const getTodayAllSchedule = async () => {
  return api.get("agenda/agendamento");
};

export const getScheduleByDate = async (date, idProfessional) => {
  return api.get(`agenda/agendamento/date/${date}/${idProfessional}`);
};

export const getScheduleBetweenDates = async (firstDate, lastDate) => {
  return api.get(`agenda/agendamento/between_date/${firstDate}/${lastDate}`);
};

export const getResendMsgLink = async (idSchedul) => {
  return api.get(`agenda/agendamento/reeviar_sms/${idSchedul}`);
};

export const getPatientRoomLink = async (idSchedul) => {
  return api.get(`agenda/agendamento/room_patient_link/${idSchedul}`);
};