import React, { useState, useEffect } from "react";
import {
  Paper,
  Button,
  Grid,
  Typography,
  Box,
  TextField,
  IconButton,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import logoImage from "../../assets/images/logo.png";
import {
  verifyRecoverToken,
  setNewPassword,
} from "../../services/endpoints/recover/endpoints";
import { defaultAlert } from "../../helpers/validation/alerts";
import { RGX_SENHA } from "../../../src/helpers/regex";

export default function PasswordChangePage(props) {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [isTokenValid, setIsTokenValid] = useState(false);

  const [passData, setPassData] = useState({
    password: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
  });
  const [errorData, setErrorData] = useState({
    passwordErrorMessage: "",
    passwordError: false,
    passwordConfirmError: false,
    passwordConfirmErrorMessage: "",
  });
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(true);

  useEffect(() => {
    if (id) {
      const verifyToken = async () => {
        verifyRecoverToken(id)
          .then(({ data }) => {
            setIsTokenValid(true);
          })
          .catch(() => {
            history.push("/");
          });
      };
      verifyToken();
    } // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (
      passData.password.length >= 6 &&
      passData.password !== passData.confirmPassword
    ) {
      setErrorData({
        // eslint-disable-next-line
        ...errorData, // eslint-disable-next-line
        ["setNewPass"]: true, // eslint-disable-next-line
        ["passwordConfirmErrorMessage"]: "As senhas não coincidem",
      });
      setIsConfirmButtonDisabled(true);
    } else {
      setErrorData({
        // eslint-disable-next-line
        ...errorData, // eslint-disable-next-line
        ["passwordConfirmError"]: false,
      });
    } // eslint-disable-next-line
  }, [passData.password]);

  function handleClickShowPassword() {
    // eslint-disable-next-line
    setPassData({ ...passData, ["showPassword"]: !passData.showPassword });
  }
  function handleClickShowConfirmPassword() {
    setPassData({
      ...passData, // eslint-disable-next-line
      ["showConfirmPassword"]: !passData.showConfirmPassword,
    });
  }

  function handlePasswordChange(e) {
    // eslint-disable-next-line
    setPassData({ ...passData, ["password"]: e.target.value });
    if (e.target.value.length < 8 && e.target.value !== "") {
      setErrorData({
        ...errorData, // eslint-disable-next-line
        ["passwordError"]: true, // eslint-disable-next-line
        ["passwordErrorMessage"]: "A senha deve conter no minimo 8 caracteres",
      });
      setIsConfirmButtonDisabled(true);
    } else {
      let regexPass = new RegExp(RGX_SENHA);
      if(!regexPass.test(e.target.value)){
        setErrorData({
          ...errorData, // eslint-disable-next-line
          ["passwordError"]: true, // eslint-disable-next-line
          ["passwordErrorMessage"]: "A senha deve conter caracteres alfabéticos e no mímino um número",
        });
        setIsConfirmButtonDisabled(true);
      }else{
        setErrorData({
          ...errorData, // eslint-disable-next-line
          ["passwordError"]: false, // eslint-disable-next-line
          ["passwordErrorMessage"]: "",
        });
      }
    }
  }

  function handleConfirmPasswordChange(e) {
    // eslint-disable-next-line
    setPassData({ ...passData, ["confirmPassword"]: e.target.value });
    if (passData.password !== e.target.value || e.target.value === "") {
      setErrorData({
        ...errorData, // eslint-disable-next-line
        ["passwordConfirmError"]: true, // eslint-disable-next-line
        ["passwordConfirmErrorMessage"]: "As senhas não coincidem",
      });
      setIsConfirmButtonDisabled(true);
    } else {
      setErrorData({
        ...errorData, // eslint-disable-next-line
        ["passwordConfirmError"]: false, // eslint-disable-next-line
        ["passwordConfirmErrorMessage"]: "",
      });
      setIsConfirmButtonDisabled(false);
    }
  }

  function handleSetNewPassword() {
    if (!errorData.passwordError && !errorData.passwordConfirmError) {
      setNewPassword(id, { nova_senha: passData.confirmPassword }).then(
        ({ data }) => {
          defaultAlert(data.msg).then((result) => {
            if (result.value) {
              history.push("/");
            }
          });
        }
      );
    }
  }

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={12}>
        <div className={classes.logoCompanyBox}>
          <img
            className={classes.logoCompanyImage}
            src={logoImage}
            alt="Logotipo - OM30 - Tecnologia movida por pessoas"
          />
        </div>
      </Grid>
      {isTokenValid ? (
        <Grid item xs={12} className={classes.inputGrid}>
          <Box component={Paper} className={classes.paper}>
            <Typography component="h1" variant="h6">
              Redefinição de senha
            </Typography>
            <Typography component="h1" variant="caption">
              Informe e confirme sua nova senha abaixo:
            </Typography>
            <Box className={classes.inputs}>
              <TextField
                error={errorData.passwordError}
                type={passData.showPassword ? "text" : "password"}
                fullWidth
                label="Digite a nova senha"
                value={passData.password}
                onChange={handlePasswordChange}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleClickShowPassword}>
                      {passData.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  ),
                }}
                helperText={
                  errorData.passwordError ? errorData.passwordErrorMessage : ""
                }
              />
              <TextField
                error={errorData.passwordConfirmError}
                fullWidth
                type={passData.showConfirmPassword ? "text" : "password"}
                label="Confirme a nova senha"
                value={passData.confirmPassword}
                onChange={handleConfirmPasswordChange}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleClickShowConfirmPassword}>
                      {passData.showConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  ),
                }}
                helperText={
                  errorData.passwordConfirmError
                    ? errorData.passwordConfirmErrorMessage
                    : ""
                }
              />
            </Box>
            <GreenButton
              color="primary"
              fullWidth
              disabled={isConfirmButtonDisabled}
              onClick={handleSetNewPassword}
              variant="contained"
            >
              Confirmar
            </GreenButton>
          </Box>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexDirection: "column",
  },
  logoCompanyBox: {
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    top: 0,
    height: "80px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoCompanyImage: {
    height: "70px",
    marginTop: "-12px",
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  inputGrid: {
    marginTop: theme.spacing(8),
    alignSelf: "center",
    maxWidth: "500px",
  },
  inputs: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

const GreenButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
