import * as yup from "yup";
import moment from "moment";
import ptBr from "../../../../locale/yup-translation-pt-BR";
import {
  RGX_NOME_COMPLETO,
  RGX_NAO_CONTEM_NUMEROS,
  RGX_CELULAR_BR,
  RGX_SENHA
} from "../../../regex";
yup.setLocale(ptBr);

export const UpdateMyUserSchema = yup.object().shape({
  cd_usuario: yup.string().min(3).label("Login").required(),
  nome: yup
    .string()
    .min(3) // eslint-disable-next-line
    .matches(RGX_NAO_CONTEM_NUMEROS, "${path} não pode conter números", {
      excludeEmptyString: false,
    }) // eslint-disable-next-line
    .matches(RGX_NOME_COMPLETO, "${path} deve conter Nome e Sobrenome", {
      excludeEmptyString: false,
    })
    .label("Nome Completo")
    .required(),
  senha: yup
    .string()
    .min(8)// eslint-disable-next-line
    .matches(RGX_SENHA, "${path} deve conter caracteres alfabéticos e no mímino um número", {
      excludeEmptyString: false,
    })
    .label("Senha")
    .required(),
  confirmacao_senha: yup
    .string()// eslint-disable-next-line
    .when("senha", {
      is: senha => (senha && senha.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref("senha")], "Confirmação de senha não coincide com senha")
    })
    .label("Confirmação de senha")
    .required(),
  email: yup.string().email().label("Email").required(),
  data_nascimento: yup
    .date()
    .min(moment(new Date(1900, 0, 1)).format("DD/MM/YYYY"))
    .max(
      moment(new Date()).format("YYYY-MM-DD"),
      `A data deve ser menor que ${moment(new Date()).format("DD/MM/YYYY")}`
    )
    .label("Data de Nascimento")
    .required() // eslint-disable-next-line
    .typeError("${path} não contém uma data valida"),
  cpf: yup
    .string() // eslint-disable-next-line
    .label("CPF")
    .min(14)
    .required(),
  celular: yup
    .string() // eslint-disable-next-line
    .matches(RGX_CELULAR_BR, "Número de celular inválido", {
      excludeEmptyString: false,
    })
    .min(14)
    .label("Celular")
    .required(),
});
