import api from "../../api";

export const uploadFile = async (token, formData) => {
  return api.get(`room/upload_file/${token}`, formData, {
    headers: {
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
    },
  });
};

export const getUploadedFile = async (fileId) => {
  return api.get(`arquivo/read_file/${fileId}`, {
    responseType: "arraybuffer",
  });
};
