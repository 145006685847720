import React, { useEffect, useState } from "react";
import MaterialTable from "@material-table/core";
import { Icon, Typography, Button, Box } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { green } from "@material-ui/core/colors";
import Content from "../../../../components/Content";
import pt_BR from "../../../../locale/material-table-locale";
import {
  confirmationAlert,
  defaultAlert,
} from "../../../../helpers/validation/alerts";
import {
  deleteProcedure,
  getAllProcedure,
} from "../../../../services/endpoints/procedures/endpoints";
import { getFormatedDateTimeUI } from "../../../../helpers/dateHelpers";

export default function ProceduresList() {
  const classes = useStyles();
  const history = useHistory();

  const [procedures, setProcedures] = useState([]);
  const [isLoadding, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProcedures = async () => {
      await getAllProcedure()
        .then(({ data }) => {
          setProcedures(data.list);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };

    fetchProcedures();
    return () => {};
  }, []);

  async function handledeleteProcedure(idProcedure) {
    setIsLoading(true);
    confirmationAlert({
      messageBody: "Deseja realmente excluir este procedimento",
    }).then(async (result) => {
      if (result.value) {
        await deleteProcedure(idProcedure)
          .then(() => {
            defaultAlert(
              "Procedimento excluído!",
              true,
              "OK",
              "success",
              "Sucesso!"
            );
            setProcedures((procedureData) =>
              procedureData.filter(
                (procedure) => procedure.id_procedimento !== idProcedure
              )
            );
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
          });
      }
      setIsLoading(false);
    });
  }

  function handleEditProcedure(idProcedure) {
    history.push(`/procedures/edit/${idProcedure}`);
  }

  const COLUMNS = [
    { title: "Procedimento", field: "nome" },
    { title: "Código", field: "codigo" },
    { title: "Descrição", field: "descricao" },
    {
      title: "Criado em",
      render: (data) => getFormatedDateTimeUI(data.created_at),
    },
  ];

  const TABLE_ACTIONS_ADMIN = [
    {
      icon: "edit",
      tooltip: "Editar tipo de procedimento",
      onClick: (event, rowData) => {
        handleEditProcedure(rowData.id_procedimento);
      },
    },
    {
      icon: "delete",
      tooltip: "Excluir tipo de procedimento",
      onClick: (event, rowData) => {
        handledeleteProcedure(rowData.id_procedimento);
      },
    },
  ];

  return (
    <>
      <Content>
        <MaterialTable
          style={{ margin: "0px 20px", marginBottom: "20px" }}
          title={
            <Box className={classes.titleBox}>
              <Typography variant="h6" component="div">
                Listagem de procedimentos
              </Typography>
              <GreenButton
                className={classes.newButton}
                onClick={() => history.push("/procedures/new")}
                variant="outlined"
                color="default"
                size="small"
                endIcon={<Icon>add</Icon>}
              >
                Criar
              </GreenButton>
            </Box>
          }
          isLoading={isLoadding}
          localization={pt_BR}
          columns={COLUMNS}
          actions={TABLE_ACTIONS_ADMIN}
          data={procedures}
          options={{
            search: true,
            actionsColumnIndex: -1,
          }}
        />
      </Content>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  newButton: {
    marginLeft: theme.spacing(4),
  },
  titleBox: {
    display: "flex",
    maxHeight: "30px",
  },
}));

const GreenButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
