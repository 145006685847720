import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  InputAdornment,
  IconButton,
  CircularProgress,
  Backdrop,
  Chip,
} from "@material-ui/core";
import ImportCertificateDialog from "../../../components/ImportCertificateDialog";
import ImportSignatoryDialog from "../../../components/ImportSignatoryDialog";
import SelectCertificateTypeDialog from "../../../components/SelectCertificateTypeDialog";
import IframeVidasDialog from "../../../components/IframeVidasDialog";
import Content from "../../../components/Content";
import InputMask from "react-input-mask";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useHistory } from "react-router-dom";
import {
  defaultAlert,
  confirmationAlert,
} from "../../../helpers/validation/alerts";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { UpdateMyUserSchema } from "../../../helpers/validation/Schemas/UpdateMyUserSchema/schema";
import {
  validateUserCPF,
  getMyUser,
  updateMyUser,
  removeCertificate,
  checkSignatory,
  searchURLAuthSignatoryA3Vidaas
} from "../../../services/endpoints/profile/endpoint";
import { useUserData } from "../../../context/UserContext";
import { getCpfFormated } from "../../../helpers/stringHelpers";
import { checkSignatureService } from "../../../services/endpoints/systemParameters/endpoints";
import { getFormatedDateSystem } from "../../../helpers/dateHelpers";

export default function MyProfile() {
  const { userData } = useUserData();

  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [signatureServiceStatus, setSignatureServiceStatus] = useState(false);
  const [userCertificateStatus, setUserCertificateStatus] = useState(false);
  const [imporCertificateDialogOpen, setImporCertificateDialogOpen] =
    useState(false);
  const [imporSignatoryDialogOpen, setImporSignatoryDialogOpen] =
    useState(false);
  const [selectCertificateTypeDialogOpen, setSelectCertificateTypeDialogOpen] =
    useState(false);
  const [iframeVidasDialogOpen, setIframeVidasDialogOpen] =
    useState(false);
  const [urlIframeVidas, setUrlIframeVidas] =
    useState('');
  const [userHealthcareProfessional, setUserHealthcareProfessional] =
    useState(false);
  const [name, setName] = useState("");
  const [cpf, setCPF] = useState("");
  const [celular, setCelular] = useState("");

  const {
    register,
    errors,
    handleSubmit,
    control,
    setError,
    reset,
    setValue,
    watch,
    getValues,
    clearError,
    formState,
  } = useForm({
    validationSchema: UpdateMyUserSchema,
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      nome: "",
      cd_usuario: "",
      email: "",
      data_nascimento: "",
      cpf: "",
      celular: "",
    },
  });

  useEffect(() => {
    setIsLoading(true);
    const handleFetchUser = async () => {
      await getMyUser()
        .then(({ data }) => {
          const { nome, cd_usuario, email, data_nascimento, cpf, celular } =
            data.usuario;
          setValue([
            { nome },
            { cpf },
            { cd_usuario },
            { email },
            { data_nascimento },
          ]);
          setName(nome);
          setCelular(celular);
          setCPF(cpf);
          data.usuario.is_profissional_de_saude
            ? setUserHealthcareProfessional(true)
            : setUserHealthcareProfessional(false);
          data.usuario.has_certificado === "A"
            ? setUserCertificateStatus(true)
            : setUserCertificateStatus(false);
        })
        .catch((error) => {
          history.push("/dashboard");
        });
      setIsLoading(false);
    };
    handleFetchUser();
    return () => {};
  }, [history, setValue]);

  const { dirty } = formState;

  useEffect(() => {
    const fetchCheckSignatureService = () => {
      checkSignatureService().then(({ data }) => {
        data.status_serviço_assinatura === "A"
          ? setSignatureServiceStatus(true)
          : setSignatureServiceStatus(false);
      });
    };
    fetchCheckSignatureService();
  }, []);

  async function cpfIsValid(data) {
    const jsonCPF = {
      cpf: data.cpf,
    };

    return await validateUserCPF(jsonCPF)
      .then(({ data }) => {
        if (data.status) {
          return data.status;
        } else {
          defaultAlert(data.msg);
          setError("cpf", "notMatch", data.msg);
          return data.status;
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  async function handleForm(data) {
    setIsLoading(true);
    const formData = data;

    formData.cpf = formData.cpf.replace(/\D+/g, "");
    formData.data_nascimento = getFormatedDateSystem(formData.data_nascimento);

    if (formData.nr_conselho) {
      formData.nr_conselho = formData.nr_conselho.toString();
    }

    const isValid = await cpfIsValid(formData);

    if (isValid) {
      handleUpdateUser(formData);
    } else {
      setIsLoading(false);
    }
  }

  function handleUpdateUser(data) {
    updateMyUser(data)
      .then(({ data }) => {
        if (data.status) {
          defaultAlert(
            "Usuário atualizado com Sucesso!",
            false,
            "Voltar para dashboard",
            "success",
            "Tudo certo!"
          ).then((result) => {
            if (result.value) {
              history.push("/dashboard");
            }
          });
          setIsLoading(false);
          reset();
          setCelular("");
          setCPF("");
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let { errors } = error.response.data;

          Object.keys(errors).map((erro) => {
            return setError(erro, "notMatch", errors[erro]);
          });
          setIsLoading(false);
          return false;
        }
        setIsLoading(false);
      });
  }

  function handleChangeCPF(event) {
    setValue("cpf", event.target.value);
    setCPF(event.target.value);
  }

  function handleChangeCelular(event) {
    setValue("celular", event.target.value);
    setCelular(event.target.value);
  }

  function handleSetCertificateType(type, urlVidaas = '') {
    if(type === "A1"){
      setImporCertificateDialogOpen(true);
    }else{
      setUrlIframeVidas(urlVidaas);
      setIframeVidasDialogOpen(true);
    }
  }

  async function handleCheckSignatory() {
    confirmationAlert({
      htmlMessage: `Verificar duplicidade de signatário? <br><b>Signatário:</b> ${name} <br> <b>CPF:</b> ${getCpfFormated(cpf)}`,
      isDismissible: false,
      footerMessage: `<div style='text-align:center'><i>É necessário verificar se este CPF já com algum ceriticado vinculado no serviço de assinatura digital</i></div>`,
    }).then(async (result) => {
      if (result.value) {
        setIsLoading(true);
        await checkSignatory()
          .then(async ({ data }) => {
            if (data.status) {
              const applications = data.applications.map((item) => {
                return `<br>- ${item.name} - ${item.client}`;
              });   
              confirmationAlert({
                htmlMessage: `${data.msg}<br><br>
                  <b>Aplicações: </b><br>
                 ${applications}

                  <br><br>Deseja importar o certificado deste signatário ?`,
                isDismissible: false,
              }).then(async (result) => {
                if (result.value) {
                  if(data.certificate_type === 'A1'){
                    handleSetCertificateType(data.certificate_type)
                  }else{
                    await searchURLAuthSignatoryA3Vidaas()
                      .then((response) => {
                        if (response.data.status) {
                          setIsLoading(false);
                          handleSetCertificateType(data.certificate_type, response.data.url);
                        }
                      })
                      .catch((error) => {
                        defaultAlert(
                          "Não possível obter a URL de autenticação, tente novamente mais tarde",
                          false,
                          "Entendi",
                          "error",
                          "Ops! Algo deu errado"
                        );
                        setIsLoading(false);
                      });
                  }
                }
              });
              setIsLoading(false);
            } else {
              setSelectCertificateTypeDialogOpen(true);
            }
          })
          .catch((error) => {
            setIsLoading(false);
          });
      }
      setIsLoading(false);
    });
  }

  function handleRemoveCertificate() {
    confirmationAlert({
      messageBody: "Deseja realmente remover seu certificado digital ?",
      isDismissible: false,
      footerMessage: `<div style='text-align:center'><i>Depois de removido, você não poderá mais assinar documentos e registros de pacientes.</i></div>`,
    }).then(async (result) => {
      if (result.value) {
        setIsLoading(true);
        await removeCertificate()
          .then(({ data }) => {
            if (data.status) {
              defaultAlert(
                "Certificado digital removido com sucesso, para assinar documentos e registros você deve importar um novo certificado!",
                true,
                "OK",
                "success",
                "Sucesso!"
              );
              setUserCertificateStatus(false);
              setIsLoading(false);
            } else {
              defaultAlert(data.msg);
            }
          })
          .catch((error) => {
            setIsLoading(false);
          });
      }
      setIsLoading(false);
    });
  }

  return (
    <>
      <Content formIsDirty={dirty}>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container className={classes.gridRoot}>
          <Box className={classes.paperStyle} component={Paper} elevation={2}>
            <Typography component="h1" variant="h6">
              {name}
            </Typography>
            {signatureServiceStatus && userHealthcareProfessional ? (
              <Chip
                size="small"
                label={
                  userCertificateStatus
                    ? "Certificado digital cadastrado"
                    : "Nenhum certificado digital cadastrado"
                }
                color={userCertificateStatus ? "default" : "primary"}
                style={
                  userCertificateStatus
                    ? { borderColor: "green", color: "green" }
                    : {}
                }
                variant="outlined"
              />
            ) : (
              ""
            )}
            <form
              method="POST"
              onSubmit={handleSubmit((data) => handleForm(data))}
            >
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.nome}
                  className={classes.flexItems}
                >
                  <InputLabel>Nome Completo</InputLabel>
                  <Controller
                    as={<Input />}
                    rules={{ required: true }}
                    control={control}
                    name="nome"
                  />
                  <FormHelperText>{errors?.nome?.message}</FormHelperText>
                </FormControl>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.cd_usuario}
                  className={classes.flexItems}
                >
                  <InputLabel>Login</InputLabel>
                  <Controller
                    onChange={([event]) =>
                      event.target.value.trim().toLowerCase()
                    }
                    as={<Input />}
                    rules={{ required: true }}
                    readOnly={true}
                    control={control}
                    name="cd_usuario"
                  />
                  <FormHelperText>{errors?.cd_usuario?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  error={!!errors.senha}
                  className={classes.flexItems}
                >
                  <InputLabel>Senha</InputLabel>
                  <Input
                    inputRef={register({ required: true })}
                    name="senha"
                    autoComplete="new-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText>{errors?.senha?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  error={!!errors.confirmacao_senha}
                  className={classes.flexItems}
                >
                  <InputLabel>Confirmação de Senha</InputLabel>
                  <Input
                    inputRef={register({ required: true })}
                    name="confirmacao_senha"
                    autoComplete="new-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText>
                    {errors?.confirmacao_senha?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.data_nascimento}
                  className={classes.flexItems}
                >
                  <InputLabel shrink={true}>Data de Nascimento</InputLabel>
                  <Input
                    name="data_nascimento"
                    type="date"
                    inputProps={{
                      min: "01/01/2020",
                      max: new Date().toISOString().split("T")[0],
                    }}
                    inputRef={register({ required: true })}
                  />
                  <FormHelperText>
                    {errors?.data_nascimento?.message}
                  </FormHelperText>
                </FormControl>
                <FormControl error={!!errors.cpf} className={classes.flexItems}>
                  <InputLabel>CPF</InputLabel>
                  <InputMask
                    mask="999.999.999-99"
                    disableUnderline
                    maskChar={" "}
                    value={cpf}
                    readOnly={true}
                    onChange={handleChangeCPF}
                    onFocus={() => clearError("cpf")}
                  >
                    {() => <Input name="cpf" inputRef={register()} />}
                  </InputMask>
                  <FormHelperText>{errors?.cpf?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  error={!!errors.celular}
                  className={classes.flexItems}
                >
                  <InputLabel>Celular</InputLabel>
                  <InputMask
                    mask="(99)99999-9999"
                    disableUnderline
                    maskChar={null}
                    value={celular}
                    onChange={handleChangeCelular}
                    onFocus={() => clearError("celular")}
                  >
                    {() => (
                      <Input
                        name="celular"
                        inputRef={register({ required: true })}
                      />
                    )}
                  </InputMask>
                  <FormHelperText>{errors?.celular?.message}</FormHelperText>
                </FormControl>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.email}
                  className={classes.flexItems}
                >
                  <InputLabel>Email</InputLabel>
                  <Controller
                    as={<Input />}
                    rules={{ required: true }}
                    control={control}
                    name="email"
                  />
                  <FormHelperText>{errors?.email?.message}</FormHelperText>
                </FormControl>
              </Box>
              <Box className={classes.flexContainerButton}>
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  color="primary"
                >
                  Atualizar Usuário
                </Button>
                {signatureServiceStatus && userHealthcareProfessional ? (
                  <>
                    {userCertificateStatus ? (
                      <Button
                        size="large"
                        variant="contained"
                        type="button"
                        color="default"
                        style={{ borderColor: "green", color: "red" }}
                        onClick={() => {
                          handleRemoveCertificate();
                        }}
                      >
                        Remover certificado digital
                      </Button>
                    ) : (
                      <Button
                        size="large"
                        variant="contained"
                        type="button"
                        color="default"
                        style={{ borderColor: "green", color: "green" }}
                        onClick={() => {
                          handleCheckSignatory();
                        }}
                      >
                        Importar certificado digital
                      </Button>
                    )}
                  </>
                ) : (
                  ""
                )}
              </Box>
            </form>
          </Box>
        </Grid>
        <ImportCertificateDialog
          open={imporCertificateDialogOpen}
          close={() => setImporCertificateDialogOpen(false)}
          setUserCertificateStatus={setUserCertificateStatus}
        />
        <ImportSignatoryDialog
          open={imporSignatoryDialogOpen}
          close={() => setImporSignatoryDialogOpen(false)}
          cpfSignatory={cpf}
          setUserCertificateStatus={setUserCertificateStatus}
        />
         <SelectCertificateTypeDialog
          open={selectCertificateTypeDialogOpen}
          close={() => setSelectCertificateTypeDialogOpen(false)}
          handleSetCertificateType={handleSetCertificateType}
        />
        <IframeVidasDialog
          open={iframeVidasDialogOpen}
          close={() => setIframeVidasDialogOpen(false)}
          url={urlIframeVidas}
          setUserCertificateStatus={setUserCertificateStatus}
        />
      </Content>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  gridRoot: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paperStyle: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  flexContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  flexContainerButton: {
    marginTop: theme.spacing(5),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "flex-start",
    width: "100%",
    display: "flex",
    gap: "10px",
    margin: theme.spacing(2, 0),
  },
  flexItems: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2),
    },
    width: "100%",
  },
}));
