import api from "../../api";

export const getAllDocumentTypes = async () => {
  return api.get("tipodocumento");
};

export const getDocumentTypeById = async (documentTypeId) => {
  return api.get(`tipodocumento/id/${documentTypeId}`);
};

export const createDocumentType = async (params) => {
  return api.post("tipodocumento/create", params);
};

export const updateDocumenType = async (documentTypeId, params) => {
  return api.put(`tipodocumento/update/${documentTypeId}`, params);
};

export const deleteDocumentType = async (documentTypeId) => {
  return api.delete(`tipodocumento/delete/${documentTypeId}`);
};

export const getDocumentTypeTags = async () => {
  return api.get("tipodocumento/list_tags");
};

export const getFooterPreview = async (params) => {
  return api.post(`tipodocumento/preview_rodape`, params, {
    responseType: "arraybuffer",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
