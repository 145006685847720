import api from "../../api";

export const exportPDF = async (date, professionalId) => {
  return api.get(`relatorio/agendamento/pdf/${date}/${professionalId}`, {
    responseType: "arraybuffer",
    headers: {
      "Content-Type": "text/html",
    },
  });
};

export const exportXlsx = async (date, professionalId) => {
  return api.get(`relatorio/agendamento/xls/${date}/${professionalId}`, {
    responseType: "arraybuffer",
    headers: {
      "Content-Type": "application/vnd.ms-excel",
    },
  });
};