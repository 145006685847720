import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  CircularProgress,
  Backdrop,
  FormControlLabel,
  Switch,
  Divider,
} from "@material-ui/core";
import { ToggleButton } from "@material-ui/lab";
import Content from "../../../../components/Content";
import {
  defaultAlert,
  confirmationAlert,
} from "../../../../helpers/validation/alerts";
import { useForm, Controller } from "react-hook-form";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { WhatsappSchema } from "../../../../helpers/validation/Schemas/Configuration/Communication/WhatsappSchema";
import {
  getConfigurationWhats,
  updateConfigurationWhats,
  getServiceCost,
  checkService,
} from "../../../../services/endpoints/configuration/communication/endpoints";

export default function WhatsParameters() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [enableServiceWhats, setEnableServiceWhats] = useState(false);
  const [enableScheduleWhats, setEnableScheduleWhats] = useState(false);
  const [enableSchedule48Whats, setEnableSchedule48Whats] = useState(false);
  const [enableSchedule24Whats, setEnableSchedule24Whats] = useState(false);
  const [enableSchedule10mWhats, setEnableSchedule10mWhats] = useState(false);
  const [enableResendLink, setEnableResendLink] = useState(true);
  const [serviceCost, setServiceCost] = useState("");
  const [serviceQntMesseges, setServiceQntMesseges] = useState("");
  const [serviceCredits, setServiceCredits] = useState("");

  const inputRef = useRef(null);

  const {
    register,
    errors,
    clearErrors,
    setError,
    handleSubmit,
    reset,
    control,
    setValue,
  } = useForm({
    validationSchema: WhatsappSchema,
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      sid: "",
      token: "",
      telefone: "",
      whats_agendar: false,
      whats_48_lembrete: false,
      whats_24_lembrete: false,
      whats_10_lembrete: false,
      whats_reenvio_link: false,
      msg_agendamento: " ",
      msg_cancelamento: " ",
      msg_reagendamento: " ",
      msg_lembrete_agendamento: " ",
      msg_lembrete_agendamento_10: " ",
      msg_link_documentos: " ",
      msg_reenvio_link: " ",
      status: false,
    },
  });

  useEffect(() => {
    setIsLoading(true);
    const handleFetchConfigsValues = async () => {
      await getConfigurationWhats()
        .then(({ data }) => {
          const {
            sid,
            token,
            telefone,
            msg_agendamento,
            msg_cancelamento,
            msg_reagendamento,
            msg_lembrete_agendamento,
            msg_lembrete_agendament_10,
            msg_link_documentos,
            msg_reenvio_link,
            status,
          } = data.configuracoes;
          setEnableServiceWhats(
            data.configuracoes.status === "1" ? true : false
          );
          setEnableScheduleWhats(
            data.configuracoes.whats_agendar === "1" ? true : false
          );
          setEnableSchedule48Whats(
            data.configuracoes.whats_48_lembrete === "1" ? true : false
          );
          setEnableSchedule24Whats(
            data.configuracoes.whats_24_lembrete === "1" ? true : false
          );
          setEnableSchedule10mWhats(
            data.configuracoes.whats_10_lembrete === "1" ? true : false
          );
          setEnableResendLink(data.configuracoes.whats_reenvio_link === "1" ? true : false);
          reset(data.configuracoes);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };
    handleFetchConfigsValues();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const handleFetchCosts = async () => {
      await getServiceCost()
        .then(({ data }) => {
          setServiceCost(data.info.custo);
          setServiceQntMesseges(data.info.qtn_mensanges);
          setServiceCost(data.info.custo_total);
          setServiceCredits(data.info.balanco);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };
    handleFetchCosts();
    // eslint-disable-next-line
  }, []);

  function handleChangeStatus(status) {
    if (status[1]) {
      setIsLoading(true);
      checkService("sms")
        .then(({ data }) => {
          setIsLoading(false);
          if (data.status_service) {
            confirmationAlert({
              messageBody:
                "O serviço de envio de notificações via SMS já esta ativo, deseja ativar também o serviço de notificações via WhatsApp ?",
              footerMessage:
                "<div style='text-align:center'><i>Se os dois serviços estiverem habilitados simultaneamente, os pacientes receberam mensagens de notificações via SMS e WhatsApp, gerando custos duplicados.</i></div>",
            }).then(async (result) => {
              if (result.value) {
                setEnableServiceWhats(true);
                return true;
              } else {
                setEnableServiceWhats(false);
                return false;
              }
            });
          } else {
            setEnableServiceWhats(true);
            return true;
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setEnableServiceWhats(false);
      return false;
    }
  }

  async function handleForm(data) {
    data.status = enableServiceWhats;
    data.whats_agendar = enableScheduleWhats;
    data.whats_48_lembrete = enableSchedule48Whats;
    data.whats_24_lembrete = enableSchedule24Whats;
    data.whats_10_lembrete = enableSchedule10mWhats;
    data.whats_reenvio_link = enableResendLink;
    setIsLoading(true);
    updateConfigurationWhats(data)
      .then(() => {
        setIsLoading(false);
        defaultAlert(
          "Parâmetros de comunicação via WhatsApp atualizados com sucesso!",
          true,
          "Entendi",
          "success"
        );
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let { errors } = error.response.data;
          Object.keys(errors).map((erro) => {
            return setError(erro, "notMatch", errors[erro]);
          });
        }
        setIsLoading(false);
      });
  }

  return (
    <Content>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container className={classes.gridRoot}>
        <Grid item xs={12} sm={9}>
          <Box className={classes.paperStyle} component={Paper} elevation={2}>
            <Typography component="h1" variant="h6">
              Editar parâmetros comunicação de WhatsApp do sistema
            </Typography>
            <form
              method="POST"
              onSubmit={handleSubmit((data) => handleForm(data))}
            >
              <Box className={classes.flexContainer}>
                <FormControlLabel
                  control={
                    <Controller
                      as={<Switch color="primary" />}
                      control={control}
                      name="status"
                      type="checkbox"
                      onChange={handleChangeStatus}
                      checked={enableServiceWhats}
                      value={enableServiceWhats}
                    />
                  }
                  defaultValue={false}
                  style={{ marginLeft: "0px" }}
                  labelPlacement="start"
                  label="Status do serviço de notificações via WhatsApp"
                />
              </Box>
              <Box className={classes.flexTopicSeparator}>
                <Typography component="p" variant="button">
                  Configurações de autenticação do serviço de WhatsApp do Twilio
                </Typography>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl error={!!errors.sid} className={classes.flexItems}>
                  <InputLabel>SID Twilio</InputLabel>
                  <Controller
                    as={<Input />}
                    rules={{ required: true }}
                    control={control}
                    name="sid"
                    disabled={!enableServiceWhats}
                  />
                  <FormHelperText>{errors?.sid?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  error={!!errors.token}
                  className={classes.flexItems}
                >
                  <InputLabel>Token Twilio</InputLabel>
                  <Controller
                    as={<Input />}
                    rules={{ required: true }}
                    control={control}
                    name="token"
                    disabled={!enableServiceWhats}
                  />
                  <FormHelperText>{errors?.token?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  error={!!errors.telefone}
                  className={classes.flexItems}
                >
                  <InputLabel>Telefone Twilio</InputLabel>
                  <Controller
                    as={<Input />}
                    rules={{ required: true }}
                    control={control}
                    name="telefone"
                    disabled={!enableServiceWhats}
                  />
                  <FormHelperText>{errors?.telefone?.message}</FormHelperText>
                </FormControl>
              </Box>
              <Divider style={{ marginTop: "25px" }} />
              <Box className={classes.flexTopicSeparator}>
                <Typography component="p" variant="button">
                  Configurações de envio de Whats
                </Typography>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControlLabel
                  control={
                    <Controller
                      as={<Switch color="primary" />}
                      control={control}
                      name="whats_agendar"
                      type="checkbox"
                      disabled={!enableServiceWhats}
                      onChange={(e) => {
                        // eslint-disable-next-line no-lone-blocks
                        {
                          setEnableScheduleWhats(e[1]);
                        }
                        return e[1];
                      }}
                      checked={enableScheduleWhats}
                      value={enableScheduleWhats}
                    />
                  }
                  defaultValue={false}
                  style={{ marginLeft: "0px" }}
                  labelPlacement="start"
                  label="Enviar Whats ao agendar"
                />
                <FormControlLabel
                  control={
                    <Controller
                      as={<Switch color="primary" />}
                      control={control}
                      name="whats_48_lembrete"
                      type="checkbox"
                      disabled={!enableServiceWhats}
                      onChange={(e) => {
                        // eslint-disable-next-line no-lone-blocks
                        {
                          setEnableSchedule48Whats(e[1]);
                        }
                        return e[1];
                      }}
                      checked={enableSchedule48Whats}
                      value={enableSchedule48Whats}
                    />
                  }
                  defaultValue={false}
                  style={{ marginLeft: "0px" }}
                  labelPlacement="start"
                  label="Enviar Whats de lembrete 48h antes"
                />
                <FormControlLabel
                  control={
                    <Controller
                      as={<Switch color="primary" />}
                      control={control}
                      name="whats_24_lembrete"
                      type="checkbox"
                      disabled={!enableServiceWhats}
                      onChange={(e) => {
                        // eslint-disable-next-line no-lone-blocks
                        {
                          setEnableSchedule24Whats(e[1]);
                        }
                        return e[1];
                      }}
                      checked={enableSchedule24Whats}
                      value={enableSchedule24Whats}
                    />
                  }
                  defaultValue={false}
                  style={{ marginLeft: "0px" }}
                  labelPlacement="start"
                  label="Enviar Whats de lembrete 24h antes"
                />
                <FormControlLabel
                  control={
                    <Controller
                      as={<Switch color="primary" />}
                      control={control}
                      name="whats_10_lembrete"
                      type="checkbox"
                      disabled={!enableServiceWhats}
                      onChange={(e) => {
                        // eslint-disable-next-line no-lone-blocks
                        {
                          setEnableSchedule10mWhats(e[1]);
                        }
                        return e[1];
                      }}
                      checked={enableSchedule10mWhats}
                      value={enableSchedule10mWhats}
                    />
                  }
                  defaultValue={false}
                  style={{ marginLeft: "0px" }}
                  labelPlacement="start"
                  label="Enviar Whats de lembrete 10m antes"
                />
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.msg_agendamento}
                  className={classes.flexItems}
                >
                  <InputLabel>Mensagem Agendamento</InputLabel>
                  <Input
                    multiline
                    readOnly
                    name="msg_agendamento"
                    disabled={!enableServiceWhats}
                    inputRef={register({ required: true })}
                  />
                  <FormHelperText>
                    {errors?.msg_agendamento?.message}
                  </FormHelperText>
                  <Typography component="p" variant="caption">
                    <strong>Tags de substituição disponiveis: </strong>
                    [prestador] - Nome do Médico vinculado ao agendamento;
                    [paciente] - Nome do paciente que receberá o Whats; [data] -
                    Data do agendamento; [hora] - Hora do agendamento;
                  </Typography>
                </FormControl>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.msg_cancelamento}
                  className={classes.flexItems}
                >
                  <InputLabel>Mensagem Cancelamento</InputLabel>
                  <Input
                    multiline
                    readOnly
                    name="msg_cancelamento"
                    disabled={!enableServiceWhats}
                    inputRef={register({ required: true })}
                  />
                  <FormHelperText>
                    {errors?.msg_cancelamento?.message}
                  </FormHelperText>
                  <Typography component="p" variant="caption">
                    <strong>Tags de substituição disponiveis: </strong>
                    [prestador] - Nome do Médico vinculado ao agendamento;
                    [paciente] - Nome do paciente que receberá o Whats; [data] -
                    Data do agendamento; [hora] - Hora do agendamento;
                  </Typography>
                </FormControl>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.msg_reagendamento}
                  className={classes.flexItems}
                >
                  <InputLabel>Mensagem Reagendamento</InputLabel>
                  <Input
                    multiline
                    readOnly
                    name="msg_reagendamento"
                    disabled={!enableServiceWhats}
                    inputRef={register({ required: true })}
                  />
                  <FormHelperText>
                    {errors?.msg_reagendamento?.message}
                  </FormHelperText>
                  <Typography component="p" variant="caption">
                    <strong>Tags de substituição disponiveis: </strong>
                    [prestador] - Nome do Médico vinculado ao agendamento;
                    [paciente] - Nome do paciente que receberá o SMS; [data] -
                    Data do agendamento; [hora] - Hora do agendamento;
                  </Typography>
                </FormControl>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.msg_lembrete_agendamento}
                  className={classes.flexItems}
                >
                  <InputLabel>Mensagem de Lembrete de 24h e 48h</InputLabel>
                  <Input
                    multiline
                    readOnly
                    name="msg_lembrete_agendamento"
                    disabled={!enableServiceWhats}
                    inputRef={register({ required: true })}
                  />
                  <FormHelperText>
                    {errors?.msg_lembrete_agendamento?.message}
                  </FormHelperText>
                  <Typography component="p" variant="caption">
                    <strong>Tags de substituição disponiveis: </strong>
                    [prestador] - Nome do Médico vinculado ao agendamento;
                    [paciente] - Nome do paciente que receberá o Whats; [data] -
                    Data do agendamento; [hora] - Hora do agendamento;
                  </Typography>
                </FormControl>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.msg_lembrete_agendamento_10}
                  className={classes.flexItems}
                >
                  <InputLabel>
                    Mensagem de lembrete do dia do agenamento (10 minutos antes)
                  </InputLabel>
                  <Input
                    multiline
                    readOnly
                    name="msg_lembrete_agendamento"
                    disabled={!enableServiceWhats}
                    inputRef={register({ required: true })}
                  />
                  <FormHelperText>
                    {errors?.msg_lembrete_agendamento_10?.message}
                  </FormHelperText>
                  <Typography component="p" variant="caption">
                    <strong>Tags de substituição disponiveis: </strong>
                    [paciente] - Nome do paciente que receberá o SMS; [data] -
                    Data do agenamento; [hora] Hora do agendamento;
                  </Typography>
                </FormControl>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.msg_link_documentos}
                  className={classes.flexItems}
                >
                  <InputLabel>
                    Mensagem de link público de documento do paciente
                  </InputLabel>
                  <Input
                    multiline
                    readOnly
                    name="msg_link_documentos"
                    disabled={!enableServiceWhats}
                    inputRef={register({ required: true })}
                  />
                  <FormHelperText>
                    {errors?.msg_link_documentos?.message}
                  </FormHelperText>
                  <Typography component="p" variant="caption">
                    <strong>Tags de substituição disponiveis: </strong>
                    [paciente] - Nome do paciente que receberá o SMS; [link] -
                    Link público de documento do paciente; [senha] - Senha de
                    acesso aos documentos;
                  </Typography>
                </FormControl>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.msg_reenvio_link}
                  className={classes.flexItems}
                >
                  <InputLabel>
                    Mensagem de reenvio de link de consulta
                  </InputLabel>
                  <Input
                    multiline
                    readOnly
                    name="msg_reenvio_link"
                    disabled={!enableServiceWhats}
                    inputRef={register({ required: true })}
                  />
                  <FormHelperText>
                    {errors?.msg_reenvio_link?.message}
                  </FormHelperText>
                  <Typography component="p" variant="caption">
                    <strong>Tags de substituição disponiveis: </strong>
                    [paciente] - Nome do paciente que receberá o SMS; 
                    [link] - Link da teleconsulta;
                  </Typography>
                </FormControl>
              </Box>
              <Box className={classes.flexContainerButton}>
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  color="primary"
                >
                  Atualizar parâmetros
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          className={classes.boxTwilioStatistic}
          style={enableServiceWhats ? {} : { display: "none" }}
        >
          <Box className={classes.paperStyle} component={Paper} elevation={2}>
            <Typography component="h1" variant="subtitle1" align="center">
              Estatisticas de uso do serviço do mês atual
            </Typography>
            <Box style={{ marginTop: "25px" }}>
              <Box style={isLoading ? { display: "none" } : {}}>
                <div>
                  <Typography variant="subtitle2" component="span">
                    {"Balanço:\n"}
                  </Typography>
                  <Typography variant="caption" component="span">
                    ${serviceCredits}
                  </Typography>
                </div>
                <div>
                  <Typography variant="subtitle2" component="span">
                    {"Mensagens:\n"}
                  </Typography>
                  <Typography variant="caption" component="span">
                    {serviceQntMesseges} whatsapps enviados
                  </Typography>
                </div>
                <div>
                  <Typography variant="subtitle2" component="span">
                    {"Custos:\n"}
                  </Typography>
                  <Typography variant="caption" component="span">
                    ${serviceCost}
                  </Typography>
                </div>
              </Box>
              <Box
                className={classes.flexContainerButton}
                style={isLoading ? { display: "none" } : {}}
              >
                <GreenButton
                  value="5"
                  size="medium"
                  onClick={() => {
                    window.open("https://www.twilio.com/login", "_blank");
                  }}
                >
                  Acessar plataforma do Twilio
                </GreenButton>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Content>
  );
}

const useStyles = makeStyles((theme) => ({
  gridRoot: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paperStyle: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  flexTopicSeparator: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-2),
  },
  clientImage: {
    borderRadius: "4px",
    height: "200px",
    width: "200px",
    objectFit: "contain",
    cursor: "pointer",
  },
  flexImageContainer: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  flexContainer: {
    marginTop: theme.spacing(1),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  flexContainerButton: {
    marginTop: theme.spacing(5),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "center",
  },
  flexItems: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
    },
    width: "100%",
  },
  flexItemImage: {
    alignItems: "center",
  },
  flexItem: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
      maxWidth: "33%",
    },
    width: "100%",
  },
  boxTwilioStatistic: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
const GreenButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: theme.status.success(),
    "&:hover": {
      backgroundColor: theme.status.success(700),
    },
  },
  selected: {
    border: "unset",
  },
}))(ToggleButton);
