import api from "../../api";

export const validateUserCPF = async (params) => {
  return api.post("usuario/validatecpf", params);
};

export const createUser = async (params) => {
  return api.post("usuario/create", params);
};

export const updateUser = async (id, params) => {
  return api.put(`usuario/update/${id}`, params);
};

export const updateStatusAccessUser = async (id, params) => {
  return api.put(`usuario/update_status_acesso/${id}`, params);
};

export const deleteUser = async (id) => {
  return api.delete(`usuario/delete/${id}`);
};

export const getUsers = async () => {
  return api.get("usuario");
};

export const getUserById = async (id) => {
  return api.get(`usuario/id/${id}`);
};

export const checkCertificateSign = async () => {
  return api.get(`usuario/check_has_ceritificao`);
};
