import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
  Input,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import Content from "../../../components/Content";
import { useHistory, useParams } from "react-router-dom";
import { defaultAlert } from "../../../helpers/validation/alerts";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { CreateHealthUnitSchema } from "../../../helpers/validation/Schemas/HealthUnitSchema/schema";
import {
  getHealthUnitById,
  updateHealthUnit,
  createHealthUnit,
} from "../../../services/endpoints/healthUnit/endpoints";

import { getHealthUnitTypes } from "../../../services/endpoints/enums/endpoints";

export default function CreateHealthUnit() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [tpHealthUnitTypeOptions, setTpHealthUnitTypeOptions] = useState([]);

  useEffect(() => {
    const populateTpProfileOptions = async () => {
      await getHealthUnitTypes().then(({ data }) => {
        setTpHealthUnitTypeOptions(data.list);
      });
    };
    populateTpProfileOptions();
  }, []);

  const {
    errors,
    handleSubmit,
    control,
    setError,
    reset,
    setValue,
    formState,
  } = useForm({
    validationSchema: CreateHealthUnitSchema,
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      nome: "",
      cnes: "",
      tipo: "",
      tp_situacao: "A",
    },
  });

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      setIsEditing(true);
      const handleFetcHealthUnit = async () => {
        await getHealthUnitById(id)
          .then(({ data }) => {
            const { nome, cnes, tipo, tp_situacao } = data.unidade;
            setValue([{ nome }, { cnes }, { tipo }, { tp_situacao }]);
          })
          .catch((error) => {
            history.push("/healthUnit/list");
            setIsEditing(false);
          });
        setIsLoading(false);
      };

      handleFetcHealthUnit();
    }

    return () => {};
  }, [id, history, setValue]);

  async function handleForm(data) {
    setIsLoading(true);
    const formData = data;

    if (isEditing) {
      handleUpdateHealthUnit(id, formData);
    } else {
      handleCreateHealthUnit(formData);
    }
  }

  function handleCreateHealthUnit(data) {
    createHealthUnit(data)
      .then(({ data }) => {
        if (data.status) {
          defaultAlert(
            "Unidade de Saúde criada com sucesso",
            false,
            "Ver lista de Unidades",
            "success",
            "Tudo certo!"
          ).then((result) => {
            if (result.value) {
              history.push("/healthUnit/list");
            }
          });
          setIsLoading(false);
          reset();
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let { errors } = error.response.data;

          Object.keys(errors).map((erro) => {
            return setError(erro, "notMatch", errors[erro]);
          });

          setIsLoading(false);
          return false;
        }
      });
  }

  function handleUpdateHealthUnit(id, data) {
    updateHealthUnit(id, data)
      .then(({ data }) => {
        if (data.status) {
          defaultAlert(
            "Unidade de Saúde atualizada com Sucesso!",
            false,
            "Ver lista de Unidades",
            "success",
            "Tudo certo!"
          ).then((result) => {
            if (result.value) {
              history.push("/healthUnit/list");
            }
          });
          setIsLoading(false);
          reset();
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let { errors } = error.response.data;

          Object.keys(errors).map((erro) => {
            return setError(erro, "notMatch", errors[erro]);
          });

          setIsLoading(false);
          return false;
        }
        setIsLoading(false);
      });
  }
  const { dirty } = formState;

  return (
    <>
      <Content formIsDirty={dirty}>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container className={classes.gridRoot}>
          <Box className={classes.paperStyle} component={Paper} elevation={2}>
            <Typography component="h1" variant="h6">
              {isEditing ? "Editar Unidade de Saúde" : "Nova Unidade de Saúde"}
            </Typography>
            <form
              method="POST"
              onSubmit={handleSubmit((data) => handleForm(data))}
            >
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.nome}
                  className={classes.flexItems}
                >
                  <InputLabel>Identificação Unidade</InputLabel>
                  <Controller
                    as={<Input />}
                    rules={{ required: true }}
                    control={control}
                    name="nome"
                  />
                  <FormHelperText>{errors?.nome?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  error={!!errors.cnes}
                  className={classes.flexItems}
                >
                  <InputLabel>CNES</InputLabel>
                  <Controller
                    as={<Input />}
                    rules={{ required: true }}
                    control={control}
                    name="cnes"
                  />
                  <FormHelperText>{errors?.cnes?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  error={!!errors.tipo}
                  className={classes.flexItems}
                >
                  <InputLabel>Tipo de Unidade</InputLabel>
                  <Controller
                    as={
                      <Select>
                        <MenuItem value="">Selecione</MenuItem>
                        {tpHealthUnitTypeOptions.map((unitType, index) => (
                          <MenuItem
                            key={unitType.value + index}
                            value={unitType.value}
                          >
                            {unitType.description}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    rules
                    control={control}
                    name="tipo"
                  />
                  <FormHelperText>{errors?.tipo?.message}</FormHelperText>
                </FormControl>
                {isEditing ? (
                  <FormControl
                    error={!!errors.tp_situacao}
                    className={classes.flexItems}
                  >
                    <InputLabel>Situação</InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value={"A"}>Ativo</MenuItem>
                        </Select>
                      }
                      rules
                      control={control}
                      name="tp_situacao"
                    />
                    <FormHelperText>
                      {errors?.uf_conselho?.message}
                    </FormHelperText>
                  </FormControl>
                ) : (
                  ""
                )}
              </Box>
              <Box className={classes.flexContainerButton}>
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  color="primary"
                >
                  {isEditing ? "Atualizar Unidade" : "Cadastrar Unidade"}
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Content>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  gridRoot: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paperStyle: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  flexContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  flexContainerButton: {
    marginTop: theme.spacing(5),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "flex-start",
  },
  flexItems: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2),
    },
    width: "100%",
  },
}));
