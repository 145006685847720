import React from "react";
import Footer from "./components/Footer";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "./theme";
import { ThemeProvider } from "@material-ui/core/styles";
import "./styles/calendar.css";
import UserProvider from "./context/UserContext";
import Routes from "./routes";

function App() {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <UserProvider>
          <Routes />
        </UserProvider>
        <Footer />
      </ThemeProvider>
    </>
  );
}

export default App;
