import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {
  removeToken,
  getToken,
  userLogout,
} from "../../services/endpoints/auth/endpoints";
import { useUserData } from "../../context/UserContext";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { defaultAlert } from "../../helpers/validation/alerts";
import logo from "../../assets/images/logo.png";
import MenuIcon from "@material-ui/icons/Menu";
import ExitIcon from "@material-ui/icons/MeetingRoom";
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import Person from "@material-ui/icons/Person";

export default function NavigationBar({ handleDrawer }) {

  const classes = useStyles();
  const history = useHistory();
  const { userData } = useUserData();
  const [userMenuState, setUserMenuState] = useState(false);
  const [anchorElementMenu, setAnchorElementMenu] = useState(null);
  const [userIsHelthcareProfessional, setUserIsHelthcareProfessional] = useState(false);

  function handleUserMenu(e) {
    setUserMenuState(!userMenuState);
    setAnchorElementMenu(e.currentTarget);
  }

  useEffect(() => {
    if (userData.is_healthcare_professional) {
      setUserIsHelthcareProfessional(true);
    }
  }, [userData]);

  async function handleLogout() {
    const token = { token: await getToken() };
    userLogout(token)
      .then(async (data) => {
        if (data.status) {
          if (window.Tawk_API) {
            window.Tawk_API.hideWidget();
          }
          await removeToken();
          history.push("/");
        }
      })
      .catch((error) => {
        if (!error.response || error.response.status === 500) {
          defaultAlert(
            "Erro na comunicação com a API, por favor tente novamente",
            false,
            "Entrar novamente"
          ).then(async (result) => {
            if (result.value) {
              if (window.Tawk_API) {
                window.Tawk_API.hideWidget();
              }
              await removeToken();
              history.push("/");
            }
          });
        }
      });
  }

  function handleMyProfile() {
    history.push("/profile");
  }

  function handleMySchedule() {
    history.push("/schedule/list");
  }

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            onClick={handleDrawer}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.logoBox}>
            <img
              className={classes.logoCompany}
              src={logo}
              alt="Logotipo - OM30 - Tecnologia movida por pessoas"
            />
          </div>
          <div className={classes.userProfileOptions}>
            <Typography variant="button" component="span">
              {userData?.health_unit_id !== null
                ? userData?.health_unit_name
                : "SISTEMA"}
            </Typography>
            <IconButton
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleUserMenu}
              color="inherit"
            >
              <Avatar alt={userData?.name?.toUpperCase()} src="##"></Avatar>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElementMenu}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              keepMounted
              open={userMenuState}
              onClose={handleUserMenu}
              className={classes.userMenu}
            >
              <MenuItem onClick={handleMyProfile}>
                <ListItemIcon>
                  <Person fontSize="medium" />
                </ListItemIcon>
                <ListItemText primary="Meu usuário" />
              </MenuItem>
              {userIsHelthcareProfessional ? (
                <MenuItem onClick={handleMySchedule}>
                  <ListItemIcon>
                    <PermContactCalendarIcon fontSize="medium" />
                  </ListItemIcon>
                  <ListItemText primary="Minhas agendas" />
                </MenuItem>) : ("")
              }
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <ExitIcon fontSize="medium" />
                </ListItemIcon>
                <ListItemText primary="Sair" />
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  userMenu: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(6),
  },
  userProfileOptions: {
    display: "flex",
    alignItems: "center",
  },
  logoCompany: {
    width: "150px",
    marginTop: "-5px",
  },
  logoBox: {
    flexGrow: 1,
  },
}));
