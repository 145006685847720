import React, { useState } from "react";
import {
  searchA3Vidaas,
  searchURLAuthA3Vidaas,
} from "../../services/endpoints/profile/endpoint";
import logoVidas from "../../assets/images/logo-vidaas.png";
import logoCertificate from "../../assets/images/certificado-digital.png";
import { defaultAlert } from "../../helpers/validation/alerts";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  ButtonBase,
  Card,
  CardContent,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const SelectCertificateTypeDialog = ({
  open,
  close,
  handleSetCertificateType,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  function handleClose() {
    close();
  }

  async function handleSelectCertificateType(type) {
    if (type === "A3") {
      setIsLoading(true);
      await searchA3Vidaas()
        .then(async ({ data }) => {
          if (data.status) {
            await searchURLAuthA3Vidaas()
              .then((response) => {
                if (response.data.status) {
                  setIsLoading(false);
                  handleSetCertificateType(type, response.data.url);
                  close();
                }
              })
              .catch((error) => {
                defaultAlert(
                  "Não possível obter a URL de autenticação, tente novamente mais tarde",
                  false,
                  "Entendi",
                  "error",
                  "Ops! Algo deu errado"
                );
                setIsLoading(false);
              });
          }
        })
        .catch((error) => {
          defaultAlert(
            "Signatáro não encontrado na base de dados do Valid Vidaas.",
            false,
            "Entendi",
            "error",
            "Ops! Algo deu errado"
          );
          setIsLoading(false);
        });
    } else {
      handleSetCertificateType(type);
      close();
    }
  }

  return (
    <>
      <Dialog
        scroll="paper"
        open={open}
        fullWidth={true}
        maxWidth="sm"
        style={{ zIndex: 1 }}
        aria-labelledby="Selecione o tipo de certificado"
        aria-describedby="Caixa de dialogo para selecionar o tipo de certificado"
      >
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle id="alert-dialog-title">
          Selecione o tipo de certificado
        </DialogTitle>
        <DialogContent dividers={true}>
          <Box className={classes.flexContainer}>
            <Card className={classes.cardButton}>
              <ButtonBase
                onClick={() => {
                  handleSelectCertificateType("A1");
                }}
              >
                <CardContent>
                  <Box style={{ marginBottom: 10 }}>Certificado do tipo A1</Box>
                  <Box>
                    {logoVidas ? (
                      <img
                        className={classes.logoCertificate}
                        src={logoCertificate}
                        alt="Logotipo Vidaas"
                      />
                    ) : null}
                  </Box>
                </CardContent>
              </ButtonBase>
            </Card>
            <Card className={classes.cardButton}>
              <ButtonBase
                onClick={() => {
                  handleSelectCertificateType("A3");
                }}
              >
                <CardContent>
                  <Box style={{ marginBottom: 10 }}>Certificado do tipo A3</Box>
                  <Box>
                    {logoVidas ? (
                      <img
                        className={classes.logoCompany}
                        src={logoVidas}
                        alt="Logotipo Vidaas"
                      />
                    ) : null}
                  </Box>
                </CardContent>
              </ButtonBase>
            </Card>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  gridRoot: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paperStyle: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  flexContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  flexContainerButton: {
    marginTop: theme.spacing(5),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "flex-start",
    width: "100%",
    gap: "10px",
    margin: theme.spacing(2, 0),
  },
  flexItems: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2),
    },
    width: "100%",
  },
  cardButton: {
    display: "block",
    textAlign: "center",
    width: "48%",
    height: "25%",
  },
  logoCompany: {
    width: "150px",
    marginTop: "-5px",
  },
  logoCertificate: {
    width: "48px",
    marginTop: "-5px",
  },
}));
export default SelectCertificateTypeDialog;
