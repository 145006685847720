import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LinkMaterial from "@material-ui/core/Link";

export default function Footer() {
  const classes = useStyles();
  return (
    <Grid container component="footer" className={classes.footer}>
      <Grid item xs={12} md={5}>
        <Typography variant="body2">
          ©OM30 - Tecnologia movida por pessoas
        </Typography>
      </Grid>
      <Grid item xs={4} md={2}>
        <LinkMaterial target="_blank" href="/terms">
          Termos de uso
        </LinkMaterial>
      </Grid>
      <Grid item xs={false} md={3}>
        <LinkMaterial target="_blank" href="/privacyPolicy">
          Politica de privacidade
        </LinkMaterial>
      </Grid>
      <Grid item xs={4} md={2}>
        <Typography>{`TeleSaúde ${process.env.REACT_APP_VERISON}`}</Typography>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: "auto",
    bottom: 0,
    padding: theme.spacing(1),
    [theme.breakpoints.between("xs", "sm")]: {
      height: "70px",
      fontSize: "12px",
      textAlign: "center",
      justifyContent: "center",
      alignItens: "center",
    },
    [theme.breakpoints.up("md")]: {
      height: "40px",
    },
    backgroundColor: theme.palette.secondary.main,
    borderTop: "1px solid lightgrey",
  },
}));
