import api from "../../api";

export const getUserTypes = async () => {
  return api.get("enum/tp_usuario");
};

export const getHealthUnitTypes = async () => {
  return api.get("enum/tp_unidade");
};

export const getAccessProfileTypes = async () => {
  return api.get("enum/tp_perfil_de_acesso");
};

export const getSmsTypes = async () => {
  return api.get("enum/tp_sms");
};

export const getCouncilTypes = async () => {
  return api.get("enum/tp_conselho");
};

export const getSmsStatuses = async () => {
  return api.get("enum/status_sms");
};

export const getScheduleStatuses = async () => {
  return api.get("enum/status_agenda");
};

export const getSatisfactionSurveyRating = async () => {
  return api.get("enum/classificacao_pesquisa_satisfacao");
};

export const getSignatureTypes = async () => {
  return api.get("enum/tp_assinatura");
};

export const getReasonsMissing = async () => {
  return api.get("enum/tp_motivo_falta");
};

export const getDescriptionReasonsMissing = async (reason) => {
  return api.get(`enum/get_tp_motivo_falta/${reason}`);
};
