import React, { useState } from "react";
import {
  TextField,
  Paper,
  Button,
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { toastAlert } from "../../helpers/validation/alerts";
import RecoverDialog from "../../components/RecoverDialog";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useUserData } from "../../context/UserContext";
import logoImage from "../../assets/images/logo.png";
import imageVideo from "../../assets/images/video_image.svg";
import {
  userLogin,
  setToken,
  setLocalUserData,
} from "../../services/endpoints/auth/endpoints";
import {
  MENU_ADMIN,
  MENU_USUARIOS,
  MENU_PROFISSIONAL_DE_SAUDE,
} from "../../helpers/constants";

export default function HomeLogin(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { setUserData } = useUserData();
  const history = useHistory();

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [forgot, setForgot] = useState(false);
  const [forgotType, setForgotType] = useState("");
  const inputRef = React.useRef();

  async function handleLogin(e) {
    e.preventDefault();
    setIsLoading(true);
    validateLoginData();

    const loginData = {
      usuario: login,
      senha: password,
    };

    userLogin(loginData)
      .then(async ({ data }) => {
        if (data.token_auth !== undefined) {
          const { token_auth } = data;
          const {
            id_usuario,
            nome,
            termo_de_uso_aceito,
            id_unidade,
            unidade_de_saude,
            perfil_de_acesso,
            is_profissional_de_saude,
            id_prestador,
          } = data.usuario;
          await setToken(token_auth);
          await setLocalUserData({
            id: id_usuario,
            name: nome,
            health_unit_id: id_unidade,
            health_unit_name: unidade_de_saude,
            accept_terms: termo_de_uso_aceito,
            profile: perfil_de_acesso,
            is_healthcare_professional:
              is_profissional_de_saude === "1" ? true : false,
            id_professional: id_prestador,
            menu:
              perfil_de_acesso === "0"
                ? MENU_ADMIN
                : is_profissional_de_saude === "1"
                ? MENU_PROFISSIONAL_DE_SAUDE
                : MENU_USUARIOS,
          });
          setUserData({
            id: id_usuario,
            name: nome,
            health_unit_id: id_unidade,
            health_unit_name: unidade_de_saude,
            accept_terms: termo_de_uso_aceito,
            profile: perfil_de_acesso,
            id_professional: id_prestador,
            is_healthcare_professional:
              is_profissional_de_saude === "1" ? true : false,
            menu:
              perfil_de_acesso === "0"
                ? MENU_ADMIN
                : is_profissional_de_saude === "1"
                ? MENU_PROFISSIONAL_DE_SAUDE
                : MENU_USUARIOS,
          });
          setIsLoading(false);
          return history.push("/dashboard");
        }

        return history.push("/");
      })
      .catch((error) => {
        inputRef.current.focus();
        setPassword("");
        setIsLoading(false);
      });
  }

  function validateLoginData() {
    if (!login || !password) {
      toastAlert("Usuário e senha são obrigatórios.");
      return false;
    }
  }

  function handleUserLogin(e) {
    setLogin(e.currentTarget.value.trim().toLowerCase());
  }

  function handlePassword(e) {
    setPassword(e.currentTarget.value.trim());
  }

  function handleForgot(type) {
    setForgot(!forgot);
    setForgotType(type);
  }

  return (
    <Grid container component="main" className={classes.root}>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item xs={false} sm={4} md={7} className={classes.image}></Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={0} square>
        <div className={classes.logoCompanyBox}>
          <img
            className={classes.logoCompanyImage}
            src={logoImage}
            alt="Logotipo - OM30 - Tecnologia movida por pessoas"
          />
        </div>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Entrar
          </Typography>
          <form
            className={classes.form}
            onSubmit={handleLogin}
            autoComplete="off"
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="login"
              value={login}
              onChange={handleUserLogin}
              label="Login"
              name="login"
              autoComplete="none"
              inputRef={inputRef}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="new-password"
              value={password}
              onChange={handlePassword}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              className={classes.submit}
            >
              Entrar
            </Button>
            <Grid container>
              <Grid item xs>
                <Button onClick={() => handleForgot("P")} color="primary">
                  Esqueci minha senha
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={() => handleForgot("L")} color="primary">
                  Esqueci meu login
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
      <RecoverDialog
        open={forgot}
        close={handleForgot}
        type={forgotType}
        isLoading={setIsLoading}
      />
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  image: {
    backgroundImage: `url(${imageVideo})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: "#77021f1f",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  logoCompanyBox: {
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    top: 0,
    height: "120px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoCompanyImage: {
    height: "100px",
    marginTop: "-12px",
  },
  paper: {
    [theme.breakpoints.between("xs", "sm")]: {
      margin: theme.spacing(3, 4),
    },
    margin: theme.spacing(6, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
