import * as yup from "yup";
import moment from "moment";
import ptBr from "../../../../locale/yup-translation-pt-BR";
import {
  RGX_NOME_COMPLETO,
  RGX_NAO_CONTEM_NUMEROS,
  RGX_CELULAR_BR,
  RGX_SENHA
} from "../../../regex";
yup.setLocale(ptBr);

export const CreateUserSchema = yup.object().shape({
  cd_usuario: yup.string().min(3).label("Login").required(),
  nome: yup
    .string()
    .min(3) // eslint-disable-next-line
    .matches(RGX_NAO_CONTEM_NUMEROS, "${path} não pode conter números", {
      excludeEmptyString: false,
    }) // eslint-disable-next-line
    .matches(RGX_NOME_COMPLETO, "${path} deve conter Nome e Sobrenome", {
      excludeEmptyString: false,
    })
    .label("Nome Completo")
    .required(),
  nome_social: yup
    .string()
    .min(3) // eslint-disable-next-line
    .matches(RGX_NAO_CONTEM_NUMEROS, "${path} não pode conter números", {
      excludeEmptyString: false,
    }) // eslint-disable-next-line
    .matches(RGX_NOME_COMPLETO, "${path} deve conter Nome e Sobrenome", {
      excludeEmptyString: false,
    })
    .label("Nome Social"),
  perfil_de_acesso: yup.string().label("Perfil de Acesso").required(),
  is_profissional_de_saude: yup.boolean(),
  id_unidade: yup
    .string()
    .label("Unidade de Saúde")
    .when("perfil_de_acesso", {
      is: (e) => e !== "" && e !== "0",
      then: yup.string().required(),
    })
    .when("perfil_de_acesso", {
      is: (e) => e === "0",
      then: yup.string().nullable(),
    }),
  alterar_senha: yup.boolean(),
  senha: yup
    .string()
    .label("Senha")
    .when("alterar_senha", {
      is: (alterar_senha) => alterar_senha === true,
      then: yup.string()// eslint-disable-next-line
        .min(8)
        .required()
        .matches(RGX_SENHA, "${path} deve conter caracteres alfabéticos e no mímino um número", {
          excludeEmptyString: false,
        }),
    }),
  confirmacao_senha: yup
    .string()// eslint-disable-next-line
    .label("Confirmação de senha")
    .when(["alterar_senha", "senha"], {
      is: (alterar_senha, senha) => alterar_senha && senha && senha.length > 0 ? true : false,
      then: yup.string().required().oneOf([yup.ref("senha")], "Confirmação de senha não coincide com senha")
    }),
  email: yup.string().email().label("Email").required(),
  data_nascimento: yup
    .date()
    .min(moment(new Date(1900, 0, 1)).format("DD/MM/YYYY"))
    .max(
      moment(new Date()).format("YYYY-MM-DD"),
      `A data deve ser menor que ${moment(new Date()).format("DD/MM/YYYY")}`
    )
    .label("Data de Nascimento")
    .required() // eslint-disable-next-line
    .typeError("${path} não contém uma data valida"),
  cpf: yup
    .string() // eslint-disable-next-line
    .label("CPF")
    .min(14)
    .required(),
  celular: yup
    .string() // eslint-disable-next-line
    .matches(RGX_CELULAR_BR, "Número de celular inválido", {
      excludeEmptyString: false,
    })
    .min(14)
    .label("Celular")
    .required(),
  tp_conselho: yup
    .string()
    .label("Tipo Conselho")
    .when("is_profissional_de_saude", {
      is: true,
      then: yup.string().required(),
    }),
  conselho: yup
    .string()
    .label("Conselho")
    .when(["is_profissional_de_saude", "tp_conselho"], {
      is: (is_profissional_de_saude, tp_conselho) => is_profissional_de_saude === true && tp_conselho === "Outros",
      then: yup.string().min(2).max(6).required(),
    }),
  nr_conselho: yup
    .string()
    .label("Numero do Conselho") // eslint-disable-next-line
    .typeError("${path} deve possuir apenas números")
    .when("is_profissional_de_saude", {
      is: true,
      then: yup.string().required(),
    }),
  uf_conselho: yup
    .string()
    .label("UF do Conselho")
    .when("is_profissional_de_saude", {
      is: true,
      then: yup.string().required(),
    })
    .when("is_profissional_de_saude", {
      is: true,
      then: yup.string().required(),
    }),
});
