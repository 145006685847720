import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Icon,
  Typography,
  Button,
  Box,
  Chip,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Content from "../../../components/Content";
import MaterialTable from "@material-table/core";
import pt_BR from "../../../locale/material-table-locale";
import {
  getSettingsScheduleProfessional,
  getSettingsScheduleMyProfessional,
  deleteSettingsSchedule,
} from "../../../services/endpoints/settingsSchedule/endpoints";
import {
  confirmationAlert,
  defaultAlert,
} from "../../../helpers/validation/alerts";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { getFormatedDateUI } from "../../../helpers/dateHelpers";
import { getProfessional } from "../../../services/endpoints/professionals/endpoints";
import { useUserData } from "../../../context/UserContext";

export default function ListSettingSchedules() {
  
  const classes = useStyles();
  const history = useHistory();
  const { userData } = useUserData();

  const [isLoading, setIsLoading] = useState(true);
  const [
    userIsHelthcareProfessional,
    setUserIsHelthcareProfessional,
  ] = useState(false);
  const [settingsSchedules, setSettingsSchedules] = useState([]);
  const [professionalName, setProfessionalName] = useState("");
  const [professionals, setProfessionals] = useState([]);

  useEffect(() => {
    if (userData.is_healthcare_professional) {
      setIsLoading(true);
      setUserIsHelthcareProfessional(true);
      const fetchMySettingsSchedule = async () => {
        await getSettingsScheduleMyProfessional().then(({ data }) => {
          setSettingsSchedules(data.list_agenda);
        });
        setIsLoading(false);
      };
      fetchMySettingsSchedule();
    }
    setIsLoading(false);
  }, [userData]);

  useEffect(() => {
    setIsLoading(true);
    const fetchAllProfessionals = async () => {
      await getProfessional()
        .then(({ data }) => {
          setProfessionals(data.list_prestador);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };
    fetchAllProfessionals();
    return () => {};
  }, []);

  async function handleFindScheduleProfessional(idProfessional) {
    if (idProfessional !== "") {
      setIsLoading(true);
      await getSettingsScheduleProfessional(idProfessional).then(({ data }) => {
        setIsLoading(false);
        if (data.list_agenda.length > 0) {
          setSettingsSchedules(data.list_agenda);
        } else {
          defaultAlert(
            "Profissional de saúde sem agendas cadastradas!",
            false,
            "Ok",
            "question",
            "Nenhum agenda encontrada"
          ).then((result) => {});
          setSettingsSchedules(data.list_agenda);
        }
      });
    }
  }

  function handleEditScheduleSettings(scheduleId) {
    history.push(`/schedule/edit/${scheduleId}`);
  }

  function handleDeleteScheduleSettings(settingsScheduleId) {
    setIsLoading(true);
    confirmationAlert({
      messageBody: "Deseja realmente excluir esta agenda?"
    }).then(async (result) => {
      if (result.value) {
        await deleteSettingsSchedule(settingsScheduleId)
          .then((data) => {
            defaultAlert("Agenda excluída!", true, "OK", "success", "Sucesso!");
            setSettingsSchedules((settingsSchedules) =>
            settingsSchedules.filter((settingSchedule) => settingSchedule.id_agenda_configuracoes !== settingsScheduleId)
            );
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
          });
      }
      setIsLoading(false);
    });
  }

  const COLUMNS = [
    {
      title: "Profissional",
      render: (data) => data.prestador,
    },
    {
      title: "Data inicial",
      field: "data_inicial",
      render: (data) => getFormatedDateUI(data.data_inicial),
    },
    {
      title: "Data final",
      field: "data_final",
      render: (data) => getFormatedDateUI(data.data_final),
    },
    {
      title: "Criado por",
      render: (data) => data.criado_por,
    },
    {
      title: "Status",
      field: "status",
      render: (data) => (
        <Chip
          size="small"
          label={data.status === "0" ? "Inativa" : "Ativa"}
          color={data.status === "0" ? "primary" : "default"}
          style={
            data.status === "0"
              ? null
              : { borderColor: "green", color: "green" }
          }
          variant="outlined"
        />
      ),
    },
  ];

  const TABLE_ACTIONS = [
    (rowData) => ({
      icon: "edit",
      tooltip: "Editar Agenda",
      onClick: (event, rowData) => {
        handleEditScheduleSettings(rowData.id_agenda_configuracoes);
      },
      disabled: rowData.status === "0" ? true : false,
    }),
    (rowData) => ({
      icon: "delete",
      tooltip: "Excluir agenda",
      onClick: (event, rowData) => {
        handleDeleteScheduleSettings(rowData.id_agenda_configuracoes);
      },
      disabled: rowData.status === "0" ? true : false,
    }),
  ];

  return (
    <>
      <Content>
        <MaterialTable
          style={{ margin: "0px 20px", marginBottom: "20px" }}
          title={
            <Box className={classes.titleBox}>
              <Typography variant="h6" component="div">
                {userIsHelthcareProfessional
                  ? "Minhas agendas"
                  : "Agenda do profissional de saúde: " + professionalName}
              </Typography>
              <Select
                className={
                  !userIsHelthcareProfessional
                    ? classes.selectProfessional
                    : classes.invisible
                }
                onChange={(event) =>
                  handleFindScheduleProfessional(event.target.value)
                }
              >
                <MenuItem value={""} selected={true}>
                  Selecione um profissional
                </MenuItem>
                {professionals.map((type, index) => (
                  <MenuItem key={type.id_prestador} value={type.id_prestador}>
                    {type.prestador}
                  </MenuItem>
                ))}
              </Select>
              <GreenButton
                className={classes.selectProfessional}
                onClick={() => history.push("/schedule/new")}
                variant="outlined"
                color="default"
                size="small"
                endIcon={<Icon>add</Icon>}
              >
                Criar
              </GreenButton>
            </Box>
          }
          isLoading={isLoading}
          localization={pt_BR}
          columns={COLUMNS}
          actions={TABLE_ACTIONS}
          data={settingsSchedules}
          options={{
            search: true,
            actionsColumnIndex: -1,
            rowStyle: { textAlign: "left" },
          }}
        />
      </Content>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    marginTop: "10px",
    margin: 0,
    width: "100%",
  },
  selectProfessional: {
    marginLeft: theme.spacing(4),
  },
  titleBox: {
    display: "flex",
    maxHeight: "30px",
  },
  flexContainer: {
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  invisible: {
    display: "none",
  },
}));

const GreenButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
