import React, { useState, useEffect } from "react";
import { Chip, Box, Typography, Grid } from "@material-ui/core";
import MaterialTable from "@material-table/core";
import pt_BR from "../../locale/material-table-locale";
import { getFormatedDateTimeUI } from "../../helpers/dateHelpers";
import { makeStyles } from "@material-ui/core/styles";
import { useUserData } from "../../context/UserContext";

import Content from "../../components/Content";
import TermsDialog from "../../components/TermsDialog";
import { errorApiAlert } from "../../helpers/validation/alerts";
import { userLogout } from "../../services/endpoints/auth/endpoints";
import { useHistory } from "react-router-dom";
import DashboardItem from "../../components/DashboardItem";
import { getDashboardData } from "../../services/endpoints/dashboard/endpoints";

export default function Dashboard() {
  const history = useHistory();
  const [isLoadding, setIsLoading] = useState(true);
  const [openTerms, setOpenTerms] = useState(false);
  const classes = useStyles();
  const { userData } = useUserData();
  const [dashboardData, setDashboardData] = useState({});
  const [listLogAccess, setlistLogAccess] = useState([]);

  useEffect(() => {
    async function handleTerms() {
      try {
        if (userData.accept_terms === false) {
          setOpenTerms(true);
        } else {
          setOpenTerms(false);
        }
      } catch (error) {
        errorApiAlert(
          "Detectamos um problema, por favor realize o login novamente"
        ).then(async (result) => {
          if (result.value) {
            await userLogout();
            history.push("/");
          }
        });
      }
    }
    handleTerms();
  }, [userData, history]);

  useEffect(() => {
    if (window.Tawk_API && window.Tawk_API.getWindowType()) {
      window.Tawk_API.showWidget();
      window.Tawk_API.setAttributes({
        name: userData?.name,
      });
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchDashboard = () => {
      getDashboardData().then(({ data }) => {
        setDashboardData(data.dados);
        setlistLogAccess(data.dados.lista_de_acesso);
        setIsLoading(false);
      });
    };
    fetchDashboard();
  }, []);

  const COLUMNS = [
    {
      title: "Usuário",
      render: (data) => data.cd_usuario,
    },
    {
      title: "Data/hora",
      render: (data) => getFormatedDateTimeUI(data.created_at),
    },
    {
      title: "Status",
      render: (data) => (
        <Chip
          size="small"
          label={data.callback_status === "0" ? "Falha" : "Sucesso"}
          color={data.callback_status === "0" ? "primary" : "default"}
          style={
            data.callback_status === "0"
              ? null
              : { borderColor: "green", color: "green" }
          }
          variant="outlined"
        />
      ),
    },
    {
      title: "Mensagem",
      render: (data) => data.callback,
    },
  ];

  return (
    <>
      <Content>
        <TermsDialog open={openTerms} />
        <Grid container className={classes.root}>
          <Grid item xs={12} className={classes.dashboardTitle}>
            <Typography variant="h4">Bem vindo, {userData?.name}!</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.dashboardItems}>
              {userData?.profile === "0" ? (
                <>
                  <DashboardItem
                    title="Pacientes:"
                    value={
                      dashboardData.total_paciente === 0
                        ? "0"
                        : dashboardData.total_paciente
                    }
                    icon="contacts"
                  />
                  <DashboardItem
                    title="Unidades de saúde:"
                    value={
                      dashboardData.total_unidade_de_saude === 0
                        ? "0"
                        : dashboardData.total_unidade_de_saude
                    }
                    icon="homework"
                  />
                  <DashboardItem
                    title="Profissionais:"
                    value={
                      dashboardData.total_prestador === 0
                        ? "0"
                        : dashboardData.total_prestador
                    }
                    icon="assignmentInd"
                  />
                  <DashboardItem
                    title="Usuários:"
                    value={dashboardData.total_usuario}
                    icon="group"
                  />
                </>
              ) : (
                <>
                  <DashboardItem
                    title="Pacientes:"
                    value={
                      dashboardData.total_paciente === 0
                        ? "0"
                        : dashboardData.total_paciente
                    }
                    icon="contacts"
                  />

                  <DashboardItem
                    title="Agendamentos atendidos (hoje)"
                    value={
                      dashboardData.total_agendamento_atendido_hoje === 0
                        ? "0"
                        : dashboardData.total_agendamento_atendido_hoje
                    }
                    icon="homework"
                  />

                  <DashboardItem
                    title="Agendamentos abertos (hoje):"
                    value={
                      dashboardData.total_agendamento_aberto_hoje === 0
                        ? "0"
                        : dashboardData.total_agendamento_aberto_hoje
                    }
                    icon="assignmentInd"
                  />
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <MaterialTable
              style={{
                margin: "20px 0px",
              }}
              title={
                <Typography variant="h6" component="div">
                  Histórico de acesso
                </Typography>
              }
              isLoading={isLoadding}
              localization={pt_BR}
              columns={COLUMNS}
              data={listLogAccess}
              options={{
                search: false,
                actionsColumnIndex: -1,
                rowStyle: { textAlign: "left" },
              }}
            />
          </Grid>
        </Grid>
      </Content>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  dashboardTitle: {
    marginBottom: theme.spacing(2),
  },
  dashboardItems: {
    gap: "10px",
    display: "flex",
    flex: "1 1 0",
    [theme.breakpoints.between("xs", "sm")]: {
      flexWrap: "wrap",
    },
  },
  tableAccess: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));
