import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import InputMask from "react-input-mask";
import { useHistory, useParams } from "react-router-dom";
import { defaultAlert } from "../../../helpers/validation/alerts";
import { useForm, Controller } from "react-hook-form";
import Content from "../../../components/Content";
import { makeStyles } from "@material-ui/core/styles";
import { CreatePatientSchema } from "../../../helpers/validation/Schemas/PatientSchema/schema";

import {
  validatePatientCPF,
  validatePatientCNS,
  updatePatient,
  createPatient,
  getPatientById,
} from "../../../services/endpoints/patients/endpoints";
import { getCepData } from "../../../services/endpoints/cep/endpoints";
import { getFormatedDateSystem } from "../../../helpers/dateHelpers";

export default function CreateUsers() {
  const history = useHistory();
  const { id } = useParams();

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [celular, setCelular] = useState("");
  const [cns, setCNS] = useState("");
  const [cpf, setCPF] = useState("");
  const [cep, setCEP] = useState("");

  const {
    register,
    errors,
    handleSubmit,
    setError,
    reset,
    control,
    setValue,
    clearError,
    formState,
    getValues,
  } = useForm({
    validationSchema: CreatePatientSchema,
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      data_nascimento: "",
      nome: "",
      cpf: "",
      nome_social: "",
      cartao_nacional_de_saude: "",
      celular: "",
      email: "",
      cep: "",
      n_logradouro: "",
      endereco: "",
      bairro: "",
      estado: "",
      cidade: "",
      complemento: "",
    },
  });

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      setIsEditing(true);
      const handleFetchPatient = async () => {
        await getPatientById(id)
          .then(({ data }) => {
            const {
              nome,
              nome_social,
              cpf,
              celular,
              data_nascimento,
              email,
              cartao_nacional_de_saude,
              cep,
              n_logradouro,
              endereco,
              bairro,
              cidade,
              estado,
              complemento,
            } = data.paciente;
            setValue([
              { nome },
              { cpf },
              { cartao_nacional_de_saude },
              { nome_social },
              { data_nascimento },
              { email },
              { cep },
              { n_logradouro },
              { cidade },
              { complemento },
              { endereco },
              { bairro },
              { estado },
            ]);
            setCelular(celular);
            setCPF(cpf);
            setCNS(cartao_nacional_de_saude);
            setCEP(cep);
          })
          .catch(() => {
            history.push("/users/list");
            setIsEditing(false);
          });
        setIsLoading(false);
      };

      handleFetchPatient();
    }
    return () => {};
  }, [id, history, setValue]);

  async function cnsIsValid(data) {
    const jsonCNS = {
      cns: data.cartao_nacional_de_saude,
    };
    return await validatePatientCNS(jsonCNS)
      .then(({ data }) => {
        if (data.status) {
          return data.status;
        } else {
          defaultAlert(data.msg);
          setError("cartao_nacional_de_saude", "notMatch", data.msg);
          return data.status;
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  async function cpfIsValid(data) {
    const jsonCPF = {
      cpf: data.cpf,
    };

    return await validatePatientCPF(jsonCPF)
      .then(({ data }) => {
        if (data.status) {
          return data.status;
        } else {
          defaultAlert(data.msg);
          setError("cpf", "notMatch", data.msg);
          return data.status;
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  async function handleForm(data) {
    setIsLoading(true);
    const formData = data;

    formData.cpf = formData.cpf.replace(/\D+/g, "");
    formData.cartao_nacional_de_saude = formData.cartao_nacional_de_saude.trim();
    formData.data_nascimento = getFormatedDateSystem(formData.data_nascimento);

    const isValid =
      (await cpfIsValid(formData)) && (await cnsIsValid(formData));

    if (isValid) {
      if (!isEditing) {
        handleCreatePatient(formData);
      } else {
        handleUpdatePatient(id, formData);
      }
    } else {
      setIsLoading(false);
    }
  }

  function handleCreatePatient(data) {
    createPatient(data)
      .then(({ data }) => {
        if (data.status) {
          defaultAlert(
            "Paciente criado com Sucesso!",
            false,
            "Ver lista de pacientes",
            "success",
            "Tudo certo!"
          ).then((result) => {
            if (result.value) {
              history.push("/patients/list");
            }
          });
          setIsLoading(false);
          reset();
          setCPF("");
          setCelular("");
          setCNS("");
          setCEP("");
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let { errors } = error.response.data;

          Object.keys(errors).map((erro) => {
            return setError(erro, "notMatch", errors[erro]);
          });
          setIsLoading(false);
          return false;
        }
      });
  }

  function handleUpdatePatient(patientId, patientForm) {
    updatePatient(patientId, patientForm)
      .then(({ data }) => {
        if (data.status) {
          defaultAlert(
            "Paciente atualizado com Sucesso!",
            false,
            "Ver lista de pacientes",
            "success",
            "Tudo certo!"
          ).then((result) => {
            if (result.value) {
              history.push("/patients/list");
            }
          });
          setIsLoading(false);
          reset();
          setCPF("");
          setCelular("");
          setCNS("");
          setCEP("");
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let { errors } = error.response.data;

          Object.keys(errors).map((erro) => {
            return setError(erro, "notMatch", errors[erro]);
          });
          setIsLoading(false);
          return false;
        }
      });
  }

  function handleChangeCPF(event) {
    setValue("cpf", event.target.value);
    setCPF(event.target.value);
  }

  function handleChangeCNS(event) {
    setValue("cartao_nacional_de_saude", event.target.value);
    setCNS(event.target.value);
  }

  function handleChangeCelular(event) {
    setValue("celular", event.target.value);
    setCelular(event.target.value);
  }

  function handleChangeCEP(event) {
    setValue("cep", event.target.value);
    setCEP(event.target.value);
  }

  function handleCEPSearch() {
    if (getValues("cep").length === 9) {
      setIsLoading(true);
      getCepData(getValues("cep"))
        .then(({ data }) => {
          if (data.dados) {
            const { cidade, uf, bairro, endereco, complemento } = data.dados;
            setValue([
              { cidade },
              { estado: uf },
              { endereco },
              { complemento },
              { bairro },
            ]);
            setIsLoading(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }

  const { dirty } = formState;

  return (
    <>
      <Content formIsDirty={dirty}>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container className={classes.gridRoot}>
          <Box className={classes.paperStyle} component={Paper} elevation={2}>
            <Typography component="h1" variant="h6">
              {isEditing ? "Editar Paciente" : "Novo Paciente"}
            </Typography>
            <form
              method="POST"
              onSubmit={handleSubmit((data) => handleForm(data))}
            >
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.nome}
                  className={classes.flexItems}
                >
                  <InputLabel>Nome</InputLabel>
                  <Controller as={<Input />} control={control} name="nome" />
                  <FormHelperText>{errors?.nome?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  error={!!errors.nome_social}
                  className={classes.flexItems}
                >
                  <InputLabel>Nome Social</InputLabel>
                  <Controller
                    as={<Input />}
                    control={control}
                    name="nome_social"
                  />
                  <FormHelperText>
                    {errors?.nome_social?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl error={!!errors.cpf} className={classes.flexItems}>
                  <InputLabel>CPF</InputLabel>
                  <InputMask
                    mask="999.999.999-99"
                    disableUnderline
                    maskChar={" "}
                    value={cpf}
                    onChange={handleChangeCPF}
                    onFocus={() => clearError("cpf")}
                  >
                    {() => <Input name="cpf" inputRef={register()} />}
                  </InputMask>
                  <FormHelperText>{errors?.cpf?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  error={!!errors.cartao_nacional_de_saude}
                  className={classes.flexItems}
                >
                  <InputLabel>Cartão Nacional de Saúde</InputLabel>
                  <Controller
                    as={
                      <InputMask
                        mask="999999999999999"
                        disableUnderline
                        maskChar={null}
                        value={cns}
                        onChange={handleChangeCNS}
                        onFocus={() => clearError("cartao_nacional_de_saude")}
                      >
                        {() => <Input />}
                      </InputMask>
                    }
                    control={control}
                    name="cartao_nacional_de_saude"
                  />
                  <FormHelperText>
                    {errors?.cartao_nacional_de_saude?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.celular}
                  className={classes.flexItems}
                >
                  <InputLabel>Celular</InputLabel>
                  <InputMask
                    mask="(99)99999-9999"
                    disableUnderline
                    maskChar={null}
                    value={celular}
                    onChange={handleChangeCelular}
                    onFocus={() => clearError("celular")}
                  >
                    {() => <Input name="celular" inputRef={register()} />}
                  </InputMask>
                  <FormHelperText>{errors?.celular?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  error={!!errors.data_nascimento}
                  className={classes.flexItems}
                >
                  <InputLabel shrink={true}>Data de Nascimento</InputLabel>
                  <Input
                    name="data_nascimento"
                    type="date"
                    inputProps={{min:"01/01/2020" ,max: new Date().toISOString().split("T")[0]}}
                    inputRef={register()}
                  />
                  <FormHelperText>
                    {errors?.data_nascimento?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl error={!!errors.cep} className={classes.flexItems}>
                  <InputLabel>CEP</InputLabel>
                  <Controller
                    onBlur={handleCEPSearch}
                    as={
                      <InputMask
                        mask="99999-999"
                        disableUnderline
                        maskChar={null}
                        value={cep}
                        onChange={handleChangeCEP}
                        onFocus={() => clearError("cep")}
                      >
                        {() => <Input />}
                      </InputMask>
                    }
                    control={control}
                    name="cep"
                  />
                  <FormHelperText>{errors?.cep?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  error={!!errors.endereco}
                  className={classes.flexItems}
                >
                  <InputLabel>Logradouro</InputLabel>
                  <Controller
                    as={<Input type="text" />}
                    control={control}
                    name="endereco"
                  />
                  <FormHelperText>{errors?.endereco?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  error={!!errors.n_logradouro}
                  className={classes.flexItems}
                >
                  <InputLabel>Número</InputLabel>
                  <Controller
                    as={<Input type="text" />}
                    control={control}
                    name="n_logradouro"
                  />
                  <FormHelperText>
                    {errors?.n_logradouro?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.bairro}
                  className={classes.flexItems}
                >
                  <InputLabel>Bairro</InputLabel>
                  <Controller
                    as={<Input type="text" />}
                    control={control}
                    name="bairro"
                  />
                  <FormHelperText>{errors?.bairro?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  error={!!errors.cidade}
                  className={classes.flexItems}
                >
                  <InputLabel>Cidade</InputLabel>
                  <Controller
                    as={<Input type="text" />}
                    control={control}
                    name="cidade"
                  />
                  <FormHelperText>{errors?.cidade?.message}</FormHelperText>
                </FormControl>
                <FormControl error={!!errors.estado} className={classes.flexItems}>
                  <InputLabel>Estado</InputLabel>
                  <Controller
                    as={
                      <InputMask
                        mask="aa"
                        disableUnderline
                        maskChar={null}
                        value={getValues("estado")}
                        onChange={(value) => { setValue(value, 'estado') }}
                        onFocus={() => clearError("estado")}
                      >
                        {() => <Input inputProps={{ style: { textTransform: "uppercase" } }}/>}
                      </InputMask>
                    }
                    control={control}
                    name="estado"
                  />
                  <FormHelperText>{errors?.estado?.message}</FormHelperText>
                </FormControl>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.complemento}
                  className={classes.flexItems}
                >
                  <InputLabel>Complemento</InputLabel>
                  <Controller
                    as={<Input type="text" />}
                    control={control}
                    name="complemento"
                  />
                  <FormHelperText>
                    {errors?.complemento?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.email}
                  className={classes.flexItems}
                >
                  <InputLabel>Email</InputLabel>
                  <Controller
                    as={<Input type="email" />}
                    control={control}
                    name="email"
                  />
                  <FormHelperText>{errors?.email?.message}</FormHelperText>
                </FormControl>
              </Box>
              <Box className={classes.flexContainerButton}>
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  color="primary"
                >
                  {isEditing ? "Atualizar Paciente" : "Cadastrar Paciente"}
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Content>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  gridRoot: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paperStyle: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  flexContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  flexContainerButton: {
    marginTop: theme.spacing(5),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "flex-start",
  },
  flexItems: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
    },
    width: "100%",
  },
  flexItem: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
      maxWidth: "33%",
    },
    width: "100%",
  },
}));
