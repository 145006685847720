import React, { useState, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NavigationBar from "../../components/NavigationBar";
import DrawerMenu from "../../components/DrawerMenu";
import { Prompt } from "react-router-dom";

export default function Content({ children, formIsDirty }) {
  const classes = useStyles();

  const [drawer, setDrawer] = useState(false);

  function handleDrawer(event) {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(!drawer);
  }

  useEffect(() => {
    (function () {
      const s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/5ed97b6e4a7c62581799f36a/1ea136nic";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);

  return (
    <>
      <Prompt
        when={formIsDirty || false}
        message="Deseja realmente sair? As informações alteradas serão perdidas!"
      />
      <Grid container className={classes.root}>
        <NavigationBar handleDrawer={handleDrawer} />
        <DrawerMenu drawer={drawer} handleDrawer={handleDrawer} />
        <Grid container component="main">
          <Box component="section" className={classes.section}>
            {children}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  section: {
    width: "100%",
    marginTop: "80px",
  },
}));
