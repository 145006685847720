import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Content from "../../../../components/Content";
import { makeStyles } from "@material-ui/core/styles";
import { defaultAlert } from "../../../../helpers/validation/alerts";
import { CreateProcedureSchema } from "../../../../helpers/validation/Schemas/ProcedureSchema/schema";
import {
  createProcedure,
  updateProcedure,
  getProcedureById,
} from "../../../../services/endpoints/procedures/endpoints";

export default function CreateProcedure() {
  const history = useHistory();
  const { id } = useParams();

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { errors, handleSubmit, setError, reset, control, setValue } = useForm({
    validationSchema: CreateProcedureSchema,
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      nome: "",
      descricao: "",
      codigo: "",
    },
  });

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      setIsEditing(true);
      const fetchProcedure = async () => {
        await getProcedureById(id)
          .then(({ data }) => {
            const { nome, descricao, codigo } = data.procedimento;
            setValue([{ nome }, { descricao }, { codigo }]);
          })
          .catch(() => {
            history.push("/procedures/list");
            setIsEditing(false);
          });
        setIsLoading(false);
      };

      fetchProcedure();
    } // eslint-disable-next-line
  }, [id, history]);

  async function handleForm(data) {
    setIsLoading(true);
    if (!isEditing) {
      handleCreateProcedure(data);
    } else {
      handleUpdateProcedure(id, data);
    }
  }

  function handleCreateProcedure(data) {
    createProcedure(data)
      .then(({ data }) => {
        if (data.status) {
          defaultAlert(
            "Procedimento criado com sucesso!",
            false,
            "Ver lista de procedimentos",
            "success",
            "Tudo certo!"
          ).then((result) => {
            if (result.value) {
              history.push("/procedures/list");
            }
          });
          setIsLoading(false);
          reset();
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let { errors } = error.response.data;

          Object.keys(errors).map((erro) => {
            return setError(erro, "notMatch", errors[erro]);
          });
          setIsLoading(false);
          return false;
        }
      });
  }

  function handleUpdateProcedure(ProcedureId, ProcedureForm) {
    updateProcedure(ProcedureId, ProcedureForm)
      .then(({ data }) => {
        if (data.status) {
          defaultAlert(
            "Procedimento atualizado com sucesso!",
            false,
            "Ver lista de procedimentos",
            "success",
            "Tudo certo!"
          ).then((result) => {
            if (result.value) {
              history.push("/procedures/list");
            }
          });
          setIsLoading(false);
          reset();
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let { errors } = error.response.data;

          Object.keys(errors).map((erro) => {
            return setError(erro, "notMatch", errors[erro]);
          });
          setIsLoading(false);
          return false;
        }
      });
  }

  return (
    <>
      <Content>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container className={classes.gridRoot}>
          <Box className={classes.paperStyle} component={Paper} elevation={2}>
            <Typography component="h1" variant="h6">
              {isEditing ? "Editar procedimento" : "Criar procedimento"}
            </Typography>
            <form
              method="POST"
              onSubmit={handleSubmit((data) => handleForm(data))}
            >
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.nome}
                  className={classes.flexItems}
                >
                  <InputLabel>Nome do procedimento</InputLabel>
                  <Controller
                    as={<Input />}
                    rules={{ required: true }}
                    control={control}
                    name="nome"
                  />
                  <FormHelperText>{errors?.nome?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  error={!!errors.codigo}
                  className={classes.flexItems}
                >
                  <InputLabel>Código do procedimento</InputLabel>
                  <Controller
                    as={<Input />}
                    rules={{ required: true }}
                    control={control}
                    name="codigo"
                  />
                  <FormHelperText>{errors?.codigo?.message}</FormHelperText>
                </FormControl>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.descricao}
                  className={classes.flexItems}
                >
                  <InputLabel>Descrição</InputLabel>
                  <Controller
                    as={<Input />}
                    rules={{ required: true }}
                    control={control}
                    name="descricao"
                  />
                  <FormHelperText>{errors?.descricao?.message}</FormHelperText>
                </FormControl>
              </Box>
              <Box className={classes.flexContainerButton}>
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  color="primary"
                >
                  {isEditing ? "Atualizar" : "Cadastrar"}
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Content>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  gridRoot: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paperStyle: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  flexContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  flexContainerButton: {
    marginTop: theme.spacing(5),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "flex-start",
  },
  flexItems: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
    },
    width: "100%",
  },
  flexItem: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
      maxWidth: "33%",
    },
    width: "100%",
  },
}));
