import React, { useState, useEffect } from "react";
import {
  Typography,
  List,
  ListItem,
  CircularProgress,
  Box,
  Button,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getAllPatientSmsDeliveredById } from "../../services/endpoints/sms/endpoints";
import { getFormatedDateTimeUI } from "../../helpers/dateHelpers";
import FormatLineSpacingIcon from "@material-ui/icons/FormatLineSpacing";

export default function SmsByPatientsList({ patientId, patientName }) {
  const classes = useStyles();
  const [smsList, setSmsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (patientId) {
      setIsLoading(true);
      const getAllSmsDeliveredByPatientId = () => {
        getAllPatientSmsDeliveredById(patientId)
          .then(({ data }) => {
            setSmsList(data.list_sms);
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      };
      getAllSmsDeliveredByPatientId();
    }
  }, [patientId]);

  function handleRevertSmsList() {
    var newSmsList = smsList;
    setSmsList(reverseArray([...newSmsList]));
  }

  const reverseArray = (array) => {
    const revArray = array.reverse();
    return [...revArray];
  };

  return (
    <>
      <Grid container justify="flex-end">
        {smsList.length > 0 ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleRevertSmsList()}
          >
            <FormatLineSpacingIcon />
          </Button>
        ) : (
          ""
        )}
      </Grid>
      <List>
        {smsList.length > 0 ? (
          smsList.map((sms, index) => (
            <ListItem
              key={index}
              className={classes.smsList}
              alignItems="flex-start"
            >
              <Typography component="p" variant="subtitle2">
                Status:{" "}
                <Typography component="span" variant="caption">
                  {sms.descricao_status.toUpperCase()}
                </Typography>
              </Typography>
              <Typography component="p" variant="subtitle2">
                Data e hora de agendamento do SMS:{" "}
                <Typography component="span" variant="caption">
                  {getFormatedDateTimeUI(sms.dh_agendado)}
                </Typography>
              </Typography>
              <Typography component="p" variant="subtitle2">
                Data e hora de Envio:{" "}
                <Typography component="span" variant="caption">
                  {getFormatedDateTimeUI(sms.dh_envio)}
                </Typography>
              </Typography>
              <Typography component="p" variant="subtitle2">
                Conteúdo da mensagem:{" "}
                <Typography component="span" variant="caption">
                  {sms.msg}
                </Typography>
              </Typography>
              <Typography component="p" variant="subtitle2">
                Enviado para o número:{" "}
                <Typography component="span" variant="caption">
                  {sms.celular.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")}
                </Typography>
              </Typography>
            </ListItem>
          ))
        ) : isLoading ? (
          <Box className={classes.centerLoading}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          "Ainda não foi enviado nenhum sms a este paciente"
        )}
      </List>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  centerLoading: {
    display: "flex",
    justifyContent: "center",
  },
  smsList: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid lightgrey",
    width: "50%",
  },
}));
