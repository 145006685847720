import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Paper, Button, Grid, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { getTerm } from "../../../services/endpoints/public/endpoints";
import logoImage from "../../../assets/images/logo.png";

export default function ExternalTerm(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [term, setTerm] = useState({});

  const history = useHistory();

  function handleBackHistory() {
    return history.goBack();
  }

  useEffect(() => {
    const getTermContent = () => {
      getTerm()
        .then(({ data }) => {
          setTerm(data.termo_de_uso);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };
    getTermContent();
  }, []);

  return (
    <Grid container component="main" className={classes.root}>
      <div className={classes.logoCompanyBox}>
        <Link to="/">
          <img
            className={classes.logoCompanyImage}
            src={logoImage}
            alt="Logotipo - OM30 - Tecnologia movida por pessoas"
          />
        </Link>
      </div>
      <Grid
        item
        xs={12}
        sm={10}
        md={10}
        component={Paper}
        className={classes.paper}
        elevation={6}
      >
        <Typography component="h1" variant="h4">
          {isLoading ? (
            <>
              <Skeleton variant="rect" width={400} height={40} />
              <br />
            </>
          ) : (
            term.nome_termo
          )}
        </Typography>

        <Typography component="div" variant="body1">
          {isLoading ? (
            <>
              {[...Array(21).keys()].map((index) => {
                return <Skeleton key={index} width={910} variant="text" />;
              })}
            </>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: term.descricao }} />
          )}
        </Typography>
        <br />
        {isLoading ? (
          <Skeleton variant="rect" width={100} height={50} />
        ) : (
          <Button
            color="primary"
            onClick={handleBackHistory}
            size="large"
            variant="contained"
          >
            VOLTAR
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  root: {
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
  },
  logoCompanyBox: {
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    top: 0,
    height: "90px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoCompanyImage: {
    height: "80px",
    marginTop: "-10px",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(4),
    margin: theme.spacing(2, 1),
  },
}));
