import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Chip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getDocumentTags } from "../../services/endpoints/documents/endpoints";
import LabelIcon from "@material-ui/icons/Label";

export default function TagsModal(props) {
  const classes = useStyles();
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchTags = () => {
      getDocumentTags().then(({ data }) => {
        setTags(data.list_tags);
        setIsLoading(false);
      });
    };
    fetchTags();
  }, []);

  function handleTagSelected(tagDescription, tagReference) {
    const selectedTagWithEditorContent = `${
      props.editorContent
    } ${tagDescription.toUpperCase()}: ${tagReference}`;

    props.setEditorContent(selectedTagWithEditorContent);
    props.handleCloseModal();
  }

  return (
    <>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={props.open}
        onClose={props.handleCloseModal}
      >
        <DialogTitle>Tags disponíveis</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Abaixo, as tags de substituição disponíveis para uso no documento:
          </DialogContentText>
          <Box className={classes.flexContainer}>
            {isLoading ? <CircularProgress color="inherit" /> : ""}
            {tags.map((tag, index) => (
              <Chip
                className={classes.tags}
                icon={<LabelIcon />}
                label={tag.description}
                color="primary"
                onClick={() => handleTagSelected(tag.description, tag.value)}
                key={index}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleCloseModal} color="default">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  tags: {
    margin: theme.spacing(2),
  },
}));
