import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from "@material-ui/lab";
import {
  Paper,
  CircularProgress,
  Typography,
  Box,
  Button,
  Backdrop,
} from "@material-ui/core";
import Icon from '@material-ui/core/Icon';
import { getFormatedDateTimeUI } from "../../helpers/dateHelpers";
import { getUploadedFile } from "../../services/endpoints/files/endpoints";
import noDataImage from "../../assets/images/no_data_history.png";

const FileList = ({ fileList, isLoading }) => {
  const classes = useStyles();
  const [isLoadingFilePreview, setIsLoadingFilePreview] = useState(false);

  function handlePreview(fileId, isDownload, filename, fileType) {
    setIsLoadingFilePreview(true);
    getUploadedFile(fileId)
      .then(({ data }) => {
        const blobContent = new Blob([data], { type: fileType });

        const generatedUrl =
          window.URL && window.URL.createObjectURL
            ? window.URL.createObjectURL(blobContent)
            : window.webkitURL.createObjectURL(blobContent);

        if (isDownload) {
          const tempLink = document.createElement("a");
          tempLink.style.display = "none";
          tempLink.href = generatedUrl;
          tempLink.setAttribute("download", filename);

          if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
          }

          document.body.appendChild(tempLink);
          tempLink.click();
          setIsLoadingFilePreview(false);

          setTimeout(function () {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(generatedUrl);
          }, 200);
        } else {
          window.open(generatedUrl, "", "width=800,height=600");
          setIsLoadingFilePreview(false);
        }
      })
      .catch(() => {
        setIsLoadingFilePreview(false);
      });
  }

  return (
    <>
      {isLoading ? (
        <Box className={classes.loadingBox}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
          <Box className={classes.timelineBox}>
            <Backdrop className={classes.backdrop} open={isLoadingFilePreview}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Timeline className={classes.timelineComponent} align="left">
              {fileList && fileList.length > 0 ? (
                fileList
                .slice(0)
                .reverse()
                .map((file) => (
                  <TimelineItem key={file.id_arquivo}>
                    <TimelineOppositeContent style={{ flex: 0, padding: 0 }} />
                    <TimelineSeparator>
                      <TimelineDot color="primary">
                        <Icon>{file.type === "img" ? "image" : "insert_drive_file"}</Icon>
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Paper elevation={3} className={classes.paper}>
                        <Typography variant="h6" component="h1">
                          {file.file_name}
                        </Typography>
                        <Typography variant="button" component="p">
                          Tipo: {file.type === "img" ? "Imagem" : "Documento"}
                        </Typography>
                        <Typography
                          variant="body2"
                          component="p"
                          color="textSecondary"
                        >
                          Data de criação:{" "}
                          {getFormatedDateTimeUI(file.created_at)}
                        </Typography>
                        <Box className={classes.boxFileButtons}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handlePreview(file.id_arquivo, false, file.file_name, file.file_type)}
                          >
                            Visualizar
                        </Button>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                              handlePreview(
                                file.id_arquivo, true, file.file_name, file.file_type
                              )
                            }
                          >
                            Baixar
                        </Button>
                        </Box>
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                ))
              ) : (
                  <Box className={classes.loadingBox}>
                    <img
                      src={noDataImage}
                      className={classes.imageNoData}
                      alt="Imagem que demonstra que o paciente não possui arquivos anexados"
                    />
                    <Typography variant="h6" component="h6">
                      Paciente sem anexos para exibição!
              </Typography>
                  </Box>
                )}
            </Timeline>
          </Box>
        )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  loadingBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  timelineBox: {
    height: "100%",
    maxHeight: "450px",
    overflow: "overlay",
  },
  imageNoData: {
    width: 150,
  },
  boxFileButtons: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    margin: theme.spacing(2, 0),
  },
}));

export default FileList;
