import api from "../../api";

export const getMyUser = async () => {
    return api.get("profile");
};
  
export const validateUserCPF = async (params) => {
  return api.post("profile/validatecpf", params);
};

export const updateMyUser = async (params) => {
  return api.put(`profile/update`, params);
};

export const checkCertificateSign = async () => {
  return api.get(`profile/check_has_ceritificao`);
};

export const checkSignatory = async () => {
  return api.get('profile/verifica_signatario');
};

export const importSignatory = async (data) => {
  return api.post('profile/importar_signatario', data);
};

export const importCertificate = async (data) => {
  return api.post(`profile/importar_certificado_digital`, data, {
    headers: {
      "Content-Type": `multipart/form-data;`,
    },
  });
};

export const removeCertificate = async () => {
  return api.delete(`profile/remover_certificado_digital`);
};

export const searchA3Vidaas = async () => {
  return api.get(`profile/buscar_signatario_vidaas`);
};

export const searchURLAuthA3Vidaas = async () => {
  return api.get(`profile/buscar_url_import_vidaas`);
};

export const searchURLAuthSignatoryA3Vidaas = async () => {
  return api.get(`profile/buscar_url_import_signatario_vidaas`);
};

export const saveCredentialsVidaas = async (data) => {
  return api.post("profile/salva_credencias_vidaas", data);
};