import * as yup from "yup";
import ptBr from "../../../../locale/yup-translation-pt-BR";
import { RGX_NAO_CONTEM_NUMEROS, RGX_SOMENTE_NUMEROS } from "../../../regex";
yup.setLocale(ptBr);

export const CreateHealthUnitSchema = yup.object().shape({
  nome: yup
    .string()
    .min(5) // eslint-disable-next-line
    .matches(RGX_NAO_CONTEM_NUMEROS, "${path} não pode conter números", {
      excludeEmptyString: false,
    }) // eslint-disable-next-line
    .label("Identificação Unidade")
    .required(),
  cnes: yup
    .string() // eslint-disable-next-line
    .min(1, "${path} deve conter pelo menos 1 digitos") // eslint-disable-next-line
    .matches(RGX_SOMENTE_NUMEROS, "${path} deve conter somente números", {
      excludeEmptyString: false,
    })
    .label("CNES")
    .required(),
  tipo: yup.string().label("Tipo de Unidade").required(),
});
