import api from "../../../api";

export const index = async () => {
  return api.get(`relatorio/producao`);
};

export const professionalsUnit = async (unitId) => {
  return api.get(`relatorio/producao/prestadores_por_unidade?id_unidade=${unitId}`);
};

export const exportXlsx = async (unitId, dateInit, dateFinish) => {
  return api.get(`relatorio/producao/xls?id_unidade=${unitId}&data_inicial=${dateInit}&data_final=${dateFinish}`, {
    responseType: "arraybuffer",
    headers: {
      "Content-Type": "application/vnd.ms-excel",
    },
  });
};