import * as yup from "yup";
import moment from "moment";
import ptBr from "../../../../locale/yup-translation-pt-BR";
import {
  RGX_NOME_COMPLETO,
  RGX_NAO_CONTEM_NUMEROS,
  RGX_CELULAR_BR,
} from "../../../regex";
yup.setLocale(ptBr);

export const CreatePatientSchema = yup.object().shape({
  nome: yup
    .string()
    .min(3) // eslint-disable-next-line
    .matches(RGX_NOME_COMPLETO, "${path} deve conter Nome e Sobrenome", {
      excludeEmptyString: false,
    })
    // eslint-disable-next-line no-template-curly-in-string
    .matches(RGX_NAO_CONTEM_NUMEROS, "${path} não pode conter números", {
      excludeEmptyString: false,
    }) // eslint-disable-next-line
    .label("Nome")
    .required(),
  nome_social: yup.string().when({
    is: (e) => e.length > 0,
    then: yup
      .string()
      .min(3) // eslint-disable-next-line
      .matches(RGX_NAO_CONTEM_NUMEROS, "${path} não pode conter números", {
        excludeEmptyString: false,
      }) // eslint-disable-next-line
      .matches(RGX_NOME_COMPLETO, "${path} deve conter Nome  e Sobrenome", {
        excludeEmptyString: false,
      })
      .label("Nome Social")
      .notRequired(),
  }),
  email: yup
    .string()
    .test('mailValid', 'E-mail inválido', (value) => {
      return (!!value) ? /\S+@\S+\.\S+/.test(value) : true;
    })
    .label("Email")
    .required(),
  data_nascimento: yup
    .date()
    .min(moment(new Date(1900, 0, 1)).format("DD/MM/YYYY"))
    .max(
      moment(new Date()).format("YYYY-MM-DD"),
      `A "Data de Nascimento" deve ser menor que ${moment(new Date()).format(
        "DD/MM/YYYY"
      )}`
    )
    .label("Data de Nascimento")
    // eslint-disable-next-line no-template-curly-in-string
    .typeError("${path} não contém uma data valida") // eslint-disable-next-line
    .required(),
  cpf: yup
    .string() // eslint-disable-next-line
    .label("CPF")
    .min(14)
    .required(),
  cartao_nacional_de_saude: yup.string().when({
    is: (e) => e.length > 0,
    then: yup
      .string() // eslint-disable-next-line
      .label("Cartão Nacional de Saúde")
      .min(15)
      .notRequired(),
  }),
  celular: yup
    .string()
    .min(14)
    .matches(RGX_CELULAR_BR, "Número de celular inválido", {
      excludeEmptyString: false,
    })
    .label("Celular")
    .required(),
  cep: yup.string().max(9).min(9).label("CEP").required(),
  cidade: yup.string().max(100).label("Cidade").required(),
  n_logradouro: yup
    .string()
    .max(9)
    .label("Número")
    .matches(/^[0-9]*$/, "Este campo aceita somente números")
    .nullable(),
  endereco: yup.string().max(200).label("Logradouro").required(),
  bairro: yup.string().max(100).label("Bairro").nullable(),
  estado: yup
    .string()
    .max(2)
    .label("Estado")
    .test('stateValid', 'Estado inválido', (value) => {
      const states = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];
      return states.includes(value);
    })
    .required(),
  complemento: yup.string().max(200).label("Complemento").nullable(),
});
