import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Avatar } from "@material-ui/core";
import {
  SentimentDissatisfied,
  SentimentVeryDissatisfied,
  SentimentSatisfiedAlt,
  SentimentSatisfied,
  SentimentVerySatisfied,
} from "@material-ui/icons";

export default function SurveyFacesLayout(props) {
  const classes = useStyles();

  return (
    <Box className={classes.flexContainerFaces}>
      <div className={classes.faceItem}>
        <Avatar className={classes.red}>
          <SentimentVeryDissatisfied />
        </Avatar>
        <Typography variant="subtitle2" component="span">
          Muito insatisfeito
        </Typography>
        <Typography variant="button" component="span">
          {props.surveyFacesValues?.muito_insatisfeito || "..."}
        </Typography>
      </div>
      <div className={classes.faceItem}>
        <Avatar className={classes.softRed}>
          <SentimentDissatisfied />
        </Avatar>
        <Typography variant="subtitle2" component="span">
          Pouco insatisfeito
        </Typography>
        <Typography variant="button" component="span">
          {props.surveyFacesValues?.insatisfeito || "..."}
        </Typography>
      </div>
      <div className={classes.faceItem}>
        <Avatar className={classes.yellow}>
          <SentimentSatisfiedAlt />
        </Avatar>
        <Typography variant="subtitle2" component="span">
          Neutro
        </Typography>
        <Typography variant="button" component="span">
          {props.surveyFacesValues?.neutro || "..."}
        </Typography>
      </div>
      <div className={classes.faceItem}>
        <Avatar className={classes.softGreen}>
          <SentimentSatisfied />
        </Avatar>
        <Typography variant="subtitle2" component="span">
          Pouco satisfeito
        </Typography>
        <Typography variant="button" component="span">
          {props.surveyFacesValues?.satisfeito || "..."}
        </Typography>
      </div>
      <div className={classes.faceItem}>
        <Avatar className={classes.green}>
          <SentimentVerySatisfied />
        </Avatar>
        <Typography variant="subtitle2" component="span">
          Muito satisfeito
        </Typography>
        <Typography variant="button" component="span">
          {props.surveyFacesValues?.muito_satisfeito || "..."}
        </Typography>
      </div>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  flexContainerFaces: {
    display: "flex",
    justifyContent: "center",
    gap: "40px",
    [theme.breakpoints.between("xs", "sm")]: {
      justifyContent: "flex-start",
      gap: "15px",
    },
    flexWrap: "wrap",
  },
  faceItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  softRed: {
    backgroundColor: theme.status.danger(300),
  },
  red: {
    backgroundColor: theme.status.danger(),
  },
  green: {
    backgroundColor: theme.status.success(),
  },
  softGreen: {
    backgroundColor: theme.status.success(300),
  },
  yellow: {
    backgroundColor: theme.status.warning(),
  },
}));
