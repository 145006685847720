import api from "../../api";

export const validatePatientCPF = async (params) => {
  return api.post("paciente/validatecpf", params);
};

export const validatePatientCNS = async (params) => {
  return api.post("paciente/validatecns", params);
};

export const createPatient = async (params) => {
  return api.post("paciente/create", params);
};

export const updatePatient = async (id, params) => {
  return api.put(`paciente/update/${id}`, params);
};

export const deletePatient = async (id, isSoftDelete) => {
  return api.delete(`paciente/delete/${id}${isSoftDelete ? "/1" : ""}`);
};

export const getPatients = async () => {
  return api.get("paciente");
};

export const getPatientById = async (patientId) => {
  return api.get(`paciente/id/${patientId}`);
};

export const getPatientByInformation = async (params) => {
  return api.post("paciente/find/", params);
};

export const getPatientFilesList = async (patientId) => {
  return api.get(`arquivo/list_paciente/${patientId}`);
};
