import api from "../../api";

export const TOKEN_KEY = "token_auth";

export const U_INF = "U_INF";

export const isAuthenticated = () => {
  if (
    localStorage.getItem(TOKEN_KEY) === null ||
    localStorage.getItem(TOKEN_KEY) === "" ||
    localStorage.getItem(TOKEN_KEY) === undefined
  ) {
    return false;
  } else {
    return true;
  }
};

export const isAdmin = () => {
  try {
    if (JSON.parse(localStorage.getItem(U_INF)).profile === "0") {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const setToken = async (token) => {
  return localStorage.setItem(TOKEN_KEY, token);
};

export const removeToken = async () => {
  return localStorage.removeItem(TOKEN_KEY);
};

export const setLocalUserData = async (information) => {
  return localStorage.setItem(U_INF, JSON.stringify(information));
};

export const getLocalUserData = () => {
  if (localStorage.getItem(U_INF)) {
    try {
      return JSON.parse(localStorage.getItem(U_INF));
    } catch (e) {
      return undefined;
    }
  }
};

export const removeLocalUserData = async () => {
  return localStorage.removeItem(U_INF);
};

export const userLogin = async (params) => {
  return api.post("login", params);
};

export const userLogout = async (params) => {
  await removeLocalUserData();
  await removeToken();
  return api.post("logoff", params);
};
