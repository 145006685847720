import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  deletePatient,
} from "../../services/endpoints/patients/endpoints";
import {
  defaultAlert,
  confirmationAlert,
} from "../../helpers/validation/alerts";
import { useUserData } from "../../context/UserContext";

export default function DropDownMenuActionPatient({patient, setIsLoading, setPatients}) {
  const history = useHistory();
  const { userData } = useUserData();

  const [anchorEl, setAnchorEl] = useState(null);

  async function handleDeletePatient(patientId) {
    setIsLoading(true);
    await deletePatient(patientId)
      .then(({ data }) => {
        if (data?.list_agenda && data?.list_agenda.length > 0) {
          confirmationAlert({
            messageBody: data.msg,
            isDismissible: false,
            footerMessage: `<div style='text-align:center'><i>O paciente não será deletado, para fins de relatórios,
              registros e rastreabilidade a consulta será possível.</i></div>`,
          }).then((result) => {
            if (result.value) {
              deletePatient(patientId, true).then(() => {
                defaultAlert(
                  "Paciente excluído com sucesso!",
                  true,
                  "Entendi",
                  "success"
                ).then(() => {
                  setPatients((patients) =>
                    patients.filter(
                      (patient) => patient.id_paciente !== patientId
                    )
                  );
                  setIsLoading(false);
                });
              });
            }
          });
        } else {
          confirmationAlert({
            messageBody: "Deseja realmente excluir esse paciente?",
            isDismissible: false,
            footerMessage: `<div style='text-align:center'><i>O paciente não será deletado, para fins de relatórios,
              registros e rastreabilidade a consulta será possível.</i></div>`,
          }).then((result) => {
            if (result.value) {
              defaultAlert(
                "Paciente excluído com sucesso!",
                true,
                "Entendi",
                "success"
              ).then(() => {
                setPatients((patients) =>
                  patients.filter(
                    (patient) => patient.id_paciente !== patientId
                  )
                );
                setIsLoading(false);
              });
            }
          });
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <IconButton
            id="basic-button"
            aria-controls={Boolean(anchorEl) ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={Boolean(anchorEl) ? "true" : undefined}
            onClick={(e) =>  setAnchorEl(e.currentTarget)}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="menu-actions"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {setAnchorEl(null)}}
          >
            <MenuItem
              onClick={() => { history.push(`/scheduling/new/${patient.id_paciente}`) }}
              disabled={userData.profile === "0"}
            >
              <ListItemIcon>
                <EventAvailableIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Realizar agendamento" />
            </MenuItem>

            <MenuItem
              onClick={() => { history.push(`/patients/edit/${patient.id_paciente}`) }}
            >
              <ListItemIcon>
                <EditIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Editar Paciente" />
            </MenuItem>

            <MenuItem
              onClick={() => { handleDeletePatient(patient.id_paciente) }}
            >
              <ListItemIcon>
                <DeleteIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Excluir Paciente" />
            </MenuItem>
            
          </Menu>
    </>
  );
}
