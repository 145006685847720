import * as yup from "yup";
import ptBr from "../../../../../locale/yup-translation-pt-BR";
yup.setLocale(ptBr);

export const WhatsappSchema = yup.object().shape({
  sid: yup.string().max(100).label("Sid Twilio").required(),
  token: yup.string().max(100).label("Token Twilio").required(),
  telefone: yup.string().max(100).label("Telefone Twilio").required(),
  msg_agendamento: yup
    .string()
    .max(300)
    .label("Mensagem de Agendamento")
    .required(),
  msg_cancelamento: yup
    .string()
    .max(300)
    .label("Mensagem de Cancelamento")
    .required(),
  msg_reagendamento: yup
    .string()
    .max(300)
    .label("Mensagem de Reagendamento")
    .required(),
  msg_lembrete_agendamento: yup
    .string()
    .max(300)
    .label("Mensagem De Lembrete")
    .required(),
  msg_lembrete_agendamento_10: yup
    .string()
    .max(300)
    .label("Mensagem De Lembrete do dia")
    .required(),
  msg_link_documentos: yup
    .string()
    .max(300)
    .label("Mensagem De Link de documentos do paciente")
    .required(),
});
