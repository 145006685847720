import moment from "moment";

export const getAgeByDate = (date) => {
  const content = [];
  const currentDate = moment(new Date());

  const years = currentDate.diff(date, "years");
  if (years >= 0) {
    content.push(`${years} ano${years <= 1 ? "" : "s"}`);
    date = moment(date).subtract(-years, "years");
  }

  const months = currentDate.diff(date, "months");
  if (months >= 0) {
    content.push(`${months} mes${months <= 1 ? "" : "es"}`);
    date = moment(date).subtract(-months, "months");
  }

  const days = currentDate.diff(date, "days");
  if (days >= 0) {
    content.push(`${days} dia${days <= 1 ? "" : "s"}`);
  }
  return content.join(" ");
};

export const getFormatedDateUI = (date) => {
  return moment(date).format("DD/MM/YYYY");
};

export const getFormatedDateTimeUI = (date) => {
  return moment(date).format("DD/MM/YYYY HH:mm:ss");
};

export const getFormatedDateSystem = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const getFormatedTimeUI = (time) => {
  return moment(time).format("HH:mm");
};
