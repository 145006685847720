import React, { useState, useEffect } from "react";
import {
  Paper,
  Button,
  Grid,
  Typography,
  Box,
  TextField,
  Backdrop,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { green } from "@material-ui/core/colors";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import logoImage from "../../assets/images/logo.png";
import {
  checkToken,
  getPatientDocuments,
  readPatientDocuments,
} from "../../services/endpoints/patientDocuments/endpoints";
import { defaultAlert } from "../../helpers/validation/alerts";
import { getFormatedDateUI } from "../../helpers/dateHelpers";

export default function PatientDocuments(props) {
  const classes = useStyles();
  const history = useHistory();
  const { token } = useParams();
  const [formData, setFormData] = useState({
    token: token,
    password: "",
  });
  const [errorData, setErrorData] = useState({
    passwordErrorMessage: "",
    passwordError: false,
  });
  const [patientName, setPatientName] = useState("");
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [showInputPassword, setShowInputPassword] = useState(true);
  const [showDocumentTables, setShowDocumentTables] = useState(false);
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(true);

  useEffect(() => {
    if (token) {
      const verifyToken = async () => {
        checkToken(token)
          .then(({ data }) => {
            setIsTokenValid(true);
            setIsLoading(false);
          })
          .catch(() => {
            history.push("/*");
            setIsLoading(false);
          });
      };
      verifyToken();
    } // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    if (formData.password.length < 6 && formData.password !== "") {
      setErrorData({
        // eslint-disable-next-line
        ...errorData, // eslint-disable-next-line
        ["passwordError"]: true, // eslint-disable-next-line
        ["passwordErrorMessage"]: "A senha deve conter 6 caracteres",
      });
      setIsConfirmButtonDisabled(true);
    } else {
      setErrorData({
        // eslint-disable-next-line
        ...errorData, // eslint-disable-next-line
        ["passwordError"]: false,
      });
      if (formData.password !== "") {
        setIsConfirmButtonDisabled(false);
      }
    } // eslint-disable-next-line
  }, [formData.password]);

  function handlePasswordChange(e) {
    // eslint-disable-next-line
    setFormData({ ...formData, ["password"]: e.target.value });
    if (e.target.value.length < 6 && e.target.value !== "") {
      setErrorData({
        ...errorData, // eslint-disable-next-line
        ["passwordError"]: true, // eslint-disable-next-line
        ["passwordErrorMessage"]: "A senha deve conter 6 caracteres",
      });
    } else {
      setErrorData({
        ...errorData, // eslint-disable-next-line
        ["passwordError"]: false, // eslint-disable-next-line
        ["passwordErrorMessage"]: "",
      });
    }
  }

  async function hadleGetDocuments() {
    if (!errorData.passwordError) {
      setIsLoading(true);
      await getPatientDocuments({
        token: formData.token,
        senha: formData.password,
      })
        .then(({ data }) => {
          setPatientName(data.nome_paciente);
          setDocuments(data.list_documentos);
          setShowInputPassword(false);
          setShowDocumentTables(true);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          defaultAlert(
            "Senha de acesso inválida!",
            false,
            "Tentar novamente"
          ).then(async (result) => {
            if (result.value) {
              setFormData({ ...formData, ["password"]: "" });
              setErrorData({
                ...errorData, // eslint-disable-next-line
                ["passwordError"]: false, // eslint-disable-next-line
                ["passwordErrorMessage"]: "",
              });
            }
          });
        });
    }
  }

  function handleDownloadDocument(documentId, documentName) {
    setIsLoading(true);
    readPatientDocuments({
      token: formData.token,
      senha: formData.password,
      id_registro: documentId,
    })
      .then(({ data }) => {
        const blobContent = new Blob([data], { type: "application/pdf" });
        const generatedUrl =
          window.URL && window.URL.createObjectURL
            ? window.URL.createObjectURL(blobContent)
            : window.webkitURL.createObjectURL(blobContent);

        const tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = generatedUrl;
        tempLink.setAttribute("download", documentName);

        if (typeof tempLink.download === "undefined") {
          tempLink.setAttribute("target", "_blank");
        }

        document.body.appendChild(tempLink);
        tempLink.click();
        setIsLoading(false);

        setTimeout(function () {
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(generatedUrl);
        }, 200);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container component="main" className={classes.root}>
        <Grid item xs={12}>
          <div className={classes.logoCompanyBox}>
            <img
              className={classes.logoCompanyImage}
              src={logoImage}
              alt="Logotipo - OM30 - Tecnologia movida por pessoas"
            />
          </div>
        </Grid>
        {isTokenValid ? (
          showInputPassword && !showDocumentTables ? (
            <Grid item xs={12} className={classes.inputGrid}>
              <Box component={Paper} className={classes.paper}>
                <Typography component="h1" variant="h6">
                  Buscar meus documentos
                </Typography>
                <Typography component="h1" variant="caption">
                  Informe a senha de acesso recebida via SMS ou WhatsApp:
                </Typography>
                <Box className={classes.inputs}>
                  <TextField
                    error={errorData.passwordError}
                    type={"text"}
                    fullWidth
                    label="Digite a senha de acesso"
                    value={formData.password}
                    onChange={handlePasswordChange}
                    inputProps={{ maxLength: 6 }}
                    helperText={
                      errorData.passwordError
                        ? errorData.passwordErrorMessage
                        : ""
                    }
                  />
                </Box>
                <GreenButton
                  color="primary"
                  fullWidth
                  onClick={hadleGetDocuments}
                  disabled={isConfirmButtonDisabled}
                  variant="contained"
                >
                  Buscar documentos
                </GreenButton>
              </Box>
            </Grid>
          ) : (
            <Grid item xs={12} className={classes.documentsGrid}>
              <Box component={Paper} className={classes.paper}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography component="h1" variant="h6">
                    {patientName}
                  </Typography>
                </Box>
                <List dense={true}>
                  {documents.map((document, index) => (
                    <>
                      <ListItem
                        button
                        onClick={() => {
                          handleDownloadDocument(
                            document.id_registro,
                            document.name_pdf
                          );
                        }}
                      >
                        <ListItemText
                          primary={document.nome_documento}
                          secondary={getFormatedDateUI(document.created_at)}
                        />
                        <ListItemSecondaryAction>
                          <CloudDownloadIcon />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </>
                  ))}
                </List>
              </Box>
            </Grid>
          )
        ) : (
          ""
        )}
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  root: {
    flexGrow: 1,
    flexDirection: "column",
  },
  logoCompanyBox: {
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    top: 0,
    height: "80px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoCompanyImage: {
    height: "70px",
    marginTop: "-12px",
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  inputGrid: {
    marginTop: theme.spacing(8),
    alignSelf: "center",
    maxWidth: "500px",
  },
  documentsGrid: {
    marginTop: theme.spacing(1),
    alignSelf: "center",
    width: "95%",
  },
  inputs: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

const GreenButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
