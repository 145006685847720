export const getCpfFormated = (cpf) => {
  if (cpf) {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }
};

export const getMobilePhoneBrFormated = (number) => {
  if (number) {
    return number.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
  }
};
