import api from "../../api";

export const getDatesProfessional = async (idProfessional) => {
  return api.get(`agenda/disponibilidade/datas/${idProfessional}`);
};

export const getSchedulesProfessional = async (idProfessional, date) => {
  return api.get(`agenda/disponibilidade/horarios/${idProfessional}/${date}`);
};

export const getCalendar = async (idProfessional) => {
  return api.get(`agenda/disponibilidade/calendario/${idProfessional}`);
};
