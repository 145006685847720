(function (e) {
  const a = (e["pt"] = e["pt"] || {});
  a.dictionary = Object.assign(a.dictionary || {}, {
    "%0 of %1": "",
    "Align center": "Alinhar ao centro",
    "Align left": "Alinhar à esquerda",
    "Align right": "Alinhar à direita",
    Aquamarine: "",
    Big: "Grande",
    Black: "Preto",
    Blue: "Azul",
    Bold: "Negrito",
    "Bulleted List": "Lista não ordenada",
    Cancel: "Cancelar",
    "Cannot upload file:": "Não foi possível carregar o ficheiro:",
    "Centered image": "Imagem centrada",
    "Change image text alternative": "",
    "Choose heading": "",
    Code: "Código",
    Default: "Padrão",
    "Dim grey": "Cinza Escuro",
    "Document colors": "",
    Downloadable: "Download",
    "Dropdown toolbar": "Menu",
    "Edit link": "Editar Link",
    "Editor toolbar": "Menu do editor",
    "Enter image caption": "Indicar legenda da imagem",
    "Font Background Color": "Cor de fundo",
    "Font Color": "Cor da Fonte",
    "Font Family": "Família de Fontes",
    "Font Size": "Tamanho da Fonte",
    "Full size image": "Imagem em tamanho completo",
    Green: "Verde",
    Grey: "Cinza",
    Heading: "Cabeçalho",
    "Heading 1": "Cabeçalho 1",
    "Heading 2": "Cabeçalho 2",
    "Heading 3": "Cabeçalho 3",
    "Heading 4": "Cabeçalho 4",
    "Heading 5": "Cabeçalho 5",
    "Heading 6": "Cabeçalho 6",
    Huge: "Gigante",
    "Image resize list": "",
    "Image toolbar": "",
    "image widget": "módulo de imagem",
    "Insert image": "Inserir imagem",
    Italic: "Itálico",
    Justify: "Justificar",
    "Left aligned image": "",
    "Light blue": "",
    "Light green": "",
    "Light grey": "",
    Link: "Hiperligação",
    "Link URL": "URL da ligação",
    Next: "Próximo",
    "Numbered List": "Lista ordenada",
    "Open in a new tab": "",
    "Open link in new tab": "",
    Orange: "Laranja",
    Original: "Original",
    Paragraph: "Parágrafo",
    Previous: "Anterior",
    Purple: "Roxo",
    Red: "Vermelho",
    Redo: "Refazer",
    "Remove color": "Remover Cor",
    "Resize image": "",
    "Resize image to %0": "",
    "Resize image to the original size": "",
    "Rich Text Editor, %0": "Editor de texto avançado, %0",
    "Right aligned image": "",
    Save: "Salvar",
    "Show more items": "",
    "Side image": "Imagem lateral",
    Small: "Texto Pequeno",
    Strikethrough: "",
    Subscript: "",
    Superscript: "",
    "Text alignment": "Alinhamento de texto",
    "Text alignment toolbar": "Ferramentas de alinhamento de texto",
    "Text alternative": "Texto alternativo",
    "This link has no URL": "",
    Tiny: "Minúsculo",
    "To-do List": "",
    Turquoise: "",
    "Type or paste your content here.": "Digite ou cole seu conteúdo aqui!",
    "Type your title": "Digite seu titulo",
    Underline: "",
    Undo: "Desfazer",
    Unlink: "Desligar",
    "Upload failed": "Falha ao carregar",
    White: "Branco",
    Yellow: "Amarelo",
    "Words: %0": "Palavras: %0",
    "Characters: %0": "Caracteres: %0",
    "Export to PDF": "Exportar para PDF",
    "Export to Word": "Exportar para Word",
    "Select all": "Selecionar Tudo",
    "Insert table": "Inserir Tabela",
    Column: "Coluna",
    "Header column": "Coluna de cabeçalho",
    "Insert column left": "Inserir coluna a esquerda",
    "Insert column right": "Inserir coluna a direita",
    "Delete column": "Deletar coluna",
    "Select column": "Selecionar coluna",
    "Merge cells": "Mesclar celulas",
    "Merge cell up": "Mesclar celulas",
    "Merge cell right": "Mesclar celulas para direita",
    "Merge cell down": "Mesclar celulas para baixo",
    "Merge cell left": "Mesclar celulas para esquerda",
    "Split cell vertically": "Mesclar celulas verticalmente",
    "Split cell horizontally": "Mesclar celulas horizontalmente",
    "Page break": "Quebra de página",
  });
  a.getPluralForm = function (e) {
    return e !== 1;
  };
})(window.CKEDITOR_TRANSLATIONS || (window.CKEDITOR_TRANSLATIONS = {}));
