import React, { useEffect, useState } from "react";
import MaterialTable from "@material-table/core";
import { Icon, Typography, Button, Box } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { green } from "@material-ui/core/colors";
import Content from "../../../components/Content";
import pt_BR from "../../../locale/material-table-locale";
import {
  confirmationAlert,
  defaultAlert,
} from "../../../helpers/validation/alerts";
import {
  getHealthUnits,
  deleteHealthUnit,
} from "../../../services/endpoints/healthUnit/endpoints";

export default function ListHealthUnits() {
  const classes = useStyles();
  const history = useHistory();

  const [healthUnit, setHealthUnit] = useState([]);
  const [isLoadding, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUnits = async () => {
      await getHealthUnits().then(({ data }) => {
        setHealthUnit(data.list);
      });
      setIsLoading(false);
    };

    fetchUnits();
    return () => {};
  }, []);

  async function handleDeleteHealthUnit(healthUnitId) {
    setIsLoading(true);
    confirmationAlert({
      messageBody: "Deseja realmente excluir esta Unidade de Saúde?",
    }).then(async (result) => {
      if (result.value) {
        await deleteHealthUnit(healthUnitId)
          .then(() => {
            defaultAlert(
              "Unidade de Saúde excluída!",
              true,
              "OK",
              "success",
              "Sucesso!"
            );
            setHealthUnit((healthUnit) =>
              healthUnit.filter((unit) => unit.id_unidade !== healthUnitId)
            );
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
          });
      }
      setIsLoading(false);
    });
  }

  function handleEditHealthUnit(healthUnitId) {
    history.push(`/healthUnit/edit/${healthUnitId}`);
  }

  const COLUMNS = [
    { title: "Indentificação", field: "nome" },
    { title: "CNES", field: "cnes" },
    { title: "Tipo de Unidade", field: "tipo_de_unidade" },
    { title: "Situação", field: "descricao_situacao" },
  ];

  const TABLE_ACTIONS_ADMIN = [
    {
      icon: "edit",
      tooltip: "Editar Unidade de Saúde",
      onClick: (event, rowData) => {
        handleEditHealthUnit(rowData.id_unidade);
      },
    },
    {
      icon: "delete",
      tooltip: "Excluir Unidade de Saúde",
      onClick: (event, rowData) => {
        handleDeleteHealthUnit(rowData.id_unidade);
      },
    },
  ];

  return (
    <>
      <Content>
        <MaterialTable
          style={{ margin: "0px 20px", marginBottom: "20px" }}
          title={
            <Box className={classes.titleBox}>
              <Typography variant="h6" component="div">
                Unidades de Saúde
              </Typography>
              <GreenButton
                className={classes.newTermButton}
                onClick={() => history.push("/healthUnit/new")}
                variant="outlined"
                color="default"
                size="small"
                endIcon={<Icon>add</Icon>}
              >
                Criar
              </GreenButton>
            </Box>
          }
          isLoading={isLoadding}
          localization={pt_BR}
          columns={COLUMNS}
          actions={TABLE_ACTIONS_ADMIN}
          data={healthUnit}
          options={{
            search: true,
            actionsColumnIndex: -1,
          }}
        />
      </Content>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  newTermButton: {
    marginLeft: theme.spacing(4),
  },
  titleBox: {
    display: "flex",
    maxHeight: "30px",
  },
}));

const GreenButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
