import React from "react";
import { TextField } from "@material-ui/core";

const HealthRecordField = ({ label, valueText, onChangeValueText, disabled }) => {
  return (
    <TextField
      label={label}
      multiline
      rows={3}
      variant="outlined"
      margin="normal"
      fullWidth
      disabled={disabled}
      value={valueText || ""}
      onChange={(e) => onChangeValueText(e.target.value)}
      InputLabelProps={{
        shrink: true,
      }}
      // InputProps={{
      //   endAdornment: (
      //     <InputAdornment>
      //       <IconButton size="small" color="primary" edge="start">
      //         <TextFields />
      //       </IconButton>
      //     </InputAdornment>
      //   ),
      // }}
    />
  );
};

export default HealthRecordField;
