import api from "../../api";

export const createSurvey = async (params) => {
  return api.post("room/create_pesquisa", params);
};

export const getAllSurveys = async () => {
  return api.get("pesquisa");
};

export const getSurveyById = async (scheduleId) => {
  return api.get(`pesquisa/agenda/${scheduleId}`);
};

export const getSurveyByScheduleId = async (scheduleId) => {
  return api.get(`pesquisa/agenda/${scheduleId}`);
};

export const getSurveyByProfessionalId = async (professionalId) => {
  return api.get(`pesquisa/prestador/${professionalId}`);
};
