import * as yup from "yup";
import ptBr from "../../../../../locale/yup-translation-pt-BR";
yup.setLocale(ptBr);

export const MailSchema = yup.object().shape({
  identificacao_email: yup
    .string()
    .max(200)
    .label("Nome de identicação do e-mail")
    .required(),
  email: yup.string().email().label("E-mail").required(),
  usuario: yup.string().max(200).label("Usuário").required(),
  senha_email: yup.string().label("Senha do email").required(),
  host_smtp: yup.string().label("Host SMTP(SSL)").required(),
  port_smtp: yup
    .string()
    .label("Porta SMTP")
    .max(4)
    .matches(/^[0-9]*$/, "Este campo aceita somente números")
    .required(),
});
