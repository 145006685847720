import * as yup from "yup";
import ptBr from "../../../../locale/yup-translation-pt-BR";
yup.setLocale(ptBr);

export const CreateDocumentTypeSchema = yup.object().shape({
  nome_tipo: yup.string().min(3).label("Tipo").required(),
  tipo_de_assinatura: yup
    .string()
    .label("Padrão assinatura digital")
    .required(),
  incluir_rodape: yup.string().label("Incluir assinatura digital").required(),
  descricao: yup.string().min(3).label("Descrição").required(),
});
