import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Typography, Button, Box, Chip } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Content from "../../components/Content";
import MaterialTable from "@material-table/core";
import pt_BR from "../../locale/material-table-locale";
import {
    getMyDocumentsRegister,
    signDocumentRegister,
    sendPatientMail,
} from "../../services/endpoints/documentRegister/endpoints";
import { getPatientDocumentPDFReadableRegisteredByRegisterId } from "../../services/endpoints/patientHistory/endpoints";
import { useUserData } from "../../context/UserContext";
import { checkCertificateSign } from "../../services/endpoints/users/endpoints";
import { checkSignatureService } from "../../services/endpoints/systemParameters/endpoints";
import {
    confirmationAlert,
    defaultAlert,
    confirmationSendMailAlert
} from "../../helpers/validation/alerts";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { getFormatedDateUI } from "../../helpers/dateHelpers";

export default function ListPatients() {
    const classes = useStyles();
    const history = useHistory();
    const { userData } = useUserData();

    const [registers, setRegisters] = useState([]);
    const [isLoadding, setIsLoading] = useState(true);
    const [isLoadingPDF, setIsLoadingPDF] = useState(false);
    const [signatureServiceStatus, setSignatureServiceStatus] = useState(false);
    const [userCertificateStatus, setUserCertificateStatus] = useState(false);

    useEffect(() => {
        const fetchRegisters = async () => {
            await getMyDocumentsRegister().then(({ data }) => {
                setRegisters(data.list);
            });
            setIsLoading(false);
        };
        fetchRegisters();
        return () => { };
    }, []);

    useEffect(() => {
        const fetchCheckSignatureService = () => {
            checkSignatureService().then(({ data }) => {
                data.status_serviço_assinatura === "A" ? setSignatureServiceStatus(true) : setSignatureServiceStatus(false);
            });
            checkCertificateSign(userData.id).then(({ data }) => {
                data.has_certificado === "A" ? setUserCertificateStatus(true) : setUserCertificateStatus(false);
            });
        };
        fetchCheckSignatureService();
    }, [userData]);

    function handleSignRegister(registerId, email) {
        setIsLoading(true);
        confirmationAlert({
            messageBody: "Deseja realmente assinar digitalmente este registro de documento?",
            footerMessage:
                "<div style='text-align:center'><i>Assim que ASSINADO, este registro de documento não poderá mais ser modificado!</i></div>",
        }).then(async (result) => {
            if (result.value) {
                await signDocumentRegister(registerId)
                    .then(({ data }) => {
                        if (data.status) {
                            confirmationAlert({
                                titleText: "Tudo Certo",
                                iconText: "success",
                                htmlMessage:  "Documento salvo e assinado digitalmente com sucesso!<br><br><br>Deseja envia o documento por e-mail para o paciente ?<br><br>",
                                confirmationButtonText: "Sim",
                                cancelButtonText: "Não",
                                isDismissible: false
                              }).then((result) => {
                                if (result.value) {
                                    handleSendRegisterMail(data.id_registro, email);
                                }
                              });
                            const fetchRegisters = async () => {
                                await getMyDocumentsRegister().then(({ data }) => {
                                    setRegisters(data.list);
                                });
                                setIsLoading(false);
                            };
                            fetchRegisters();
                        } else {
                            defaultAlert(
                                data.msg,
                                true,
                                "OK",
                                "error",
                                "Erro!"
                            );
                            setIsLoading(false);
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                    });
            }
            setIsLoading(false);
        });
    }

    function handleSendRegisterMail(idRegister, email){
        confirmationSendMailAlert({
            titleText: 'Confirme o e-mail do paciente',
            messageBody: `E-mail do paciente`,
            confirmationButtonText: 'Enviar',
            email: email
          }).then((result) => {
            if(!!result.value && result.isConfirmed) {
              sendPatientMail({
                id_registro: idRegister,
                email: result.value
              });
            }
          });
    }

    function handleDownloadRegister(registerId, filename) {
        setIsLoadingPDF(true);
        getPatientDocumentPDFReadableRegisteredByRegisterId(registerId)
            .then(({ data }) => {
                const blobContent = new Blob([data], { type: "application/pdf" });
                const generatedUrl =
                    window.URL && window.URL.createObjectURL
                        ? window.URL.createObjectURL(blobContent)
                        : window.webkitURL.createObjectURL(blobContent);

                const tempLink = document.createElement("a");
                tempLink.style.display = "none";
                tempLink.href = generatedUrl;
                tempLink.setAttribute("download", filename);

                if (typeof tempLink.download === "undefined") {
                    tempLink.setAttribute("target", "_blank");
                }

                document.body.appendChild(tempLink);
                tempLink.click();
                setIsLoadingPDF(false);

                setTimeout(function () {
                    document.body.removeChild(tempLink);
                    window.URL.revokeObjectURL(generatedUrl);
                }, 200);
            })
            .catch(() => {
                setIsLoadingPDF(false);
            });
    }

    function handlePreviewRegister(registerId) {
        setIsLoadingPDF(true);
        getPatientDocumentPDFReadableRegisteredByRegisterId(registerId)
            .then(({ data }) => {
                const blobContent = new Blob([data], { type: "application/pdf" });
                const generatedUrl =
                    window.URL && window.URL.createObjectURL
                        ? window.URL.createObjectURL(blobContent)
                        : window.webkitURL.createObjectURL(blobContent);

                window.open(generatedUrl, "", "width=800,height=600");
                setIsLoadingPDF(false);
            })
            .catch(() => {
                setIsLoadingPDF(false);
            });
    }

    const COLUMNS = [
        {
            title: "Registro",
            render: (data) =>
                data.nome_documento,
        },
        {
            title: "Paciente",
            render: (data) =>
                data.nome_paciente,
            searchable: true,
        },
        {
            title: "Dt. Criação",
            field: "data_nascimento",
            render: (data) => getFormatedDateUI(data.created_at),
        },
        {
            title: "Status de Assinatura",
            render: (data) => (
                <Chip
                    size="small"
                    label={data.is_assinado === "A" ? "Assinado" : "Não assinado"}
                    color={data.is_assinado === "A" ? "default" : "primary"}
                    style={data.is_assinado === "A" ? { borderColor: 'green', color: 'green' } : null}
                    variant="outlined"
                />
            ),
        },
        {
            title: "Validade da Assinatura",
            field: "validade_assinatura",
            render: (data) =>
                data.validade_assinatura ? getFormatedDateUI(data.validade_assinatura) : " - ",
        }
    ];

    const TABLE_ACTIONS = [
        rowData => ({
            icon: "assignment_turned_in_icon",
            tooltip: "Assinar digitalmente",
            onClick: (event, rowData) => {
                handleSignRegister(rowData.id_registro, rowData.email_paciente);
            },
            disabled: (signatureServiceStatus && userCertificateStatus ? (rowData.is_assinado === "A" ? true : false) : true)
        }),
        rowData => ({
            icon: "attach_email",
            tooltip: "Enviar por e-mail",
            onClick: (event, rowData) => {
                handleSendRegisterMail(rowData.id_registro, rowData.email_paciente);
            },
            disabled: rowData.is_assinado === "A" ? false : true
        }),
        {
            icon: "get_app",
            tooltip: "Download",
            onClick: (event, rowData) => {
                handleDownloadRegister(rowData.id_registro, rowData.name_pdf);
            },
        },
        {
            icon: "visibility",
            tooltip: "Pré-visualizar",
            onClick: (event, rowData) => {
                handlePreviewRegister(rowData.id_registro);
            },
        }
    ];

    return (
        <>
            <Content>
                <MaterialTable
                    style={{ margin: "0px 20px", marginBottom: "20px" }}
                    title={
                        <Box className={classes.titleBox}>
                            <Typography variant="h6" component="div">
                                Meus registros de documentos
                            </Typography>
                        </Box>
                    }
                    isLoading={isLoadding}
                    localization={pt_BR}
                    columns={COLUMNS}
                    actions={TABLE_ACTIONS}
                    data={registers}
                    options={{
                        search: true,
                        actionsColumnIndex: -1,
                    }}
                />
            </Content>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    newTermButton: {
        marginLeft: theme.spacing(4),
    },
    titleBox: {
        display: "flex",
        maxHeight: "30px",
    },
}));

const GreenButton = withStyles((theme) => ({
    root: {
        color: "#FFFFFF",
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
}))(Button);
