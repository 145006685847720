import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Icon,
  Typography,
  Button,
  Box,
} from "@material-ui/core";
import DropDownMenuActionPatient from "../../../components/DropDownMenuActionPatient";
import { green } from "@material-ui/core/colors";
import Content from "../../../components/Content";
import MaterialTable from "@material-table/core";
import pt_BR from "../../../locale/material-table-locale";
import {
  getPatients
} from "../../../services/endpoints/patients/endpoints";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { getAgeByDate, getFormatedDateUI } from "../../../helpers/dateHelpers";
import { getCpfFormated } from "../../../helpers/stringHelpers";
import { useUserData } from "../../../context/UserContext";

export default function ListPatients() {
  const classes = useStyles();
  const history = useHistory();

  const [patients, setPatients] = useState([]);
  const [isLoadding, setIsLoading] = useState(true);
 
  const { userData } = useUserData();

  useEffect(() => {
    const fetchPatients = async () => {
      await getPatients().then(({ data }) => {
        setPatients(data.list_paciente);
      });
      setIsLoading(false);
    };

    fetchPatients();
  }, [userData]);


  const COLUMNS = [
    {
      title: "Nome/Nome Social",
      render: (data) =>
        data.nome_social !== "" ? data.nome_social : data.nome,
      customFilterAndSearch: (term, rowData) =>
        rowData.nome.toUpperCase().indexOf(term.toUpperCase()) > -1,
    },
    {
      title: "CPF",
      field: "cpf",
      render: (data) => getCpfFormated(data.cpf),
      customFilterAndSearch: (term, rowData) =>
        rowData.cpf.toUpperCase().indexOf(term.toUpperCase()) > -1,
    },
    {
      title: "CNS",
      field: "cartao_nacional_de_saude",
      render: (data) =>
        data.cartao_nacional_de_saude
          ? data.cartao_nacional_de_saude
          : "Não informado",
      customFilterAndSearch: (term, rowData) =>
          rowData.cartao_nacional_de_saude.toUpperCase().indexOf(term.toUpperCase()) > -1,
    },
    {
      title: "Dt. Nascimento",
      field: "data_nascimento",
      render: (data) => getFormatedDateUI(data.data_nascimento),
      customFilterAndSearch: (term, rowData) =>
          rowData.data_nascimento.toUpperCase().indexOf(term.toUpperCase()) > -1,
    },
    {
      title: "Idade",
      field: "idade",
      render: (data) => getAgeByDate(data.data_nascimento),
      searchable: false,
    },
    {
      title: "Ações",
      tooltip: "Ações",
      searchable: false,
      width: "5%",
      headerStyle: {
        paddingRight: 0,
        textAlign: 'right',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: (data) => (
        <DropDownMenuActionPatient 
          patient={data}
          setIsLoading={setIsLoading}
          setPatients={setPatients}
        />
      ),
    },
  ];

  return (
    <>
      <Content>
        <MaterialTable
          style={{ margin: "0px 20px", marginBottom: "20px" }}
          title={
            <Box className={classes.titleBox}>
              <Typography variant="h6" component="div">
                Pacientes
              </Typography>
              <GreenButton
                className={classes.newTermButton}
                onClick={() => history.push("/patients/new")}
                variant="outlined"
                color="default"
                size="small"
                endIcon={<Icon>add</Icon>}
              >
                Criar
              </GreenButton>
            </Box>
          }
          isLoading={isLoadding}
          localization={pt_BR}
          columns={COLUMNS}
          data={patients}
          options={{
            search: true,
            rowStyle: { textAlign: "left" },
            actionsColumnIndex: -1,
          }}
        />
      </Content>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  newTermButton: {
    marginLeft: theme.spacing(4),
  },
  titleBox: {
    display: "flex",
    maxHeight: "30px",
  },
}));

const GreenButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
