import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  MenuItem,
  Typography,
  Grid,
  Paper,
  FormControl,
  FormHelperText,
  InputLabel,
  Input,
  Select,
  CircularProgress,
  Backdrop,
  Fab,
} from "@material-ui/core";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor";
import { editorConfiguration } from "../../../../helpers/ckeditorConfig";
import { useHistory, useParams } from "react-router-dom";
import Content from "../../../../components/Content";
import { makeStyles } from "@material-ui/core/styles";
import { defaultAlert } from "../../../../helpers/validation/alerts";
import { useForm, Controller } from "react-hook-form";
import {
  updateDocument,
  createDocument,
  getDocumentById,
} from "../../../../services/endpoints/documents/endpoints";
import { getAllDocumentTypes } from "../../../../services/endpoints/documentTypes/endpoints";
import { CreateDocumentSchema } from "../../../../helpers/validation/Schemas/DocumentSchema/schema";
import DocumentInputsModal from "../../../../components/DocumentInputsModal";
import TagsModal from "../../../../components/TagsModal";
import LabelIcon from "@material-ui/icons/Label";
import PostAddIcon from "@material-ui/icons/PostAdd";
import "../../../../locale/ckeditor-pt";
import "./style.css";

export default function CreateDocuments() {
  const history = useHistory();
  const { id } = useParams();

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [documentEditor, setDocumentEditor] = useState("");
  const [openDocumentsInputModal, setOpenDocumentsInputModal] = useState(false);
  const [openTagsModal, setOpenTagsModal] = useState(false);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      const fetchDocument = async () => {
        await getDocumentById(id)
          .then(({ data }) => {
            const { titulo, html, id_tipo_doc } = data.documento;
            setValue([{ titulo }, { html }, { id_tipo_doc }]);
            setDocumentEditor(html);
            setIsLoading(false);
            setIsEditing(true);
          })
          .catch(() => {
            history.push("/documentTypes/list");
            setIsLoading(false);
          });
      };
      fetchDocument();
    }
  }, [id, history]);

  useEffect(() => {
    setIsLoading(true);
    const fetchDocumentTypes = () => {
      getAllDocumentTypes()
        .then(({ data }) => {
          setDocumentTypes(data.list);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };
    fetchDocumentTypes();
  }, []);

  const {
    register,
    errors,
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    formState,
  } = useForm({
    validationSchema: CreateDocumentSchema,
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      titulo: "",
      html: "",
      id_tipo_doc: "",
    },
  });

  useEffect(() => {
    register({ name: "html" }, { required: true });
  }, []);

  async function handleForm(data) {
    setIsLoading(true);
    if (!isEditing) {
      handleCreateDocument(data);
    } else {
      handleUpdateDocument(id, data);
    }
  }

  function handleCreateDocument(data) {
    createDocument(data)
      .then(({ data }) => {
        if (data.status) {
          defaultAlert(
            "Documento criado com sucesso!",
            false,
            "Ir para lista de Documentos",
            "success",
            "Tudo certo!"
          ).then((result) => {
            if (result.value) {
              history.push("/documents/list");
            }
          });
          setIsLoading(false);
          reset();
          setDocumentEditor("");
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let { errors } = error.response.data;

          Object.keys(errors).map((erro) => {
            return setError(erro, "notMatch", errors[erro]);
          });
          setIsLoading(false);
          return false;
        }
      });
    setIsLoading(false);
  }

  function handleUpdateDocument(documentId, documentData) {
    updateDocument(documentId, documentData)
      .then(({ data }) => {
        if (data.status) {
          defaultAlert(
            "Documento atualizado com sucesso",
            false,
            "Ir para lista de Documentos",
            "success",
            "Tudo certo!"
          ).then((result) => {
            if (result.value) {
              history.push("/documents/list");
            }
          });
          setIsLoading(false);
          reset();
          setDocumentEditor("");
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let { errors } = error.response.data;

          Object.keys(errors).map((erro) => {
            return setError(erro, "notMatch", errors[erro]);
          });
          setIsLoading(false);
          return false;
        }
      });
    setIsLoading(false);
  }

  function handleDocumentEditor(event, editor) {
    setDocumentEditor(editor.getData());
    setValue("html", editor.getData());
  }

  function handleDocumentInputsModal() {
    setOpenDocumentsInputModal(!openDocumentsInputModal);
  }

  function handleTagsModal() {
    setOpenTagsModal(!openTagsModal);
  }

  const { dirty } = formState;

  return (
    <>
      <Content formIsDirty={dirty}>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box className={classes.paperStyle} component={Paper} elevation={2}>
          <Typography component="h1" variant="h6">
            {isEditing ? "Editar Documento" : "Criar Documento"}
          </Typography>
          <form
            method="POST"
            onSubmit={handleSubmit((data) => handleForm(data))}
          >
            <Grid item xs={12}>
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.titulo}
                  className={classes.flexItems}
                >
                  <InputLabel>Titulo do documento</InputLabel>
                  <Controller
                    as={<Input />}
                    rules={{ required: true }}
                    control={control}
                    name="titulo"
                  />
                  <FormHelperText>{errors?.titulo?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  error={!!errors.id_tipo_doc}
                  className={classes.flexItems}
                >
                  <InputLabel>Tipo de Documento</InputLabel>
                  <Controller
                    as={
                      <Select>
                        <MenuItem value="">Selecione um tipo</MenuItem>
                        {documentTypes.map((type, index) => (
                          <MenuItem
                            key={type.id_tipo_doc}
                            value={type.id_tipo_doc}
                          >
                            {type.nome_tipo}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    rules={{ required: true }}
                    control={control}
                    name="id_tipo_doc"
                  />
                  <FormHelperText>
                    {errors?.id_tipo_doc?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8} xl={8} className={classes.documentBox}>
                <FormControl fullWidth error={!!errors.html}>
                  <Typography
                    component="h1"
                    variant="h6"
                    className={classes.documentTitle}
                  >
                    Documento
                  </Typography>
                  <div id="toolbar-container"></div>
                  <div className="document-editor">
                    <div id="wordCount"></div>
                    <div className="document-editor__toolbar"></div>
                    <div className="document-editor__editable-container">
                      <div className="document-editor__editable">
                        <CKEditor
                          onReady={(editor) => {
                            const toolbarContainer = document.querySelector(
                              "#toolbar-container"
                            );
                            toolbarContainer.appendChild(
                              editor.ui.view.toolbar.element
                            );

                            window.editor = editor;
                            editor.editing.view.change((writer) => {
                              writer.setStyle(
                                "height",
                                "500px",
                                editor.editing.view.document.getRoot()
                              );
                            });

                            const wordCountPlugin = editor.plugins.get(
                              "WordCount"
                            );
                            const wordCountWrapper = document.getElementById(
                              "wordCount"
                            );

                            wordCountWrapper.appendChild(
                              wordCountPlugin.wordCountContainer
                            );
                          }}
                          editor={DecoupledEditor}
                          config={editorConfiguration}
                          data={documentEditor}
                          onChange={handleDocumentEditor}
                        />
                      </div>
                    </div>
                  </div>
                  <FormHelperText>
                    {documentEditor.trim() === "" ? errors?.html?.message : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Box className={classes.flexContainerButton}>
              <Button
                size="large"
                variant="contained"
                type="submit"
                color="primary"
              >
                {isEditing ? "Atualizar documento" : "Criar documento"}
              </Button>
            </Box>
          </form>
        </Box>
        <DocumentInputsModal
          open={openDocumentsInputModal}
          handleCloseModal={handleDocumentInputsModal}
          editorContent={documentEditor}
          setEditorContent={setDocumentEditor}
        />
        <TagsModal
          open={openTagsModal}
          handleCloseModal={handleTagsModal}
          editorContent={documentEditor}
          setEditorContent={setDocumentEditor}
        />
        <Fab
          onClick={handleTagsModal}
          variant="extended"
          size="medium"
          color="primary"
          className={classes.fab}
        >
          <LabelIcon />
          Tags
        </Fab>
        <Fab
          onClick={handleDocumentInputsModal}
          variant="extended"
          size="medium"
          color="primary"
          className={classes.fab2}
        >
          <PostAddIcon />
          Campo personalizado
        </Fab>
      </Content>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    flexGrow: 1,
    margin: "unset",
    width: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paperStyle: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  documentTitle: {
    marginBottom: theme.spacing(1),
  },
  documentBox: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.between("lg", "xl")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  flexContainer: {
    gap: "16px",
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  flexContainerTopButtons: {
    marginTop: theme.spacing(2),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "flex-end",
  },
  flexContainerButton: {
    marginTop: theme.spacing(5),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "center",
  },
  editorAndPreviewBox: {
    display: "flex",
    flexBasis: "50%",
    gap: "10px",
  },
  flexItems: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  fab: {
    position: "fixed",
    bottom: 90,
    right: 20,
  },
  fab2: {
    position: "fixed",
    bottom: 40,
    right: 20,
  },
}));
