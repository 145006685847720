import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { isAuthenticated, isAdmin } from "./services/endpoints/auth/endpoints";

import HomeLogin from "./pages/HomeLogin";
import TeleConsulta from "./pages/Teleconsultation";
import CreatePatients from "./pages/Patients/CreatePatients";
import EditPatients from "./pages/Patients/EditPatients";
import ListPatients from "./pages/Patients/ListPatients";
import CreateUsers from "./pages/Users/CreateUsers";
import MyProfile from "./pages/Users/MyProfile";
import ListUsers from "./pages/Users/ListUsers";
import Dashboard from "./pages/Dashboard";
import ListSchedules from "./pages/Scheduling/ListSchedules";
import CreateSchedule from "./pages/Scheduling/CreateSchedule";
import Registers from "./pages/Registers";
import NotFound404 from "./pages/ErrorPages/NotFound404";
import Unauthorized401 from "./pages/ErrorPages/Unauthorized401";
import MeetingRoom from "./pages/MeetingRoom";
import TermsMaintenanceUser from "./pages/Configuration/TermsMaintenance/User";
import TermsMaintenancePatient from "./pages/Configuration/TermsMaintenance/Patient";
import ExternalTerm from "./pages/PublicPages/ExternalTerm";
import PasswordChangePage from "./pages/PasswordChangePage";
import PatientDocuments from "./pages/PatientDocuments";
import SystemParameters from "./pages/Configuration/SystemParameters";
import CreateHealthUnit from "./pages/HealthUnits/CreateHealthUnits";
import ListHealthUnits from "./pages/HealthUnits/ListHealthUnits";
import ExternalPrivacyPolicy from "./pages/PublicPages/ExternalPrivacyPolicy";
import PrivacyPolicyMaintenance from "./pages/Configuration/PrivacyPolicyMaintenance";
import DocumentTypeList from "./pages/Configuration/Documents/ListDocumentTypes";
import ListDocuments from "./pages/Configuration/Documents/ListDocuments";
import CreateDocumentType from "./pages/Configuration/Documents/CreateDocumentTypes";
import CreateDocuments from "./pages/Configuration/Documents/CreateDocuments";
import CreateProcedure from "./pages/Configuration/Procedures/CreateProcedure";
import ListProcedures from "./pages/Configuration/Procedures/ListProcedures";
import ListSettingSchedules from "./pages/SettingSchedule/ListSettingSchedules";
import CreateSettingSchedule from "./pages/SettingSchedule/CreateSettingSchedule";
import SurveyManagement from "./pages/SurveyManagement";
import MailParameters from "./pages/Configuration/Communication/MailParameters";
import SmsParameters from "./pages/Configuration/Communication/SmsParameters";
import WhatsParameters from "./pages/Configuration/Communication/WhatsParameters";
import ProductionReport from "./pages/Report/ProductionReport";

export default function Routes() {
  function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated() ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }

  function AdminRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAdmin() ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/unauthorized401",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }

  function UserRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          !isAdmin() ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/unauthorized401",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          key="login"
          path="/"
          component={() =>
            isAuthenticated() ? (
              <Redirect
                to={{
                  pathname: "/dashboard",
                }}
              />
            ) : (
              <HomeLogin />
            )
          }
        />
        <PrivateRoute key="dashboard" exact path="/dashboard">
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute key="new-patient" exact path="/patients/new">
          <CreatePatients />
        </PrivateRoute>
        <PrivateRoute key="edit-patient" exact path="/patients/edit/:id">
          <EditPatients />
        </PrivateRoute>
        <PrivateRoute exact path="/patients/list">
          <ListPatients />
        </PrivateRoute>
        <PrivateRoute key="new-user" exact path="/users/new">
          <AdminRoute>
            <CreateUsers />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute key="edit-user" exact path="/users/edit/:id">
          <AdminRoute>
            <CreateUsers />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute key="list-user" exact path="/users/list">
          <AdminRoute>
            <ListUsers />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute key="edit-profile" exact path="/profile">
          <MyProfile />
        </PrivateRoute>
        <PrivateRoute exact path="/teleconsult/room/:id">
          <UserRoute>
            <TeleConsulta />
          </UserRoute>
        </PrivateRoute>
        <PrivateRoute key="new-schedule" exact path="/scheduling/new">
          <UserRoute>
            <CreateSchedule />
          </UserRoute>
        </PrivateRoute>
        <PrivateRoute
          key="new-schedule-custom"
          exact
          path="/scheduling/new/:professional/:date/:time"
        >
          <UserRoute>
            <CreateSchedule />
          </UserRoute>
        </PrivateRoute>
        <PrivateRoute
          key="embed-schedule-custom"
          exact
          path="/scheduling/embed/:professional/:date/:time"
        >
          <UserRoute>
            <CreateSchedule />
          </UserRoute>
        </PrivateRoute>
        <PrivateRoute key="edit-schedule" exact path="/scheduling/edit/:id">
          <UserRoute>
            <CreateSchedule />
          </UserRoute>
        </PrivateRoute>
        <PrivateRoute
          key="new-schedule-by-patient-id"
          exact
          path="/scheduling/new/:id"
        >
          <UserRoute>
            <CreateSchedule />
          </UserRoute>
        </PrivateRoute>
        <PrivateRoute exact path="/scheduling/list">
          <UserRoute>
            <ListSchedules />
          </UserRoute>
        </PrivateRoute>
        <PrivateRoute exact path="/registers/list">
          <UserRoute>
            <Registers />
          </UserRoute>
        </PrivateRoute>
        <PrivateRoute key="new-healthUnit" exact path="/healthUnit/new/">
          <AdminRoute>
            <CreateHealthUnit />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute key="edit-healthUnit" exact path="/healthUnit/edit/:id">
          <AdminRoute>
            <CreateHealthUnit />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute key="list-healthUnit" exact path="/healthUnit/list">
          <AdminRoute>
            <ListHealthUnits />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute key="terms-user" exact path="/configuration/terms/user">
          <AdminRoute>
            <TermsMaintenanceUser />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute
          key="terms-patient"
          exact
          path="/configuration/terms/patient"
        >
          <AdminRoute>
            <TermsMaintenancePatient />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute
          key="adm-privacy-policy-maintenance"
          exact
          path="/configuration/privacyPolicy"
        >
          <AdminRoute>
            <PrivacyPolicyMaintenance />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute
          key="survey-managment"
          exact
          path="/surveyManagement"
        >
         <SurveyManagement />
        </PrivateRoute>
        <PrivateRoute
          key="production-report"
          exact
          path="/report/productionReport"
        >
           <ProductionReport />
        </PrivateRoute>
        <PrivateRoute
          key="system-parameters"
          exact
          path="/configuration/systemParameters"
        >
          <AdminRoute>
            <SystemParameters />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute
          key="mail-parameters"
          exact
          path="/configuration/communication/mail"
        >
          <AdminRoute>
            <MailParameters />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute
          key="sms-parameters"
          exact
          path="/configuration/communication/sms"
        >
          <AdminRoute>
            <SmsParameters />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute
          key="whatsapp-parameters"
          exact
          path="/configuration/communication/whatsapp"
        >
          <AdminRoute>
            <WhatsParameters />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute key="document-list" exact path="/documents/list">
          <AdminRoute>
            <ListDocuments />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute key="document-new" exact path="/documents/new">
          <AdminRoute>
            <CreateDocuments />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute key="document-edit" exact path="/documents/edit/:id">
          <AdminRoute>
            <CreateDocuments />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute
          key="document-types-list"
          exact
          path="/documentTypes/list"
        >
          <AdminRoute>
            <DocumentTypeList />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute key="document-types-new" exact path="/documentTypes/new">
          <AdminRoute>
            <CreateDocumentType />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute
          key="document-types-edit"
          exact
          path="/documentTypes/edit/:id"
        >
          <AdminRoute>
            <CreateDocumentType />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute key="procedure-list" exact path="/procedures/list">
          <AdminRoute>
            <ListProcedures />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute key="procedure-new" exact path="/procedures/new">
          <AdminRoute>
            <CreateProcedure />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute key="procedure-edit" exact path="/procedures/edit/:id">
          <AdminRoute>
            <CreateProcedure />
          </AdminRoute>
        </PrivateRoute>
        <PrivateRoute key="list-settings-schedules" exact path="/schedule/list">
          <UserRoute>
            <ListSettingSchedules />
          </UserRoute>
        </PrivateRoute>
        <PrivateRoute key="setting-schedule-new" exact path="/schedule/new/">
          <UserRoute>
            <CreateSettingSchedule />
          </UserRoute>
        </PrivateRoute>
        <PrivateRoute
          key="setting-schedule-edit"
          exact
          path="/schedule/edit/:id"
        >
          <UserRoute>
            <CreateSettingSchedule />
          </UserRoute>
        </PrivateRoute>
        <Route exact key="recover-pass" path="/recoverPass/:id">
          <PasswordChangePage />
        </Route>
        <Route exact key="patient-documents" path="/patientDocuments/:token">
          <PatientDocuments />
        </Route>
        <Route exact key="public-terms" path="/terms">
          <ExternalTerm />
        </Route>
        <Route exact key="public-privacy-policy" path="/privacyPolicy">
          <ExternalPrivacyPolicy />
        </Route>
        <Route exact key="meeting-room" path="/meetingRoom/:id">
          <MeetingRoom />
        </Route>
        <Route exact key="error-401" path="/unauthorized401">
          <Unauthorized401 />
        </Route>
        <Route path="*">
          <NotFound404 />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
