import React, { createContext, useState, useContext, useEffect } from "react";
import { getLocalUserData } from "../services/endpoints/auth/endpoints";

const UserContext = createContext();

export default function UserProvider({ children }) {
  const [userData, setUserData] = useState({});
  const localUserData = getLocalUserData();

  useEffect(() => {
    if (JSON.stringify(userData) !== JSON.stringify(localUserData)) {
      setUserData(localUserData);
    }
  }, [userData, localUserData]);

  return (
    <UserContext.Provider value={{ userData, setUserData }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUserData() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserData deve ser usado com um UserProvider");
  }
  const { userData, setUserData } = context;
  return { userData, setUserData };
}
