import React from "react";
import {
  Button,
  InputLabel,
  Dialog,
  Input,
  Box,
  FormControl,
  FormLabel,
  Checkbox,
  FormGroup,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { InputBuilderSchema } from "../../helpers/validation/Schemas/InputBuilderSchema/schema";
import { makeStyles } from "@material-ui/core/styles";

export default function DocumentInputsModal(props) {
  const classes = useStyles();

  const { errors, handleSubmit, control } = useForm({
    validationSchema: InputBuilderSchema,
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      description: "",
      isRequired: false,
    },
  });

  function handleBuilderForm(data) {
    const referenceId = data.description
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s+/g, "_")
      .trim()
      .toLowerCase();

    const inputMountedText = `{${data.description}:${referenceId}${
      data.isRequired ? ":requerido" : ""
    }}`;

    const inputWithContent =
      props.editorContent +
      "\n" +
      `${data.description}: ` +
      "<span class='marker'>" +
      `${inputMountedText}` +
      "</span>" +
      "\n";

    props.setEditorContent(inputWithContent);
    props.handleCloseModal();
  }

  return (
    <form>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={props.open}
        onClose={props.handleCloseModal}
      >
        <DialogTitle>Criador de campos</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para criar um campo de forma automatizada para o documento, preencha
            os campos abaixo:
          </DialogContentText>
          <Box className={classes.flexContainer}>
            <FormControl
              error={!!errors.description}
              className={classes.flexItems}
            >
              <InputLabel>Descrição do campo</InputLabel>
              <Controller
                as={<Input />}
                rules={{ required: true }}
                control={control}
                name="description"
              />
              <FormHelperText>{errors?.description?.message}</FormHelperText>
            </FormControl>
          </Box>
          <Box className={classes.flexContainer}>
            <FormControl
              error={!!errors.isRequired}
              className={classes.flexItems}
            >
              <FormLabel component="legend">
                É obrigatório o preenchimento?
              </FormLabel>
              <FormGroup aria-label="position" row>
                <Controller
                  name="isRequired"
                  as={<Checkbox color="primary" />}
                  control={control}
                  type="checkbox"
                />
              </FormGroup>
              <FormHelperText>{errors?.isRequired?.message}</FormHelperText>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleCloseModal} color="default">
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit((data) => handleBuilderForm(data))}
            color="primary"
          >
            Adicionar campo
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  flexItems: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2),
    },
    width: "100%",
  },
}));
