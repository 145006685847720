import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from "@material-ui/lab";
import {
  Paper,
  CircularProgress,
  Typography,
  Box,
  Button,
  Backdrop,
  Grid,
} from "@material-ui/core";
import FormatLineSpacingIcon from "@material-ui/icons/FormatLineSpacing";
import { getHistoryByPatientId } from "../../services/endpoints/patientHistory/endpoints";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import { getFormatedDateTimeUI } from "../../helpers/dateHelpers";
import { getPatientDocumentPDFReadableRegisteredByRegisterId } from "../../services/endpoints/patientHistory/endpoints";
import noDataImage from "../../assets/images/no_data_history.png";

const videoDomain = process.env.REACT_APP_VIDEOIFRAME_DOMAIN;

const HistoryTimelinePatient = ({ patientId, patientName }) => {
  const classes = useStyles();
  const [patientHistory, setPatientHistory] = useState([]);
  const [isLoadingPDF, setIsLoadingPDF] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const getAllHistoryByPatientId = () => {
      getHistoryByPatientId(patientId)
        .then(({ data }) => {
          setPatientHistory(data.list);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };
    getAllHistoryByPatientId();
  }, [patientId]);

  function handlePDF(registerId, isDownload, filename) {
    setIsLoadingPDF(true);
    getPatientDocumentPDFReadableRegisteredByRegisterId(registerId)
      .then(({ data }) => {
        const blobContent = new Blob([data], { type: "application/pdf" });
        const generatedUrl =
          window.URL && window.URL.createObjectURL
            ? window.URL.createObjectURL(blobContent)
            : window.webkitURL.createObjectURL(blobContent);

        if (isDownload) {
          const tempLink = document.createElement("a");
          tempLink.style.display = "none";
          tempLink.href = generatedUrl;
          tempLink.setAttribute("download", filename);

          if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
          }

          document.body.appendChild(tempLink);
          tempLink.click();
          setIsLoadingPDF(false);

          setTimeout(function () {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(generatedUrl);
          }, 200);
        } else {
          window.open(generatedUrl, "", "width=800,height=600");
          setIsLoadingPDF(false);
        }
      })
      .catch(() => {
        setIsLoadingPDF(false);
      });
  }

  function handleRevertSmsList() {
    var newPatientHistory = patientHistory;
    setPatientHistory(reverseArray([...newPatientHistory]));
  }

  const reverseArray = (array) => {
    const revArray = array.reverse();
    return [...revArray];
  };

  return (
    <>
      {isLoading ? (
        <Box className={classes.loadingBox}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Box className={classes.timelineBox}>
          <Backdrop className={classes.backdrop} open={isLoadingPDF}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid container justify="flex-end">
            {patientHistory && patientHistory.length > 0 ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleRevertSmsList()}
              >
                <FormatLineSpacingIcon />
              </Button>
            ) : (
              ""
            )}
          </Grid>
          <Timeline className={classes.timelineComponent} align="left">
            {patientHistory && patientHistory.length > 0 ? (
              patientHistory.map((history) => (
                <TimelineItem
                  key={history.id_registro}
                  className={classes.item}
                >
                  <TimelineOppositeContent style={{ flex: 0, padding: 0 }} />
                  <TimelineSeparator>
                    <TimelineDot color="primary">
                      <CreateNewFolderIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>
                      <Typography variant="h6" component="h1">
                        {history.nome_documento}({history.tipo_de_documento})
                      </Typography>
                      <Typography variant="button" component="p">
                        Profissional: {`${history.prestador}`}
                      </Typography>
                      {!!videoDomain ? 
                        <Typography variant="caption" component="p">
                          Procedimento: {history.procedimento_realizado}
                        </Typography> : null}
                      <Typography
                        variant="body2"
                        component="p"
                        color="textSecondary"
                      >
                        Data de criação:{" "}
                        {getFormatedDateTimeUI(history.created_at)}
                      </Typography>
                      <Box className={classes.boxPDFButtons}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handlePDF(history.id_registro, false)}
                        >
                          Visualizar
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            handlePDF(
                              history.id_registro,
                              true,
                              history.name_pdf
                            )
                          }
                        >
                          Baixar
                        </Button>
                      </Box>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              ))
            ) : (
              <Box className={classes.loadingBox}>
                <img
                  src={noDataImage}
                  className={classes.imageNoData}
                  alt="Imagem que demonstra que o paciente não possui histórico de atendimento"
                />
                <Typography variant="h6" component="h6">
                  Paciente sem histórico para exibição!
                </Typography>
              </Box>
            )}
          </Timeline>
        </Box>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  loadingBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  timelineBox: {
    height: "100%",
    overflow: "overlay",
  },
  imageNoData: {
    width: 150,
  },
  boxPDFButtons: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    margin: theme.spacing(2, 0),
  },
  item: {
    width: "50%",
  },
}));

export default HistoryTimelinePatient;
