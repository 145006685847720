import api from "../../api";

export const recoverPassword = async (params) => {
  return api.post("recovery_pass", params);
};

export const recoverLoginUser = async (params) => {
  return api.post("recovery_user", params);
};

export const verifyRecoverToken = async (tokenID) => {
  return api.get(`check_token_recovery/${tokenID}`);
};

export const setNewPassword = async (tokenId, params) => {
  return api.put(`forgot_pass/${tokenId}`, params);
};
