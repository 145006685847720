import React, { useEffect } from "react";
import { saveCredentialsVidaas } from "../../services/endpoints/profile/endpoint";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { defaultAlert } from "../../helpers/validation/alerts";

var isFirst = true;

const IframeVidasDialog = ({ open, close, url, setUserCertificateStatus }) => {

  function handleClose() {
    close();
  }

  useEffect(() => {
    window.addEventListener("message", (event) => handleSaveCredentials(event), false);
    return () => {
      window.removeEventListener("message", (event) => handleSaveCredentials(event), false);
    };
  });

  async function handleSaveCredentials(event) {
    if(event.origin !== process.env.REACT_APP_HOST_ASS_SIMPLES){
      return;
    }
    if (
      event.data.hasOwnProperty("name") &&
      event.data.hasOwnProperty("cpf") &&
      event.data.hasOwnProperty("password") && 
      isFirst
    ) {
      isFirst = false;
      await saveCredentialsVidaas({ password: event.data.password })
        .then(({ data }) => {
          if (data.status) {
            setUserCertificateStatus(true);
            defaultAlert(
              "Certificado importado Sucesso! Agora você já pode assinar documentos e registros de pacientes.",
              false,
              "OK",
              "success",
              "Tudo certo!"
            ).then((result) => {
              if (result.value) {
                close();
              }
            });
          }
        })
        .catch((error) => {});
    }
  }

  return (
    <>
      <Dialog
        scroll="paper"
        open={open}
        fullWidth={true}
        maxWidth="sm"
        style={{ zIndex: 1 }}
        aria-labelledby="Autorizar certificado Vidaas"
        aria-describedby="Caixa de dialogo para Autorização o certificado Vidaas"
      >
        <DialogTitle id="alert-dialog-title">
          Autorizar certificado Vidaas
        </DialogTitle>
        <DialogContent dividers={true}>
          <iframe
            src={url}
            width="550"
            height="550"
          ></iframe>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default IframeVidasDialog;
