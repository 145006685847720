import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Grid, Typography, Avatar } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";

export default function DashboardItem({ title, value, icon }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              {title || "Carregando..."}
            </Typography>
            <Typography variant="h6">{value || "Carregando..."}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <Icon className={classes.icon}>{icon}</Icon>
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    flexBasis: "100%",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 38,
    width: 38,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
