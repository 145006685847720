import React, { useState } from "react";
import { importCertificate } from "../../services/endpoints/profile/endpoint";
import { useForm, Controller } from "react-hook-form";
import { ImportCertificateSchema } from "../../helpers/validation/Schemas/ImportCertificateSchema/schema";
import { defaultAlert } from "../../helpers/validation/alerts";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Box,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const ImportCertificateDialog = ({
  open,
  close,
  setUserCertificateStatus
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const {
    errors,
    handleSubmit,
    control,
  } = useForm({
    validationSchema: ImportCertificateSchema,
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      certificado: "",
      pin: ""
    },
  });

  function handleClose() {
    close();
  }

  async function handleForm(data) {
    setIsLoading(true);
    let formData = new FormData();
    var certificate = document.querySelector('#certificate');
    formData.append('certificado', certificate.files[0]);
    formData.append('pin', data.pin);
    await importCertificate(formData)
      .then(({ data }) => {
        if (data.status) {
          setUserCertificateStatus(true);
          defaultAlert(
            "Certificado importado Sucesso! Agora você já pode assinar documentos e registros de pacientes.",
            false,
            "OK",
            "success",
            "Tudo certo!"
          ).then((result) => {
            if (result.value) {

              close();
            }
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        return false;
      });
  }
  return (
    <>
      <Dialog
        scroll="paper"
        open={open}
        fullWidth={true}
        maxWidth="sm"
        style={{zIndex:1}}
        aria-labelledby="Importar certificado digital"
        aria-describedby="Caixa de dialogo para importar certificado digital"
      >
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <form
          method="POST"
          encType="multipart/form-data"
          onSubmit={handleSubmit((data) => handleForm(data))}
        >
          <DialogTitle id="alert-dialog-title">
            Importar certificado digital
        </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText>
              Faça upload do seu certificado digital A1 no formado .p12 ou .pfx
          </DialogContentText>
            <Box className={classes.flexContainer}>
              <FormControl
                error={!!errors.certificado}
                className={classes.flexItems}
              >
                <InputLabel>Certificado A1</InputLabel>
                <Controller
                  as={<Input />}
                  id="certificate"
                  type="file"
                  accept=".p12, .pfx"
                  rules={{ required: true }}
                  control={control}
                  name="certificado"
                />
                <FormHelperText>{errors?.certificado?.message}</FormHelperText>
              </FormControl>
            </Box>
            <Box className={classes.flexContainer}>
              <FormControl
                error={!!errors.pin}
                className={classes.flexItems}
              >
                <InputLabel>Pin do Certificado</InputLabel>
                <Controller
                  as={<Input />}
                  type="password"
                  autoComplete="new-password"
                  rules={{ required: true }}
                  control={control}
                  name="pin"
                />
                <FormHelperText>{errors?.pin?.message}</FormHelperText>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              size="large"
              variant="contained"
              type="submit"
              color="primary"
            >
              Importar
          </Button>
            <Button onClick={handleClose} color="default">
              Fechar
          </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  gridRoot: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paperStyle: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  flexContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  flexContainerButton: {
    marginTop: theme.spacing(5),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "flex-start",
    width: "100%",
    gap: "10px",
    margin: theme.spacing(2, 0),
  },
  flexItems: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2),
    },
    width: "100%",
  },
}));
export default ImportCertificateDialog;
