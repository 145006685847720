import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Collapse,
} from "@material-ui/core";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";
import Forum from "@material-ui/icons/Forum";
import Mail from "@material-ui/icons/Mail";
import Sms from "@material-ui/icons/Sms";
import WhatsApp from "@material-ui/icons/WhatsApp";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ArticleIcon from '@material-ui/icons/Description';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LockIcon from "@material-ui/icons/EnhancedEncryption";
import GroupIcon from "@material-ui/icons/Group";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EventNoteIcon from "@material-ui/icons/EventNote";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import Description from "@material-ui/icons/Description";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import TodayIcon from "@material-ui/icons/Today";
import PermDataSettingIcon from "@material-ui/icons/PermDataSetting";
import SubjectIcon from "@material-ui/icons/Subject";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import FolderIcon from "@material-ui/icons/Folder";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import DescriptionIcon from "@material-ui/icons/Description";
import LineStyleIcon from "@material-ui/icons/LineStyle";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import BallotIcon from "@material-ui/icons/Ballot";
import { useUserData } from "../../context/UserContext";
import { useLocation } from "react-router-dom";

export default function DrawerMenu({ drawer, handleDrawer }) {
  const classes = useStyles();
  const location = useLocation();
  const { userData } = useUserData();
  const [subMenusState, setSubMenuState] = useState({
    termsItemOpen: false,
    configurationNestedItemOpen: false,
    patientsNestedItemsOpen: false,
    usersNestedItemsOpen: false,
    schedulingNestedItemsOpen: false,
    comunicationNestedItemOpen: false,
    healthUnitsNestedItemOpen: false,
    documentsNestedItemOpen: false,
    termsNestedItemOpen: false,
  });

  function handleSubMenuState(subMenu, index) {
    setSubMenuState((subMenusState) => ({
      ...subMenusState,
      [subMenu]: !subMenusState[subMenu],
    }));
  }

  function isSelectedItem(item) {
    if (location.pathname.match(item) !== null) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <Drawer
      anchor="left"
      variant="temporary"
      open={drawer}
      onClose={handleDrawer}
    >
      <Box className={classes.drawer}>
        <IconButton
          edge="start"
          onClick={handleDrawer}
          color="secondary"
          aria-label="menu"
        >
          <ArrowBackIcon />
        </IconButton>
        <img
          className={classes.logoCompany}
          src={logo}
          alt="Logotipo OM30 - Tecnologia movida por pessoas"
        />
      </Box>
      <List className={classes.list} dense>
        <ListItem
          selected={location.pathname === "/dashboard"}
          button
          component={Link}
          to="/"
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem>
        <ListItem
          className={!userData.menu?.patients ? classes.invisible : ""}
          button
          onClick={() => handleSubMenuState("patientsNestedItemsOpen")}
          selected={isSelectedItem("patients")}
        >
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary={"Pacientes"} />
          {subMenusState["patientsNestedItemsOpen"] ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </ListItem>
        <Collapse
          in={subMenusState["patientsNestedItemsOpen"]}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding dense>
            <ListItem
              button
              selected={location.pathname === "/patients/new"}
              component={Link}
              to="/patients/new"
              className={classes.nested}
            >
              <ListItemIcon>
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText primary="Novo Paciente" />
            </ListItem>
            <ListItem
              button
              selected={location.pathname === "/patients/list"}
              component={Link}
              to="/patients/list"
              className={classes.nested}
            >
              <ListItemIcon>
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText primary="Listar pacientes" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          className={
            !userData.menu?.schedule
              ? classes.invisible
              : userData.is_healthcare_professional
              ? classes.invisible
              : ""
          }
          button
          selected={isSelectedItem("schedule")}
          onClick={() => handleSubMenuState("scheduleSettingsNestedItemsOpen")}
        >
          <ListItemIcon>
            <PermContactCalendarIcon />
          </ListItemIcon>
          <ListItemText primary={"Agendas"} />
          {subMenusState["scheduleSettingsNestedItemsOpen"] ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </ListItem>
        <Collapse
          in={subMenusState["scheduleSettingsNestedItemsOpen"]}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding dense>
            <ListItem
              button
              component={Link}
              selected={location.pathname === "/schedule/new"}
              to="/schedule/new"
              className={classes.nested}
            >
              <ListItemIcon>
                <EventAvailableIcon />
              </ListItemIcon>
              <ListItemText primary="Nova agenda" />
            </ListItem>
            <ListItem
              button
              component={Link}
              selected={location.pathname === "/schedule/list"}
              to="/schedule/list"
              className={classes.nested}
            >
              <ListItemIcon>
                <EventNoteIcon />
              </ListItemIcon>
              <ListItemText primary="Listar agendas" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          className={!userData.menu?.scheduling ? classes.invisible : ""}
          button
          selected={isSelectedItem("scheduling")}
          onClick={() => handleSubMenuState("schedulingNestedItemsOpen")}
        >
          <ListItemIcon>
            <TodayIcon />
          </ListItemIcon>
          <ListItemText primary={"Agendamento"} />
          {subMenusState["schedulingNestedItemsOpen"] ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </ListItem>
        <Collapse
          in={subMenusState["schedulingNestedItemsOpen"]}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding dense>
            <ListItem
              button
              component={Link}
              selected={location.pathname === "/scheduling/new"}
              to="/scheduling/new"
              className={classes.nested}
            >
              <ListItemIcon>
                <EventAvailableIcon />
              </ListItemIcon>
              <ListItemText primary="Novo Agendamento" />
            </ListItem>
            <ListItem
              button
              component={Link}
              selected={location.pathname === "/scheduling/list"}
              to="/scheduling/list"
              className={classes.nested}
            >
              <ListItemIcon>
                <EventNoteIcon />
              </ListItemIcon>
              <ListItemText primary="Listar Agendamentos" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          className={
            !userData.is_healthcare_professional ? classes.invisible : ""
          }
          selected={location.pathname === "/registers/list"}
          button
          component={Link}
          to="/registers/list"
        >
          <ListItemIcon>
            <Description />
          </ListItemIcon>
          <ListItemText primary={"Registros de documentos"} />
        </ListItem>
        <ListItem
          className={!userData.menu?.user ? classes.invisible : ""}
          button
          selected={isSelectedItem("users")}
          onClick={() => handleSubMenuState("usersNestedItemsOpen")}
        >
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary={"Usuários"} />
          {subMenusState["usersNestedItemsOpen"] ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </ListItem>
        <Collapse
          in={subMenusState["usersNestedItemsOpen"]}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding dense>
            <ListItem
              button
              component={Link}
              selected={location.pathname === "/users/new"}
              to="/users/new"
              className={classes.nested}
            >
              <ListItemIcon>
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText primary="Novo Usuário" />
            </ListItem>
            <ListItem
              button
              component={Link}
              selected={location.pathname === "/users/list"}
              to="/users/list"
              className={classes.nested}
            >
              <ListItemIcon>
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText primary="Listar Usuários" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          className={
            !userData.menu?.surveyManagement
              ? classes.invisible
              : userData.profile === "1"
              ? ""
              : classes.invisible
          }
          button
          component={Link}
          to="/surveyManagement"
        >
          <ListItemIcon>
            <BallotIcon />
          </ListItemIcon>
          <ListItemText primary="Pesquisa de Satisfação" />
        </ListItem>
        <ListItem
          className={!userData.menu?.reports
            ? classes.invisible
            : userData.profile === "1" || userData.profile === "0"
            ? ""
            : classes.invisible}
          button
          selected={
            isSelectedItem("productionReport")
          }
          onClick={() => handleSubMenuState("reportsNestedItemOpen")}
        >
          <ListItemIcon>
            <ArticleIcon />
          </ListItemIcon>
          <ListItemText primary={"Relatórios"} />
          {subMenusState["reportsNestedItemOpen"] ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </ListItem>
        <Collapse
          in={subMenusState["reportsNestedItemOpen"]}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding dense>
            <ListItem
              button
              component={Link}
              to="/report/productionReport"
              className={classes.nested}
            >
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Relatório de produtividade" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          className={!userData.menu?.configs ? classes.invisible : ""}
          button
          selected={
            isSelectedItem("configuration") ||
            isSelectedItem("procedures") ||
            isSelectedItem("healthUnit") ||
            isSelectedItem("documents")
          }
          onClick={() => handleSubMenuState("configurationNestedItemOpen")}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={"Configurações"} />
          {subMenusState["configurationNestedItemOpen"] ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </ListItem>
        <Collapse
          in={subMenusState["configurationNestedItemOpen"]}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding dense>
            <ListItem
              button
              component={Link}
              to="/configuration/systemParameters"
              className={classes.nested}
            >
              <ListItemIcon>
                <PermDataSettingIcon />
              </ListItemIcon>
              <ListItemText primary="Parâmetros do sistema" />
            </ListItem>
          </List>
          <List component="div" disablePadding dense>
            <ListItem
              button
              component={Link}
              to="/procedures/list"
              className={classes.nested}
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Procedimentos" />
            </ListItem>
          </List>
          <List component="div" disablePadding dense>
            <ListItem
              button
              selected={isSelectedItem("healthUnit")}
              onClick={() => handleSubMenuState("healthUnitsNestedItemOpen")}
              className={classes.nested}
            >
              <ListItemIcon>
                <HomeWorkIcon />
              </ListItemIcon>
              <ListItemText primary="Unidades de Saúde" />
              {subMenusState["healthUnitsNestedItemOpen"] ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </ListItem>
          </List>
          <Collapse
            in={subMenusState["healthUnitsNestedItemOpen"]}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding dense>
              <ListItem
                button
                component={Link}
                selected={location.pathname === "/healthUnit/new"}
                to="/healthUnit/new"
                className={classes.nested}
              >
                <ListItemIcon>
                  <SubjectIcon />
                </ListItemIcon>
                <ListItemText primary="Nova Unidade" />
              </ListItem>
            </List>
            <List component="div" disablePadding dense>
              <ListItem
                button
                component={Link}
                selected={location.pathname === "/healthUnit/list"}
                to="/healthUnit/list"
                className={classes.nested}
              >
                <ListItemIcon>
                  <SubjectIcon />
                </ListItemIcon>
                <ListItemText primary="Listar Unidades" />
              </ListItem>
            </List>
          </Collapse>
          <List component="div" disablePadding dense>
            <ListItem
              button
              selected={isSelectedItem("terms")}
              onClick={() => handleSubMenuState("termsNestedItemOpen")}
              className={classes.nested}
            >
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="Termos de uso" />
              {subMenusState["termsNestedItemOpen"] ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </ListItem>
            <Collapse
              in={subMenusState["termsNestedItemOpen"]}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding dense>
                <ListItem
                  button
                  component={Link}
                  selected={location.pathname === "/configuration/terms/user"}
                  to="/configuration/terms/user"
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <PlaylistAddIcon />
                  </ListItemIcon>
                  <ListItemText primary="Termos do usuário" />
                </ListItem>
              </List>
              <List component="div" disablePadding dense>
                <ListItem
                  button
                  component={Link}
                  selected={
                    location.pathname === "/configuration/terms/patient"
                  }
                  to="/configuration/terms/patient"
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <PlaylistAddIcon />
                  </ListItemIcon>
                  <ListItemText primary="Termos do paciente" />
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              button
              component={Link}
              selected={location.pathname === "/configuration/privacyPolicy"}
              to="/configuration/privacyPolicy"
              className={classes.nested}
            >
              <ListItemIcon>
                <LockIcon />
              </ListItemIcon>
              <ListItemText primary="Política de privacidade" />
            </ListItem>
          </List>
          <List component="div" disablePadding dense>
            <ListItem
              button
              selected={isSelectedItem("document")}
              onClick={() => handleSubMenuState("documentsNestedItemOpen")}
              className={classes.nested}
            >
              <ListItemIcon>
                <FolderIcon />
              </ListItemIcon>
              <ListItemText primary="Documentos" />
              {subMenusState["documentsNestedItemOpen"] ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </ListItem>
            <Collapse
              in={subMenusState["documentsNestedItemOpen"]}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding dense>
                <ListItem
                  button
                  component={Link}
                  selected={location.pathname === "/documents/new"}
                  to="/documents/new"
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <LineStyleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Criar Documento" />
                </ListItem>
              </List>
              <List component="div" disablePadding dense>
                <ListItem
                  button
                  component={Link}
                  selected={location.pathname === "/documents/list"}
                  to="/documents/list"
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary="Listar documentos" />
                </ListItem>
              </List>
              <List component="div" disablePadding dense>
                <ListItem
                  button
                  component={Link}
                  selected={location.pathname === "/documentTypes/new"}
                  to="/documentTypes/new"
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <PlaylistAddIcon />
                  </ListItemIcon>
                  <ListItemText primary="Criar tipo de documento" />
                </ListItem>
              </List>
              <List component="div" disablePadding dense>
                <ListItem
                  button
                  component={Link}
                  selected={location.pathname === "/documentTypes/list"}
                  to="/documentTypes/list"
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <FormatListNumberedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Listar tipos de documentos" />
                </ListItem>
              </List>
            </Collapse>
            <List component="div" disablePadding dense>
              <ListItem
                button
                selected={isSelectedItem("communication")}
                onClick={() =>
                  handleSubMenuState("communicationNestedItemOpen")
                }
                className={classes.nested}
              >
                <ListItemIcon>
                  <Forum />
                </ListItemIcon>
                <ListItemText primary="Comunicação" />
                {subMenusState["communicationNestedItemOpen"] ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItem>
            </List>
            <Collapse
              in={subMenusState["communicationNestedItemOpen"]}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding dense>
                <ListItem
                  button
                  component={Link}
                  selected={
                    location.pathname === "/configuration/communication/mail"
                  }
                  to="/configuration/communication/mail"
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <Mail />
                  </ListItemIcon>
                  <ListItemText primary="E-mail" />
                </ListItem>
              </List>
              <List component="div" disablePadding dense>
                <ListItem
                  button
                  component={Link}
                  selected={
                    location.pathname === "/configuration/communication/sms"
                  }
                  to="/configuration/communication/sms"
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <Sms />
                  </ListItemIcon>
                  <ListItemText primary="SMS" />
                </ListItem>
              </List>
              <List component="div" disablePadding dense>
                <ListItem
                  button
                  component={Link}
                  selected={
                    location.pathname ===
                    "/configuration/communication/whatsapp"
                  }
                  to="/configuration/communication/whatsapp"
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <WhatsApp />
                  </ListItemIcon>
                  <ListItemText primary="WhatsApp" />
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Collapse>
      </List>
    </Drawer>
  );
}

const useStyles = makeStyles((theme) => ({
  drawer: {
    height: "64.3px",
    backgroundColor: theme.palette.primary.main,
    border: "none",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    position: "fixed",
    width: "300px",
    zIndex: 1000,
  },
  logoCompany: {
    width: "150px",
    marginTop: "-5px",
  },
  list: {
    width: 300,
    marginTop: "58px",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  subItemNested: {
    paddingLeft: theme.spacing(6),
  },
  invisible: {
    display: "none",
  },
}));
