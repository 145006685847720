import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import Content from "../../../components/Content";
import { defaultAlert } from "../../../helpers/validation/alerts";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { SystemParametersSchema } from "../../../helpers/validation/Schemas/SystemParametersSchema/schema";
import {
  getSystemParameters,
  updateSystemParameters,
} from "../../../services/endpoints/systemParameters/endpoints";
import imagePlaceholder from "../../../assets/images/placeholder.png";

export default function SystemParameters() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [clientImage, setClientImage] = useState("");
  const inputRef = useRef(null);

  const {
    register,
    errors,
    setError,
    handleSubmit,
    reset,
    control,
    setValue,
  } = useForm({
    validationSchema: SystemParametersSchema,
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      nome_global: " ",
      logo_b64: " ",
      link_call: " ",
      link_documentos: " ",
      link_forgot_pass: " ",
      msg_pesquisa_de_satisfacao: " ",
      max_tentativas_login: " ",
      tempo_de_sessao: " ",
      validade_senha: " ",
      hash_assinatura_simples: " ",
    },
  });

  useEffect(() => {
    setIsLoading(true);
    const handleFetchConfigsValues = async () => {
      await getSystemParameters()
        .then(({ data }) => {
          const {
            logo_b64,
          } = data.configuracoes;
          reset(data.configuracoes);
          setIsLoading(false);
          setClientImage(logo_b64);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };
    handleFetchConfigsValues();
    // eslint-disable-next-line
  }, []);

  async function handleForm(data) {
    setIsLoading(true);
    updateSystemParameters(data)
      .then(() => {
        setIsLoading(false);
        defaultAlert(
          "Parâmetros atualizados com sucesso!",
          true,
          "Entendi",
          "success"
        );
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let { errors } = error.response.data;
          Object.keys(errors).map((erro) => {
            return setError(erro, "notMatch", errors[erro]);
          });
        }
        setIsLoading(false);
      });
  }

  function handleClickImageChange() {
    inputRef.current.click();
  }

  function handleChangeImage(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = function () {
        setClientImage(reader.result);
        setValue("logo_b64", reader.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    } else {
      setClientImage("");
      setValue("logo_b64", "");
    }
  }

  return (
    <Content>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container className={classes.gridRoot}>
        <Box className={classes.paperStyle} component={Paper} elevation={2}>
          <Typography component="h1" variant="h6">
            Editar parâmetros do sistema
          </Typography>
          <form
            method="POST"
            onSubmit={handleSubmit((data) => handleForm(data))}
          >
            <Box className={classes.flexTopicSeparator}>
              <Typography component="p" variant="button">
                Informações da Instituição
              </Typography>
            </Box>
            <Box className={classes.flexImageContainer}>
              <Input
                style={{ display: "none" }}
                inputRef={inputRef}
                inputProps={{ accept: "image/*" }}
                type="file"
                onChange={handleChangeImage}
              />
              <FormControl
                className={classes.flexItemImage}
                error={!!errors.logo_b64}
              >
                <img
                  onClick={handleClickImageChange}
                  src={clientImage ? clientImage : imagePlaceholder}
                  className={classes.clientImage}
                  alt="Imagem da organização responsável"
                />
                <Controller
                  as={<Input style={{ display: "none" }} />}
                  control={control}
                  name="logo_b64"
                />
                <FormHelperText>{errors?.logo_b64?.message}</FormHelperText>
              </FormControl>
            </Box>
            <Box className={classes.flexContainer}>
              <FormControl
                error={!!errors.nome_global}
                className={classes.flexItems}
              >
                <InputLabel>Nome da Instituição</InputLabel>
                <Controller
                  as={<Input />}
                  rules={{ required: true }}
                  control={control}
                  name="nome_global"
                />
                <FormHelperText>{errors?.nome_global?.message}</FormHelperText>
              </FormControl>
            </Box>
            <Box className={classes.flexTopicSeparator}>
              <Typography component="p" variant="button">
                URL's do sistema
              </Typography>
            </Box>
            <Box className={classes.flexContainer}>
              <FormControl
                error={!!errors.link_call}
                className={classes.flexItems}
              >
                <InputLabel>URL Página de conferência</InputLabel>
                <Controller
                  as={<Input />}
                  rules={{ required: true }}
                  control={control}
                  name="link_call"
                />
                <FormHelperText>{errors?.link_call?.message}</FormHelperText>
              </FormControl>
              <FormControl
                error={!!errors.link_documentos}
                className={classes.flexItems}
              >
                <InputLabel>URL Página de documentos do paciente</InputLabel>
                <Controller
                  as={<Input />}
                  rules={{ required: true }}
                  control={control}
                  name="link_documentos"
                />
                <FormHelperText>
                  {errors?.link_documentos?.message}
                </FormHelperText>
              </FormControl>
              <FormControl
                error={!!errors.link_forgot_pass}
                className={classes.flexItems}
              >
                <InputLabel>URL Página de recuperação de senha</InputLabel>
                <Controller
                  as={<Input />}
                  rules={{ required: true }}
                  control={control}
                  name="link_forgot_pass"
                />
                <FormHelperText>
                  {errors?.link_forgot_pass?.message}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box className={classes.flexTopicSeparator}>
              <Typography component="p" variant="button">
                Pesquisa de satisfação
              </Typography>
            </Box>
            <Box className={classes.flexContainer}>
              <FormControl
                error={!!errors.msg_pesquisa_de_satisfacao}
                className={classes.flexItems}
              >
                <InputLabel>Mensagem Pesquisa de Satisfação</InputLabel>
                <Controller
                  as={<Input multiline />}
                  rules={{ required: true }}
                  control={control}
                  name="msg_pesquisa_de_satisfacao"
                />
                <FormHelperText>
                  {errors?.msg_pesquisa_de_satisfacao?.message}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box className={classes.flexTopicSeparator}>
              <Typography component="p" variant="button">
                Configurações de Autenticação
              </Typography>
            </Box>
            <Box className={classes.flexContainer}>
              <FormControl
                error={!!errors.max_tentativas_login}
                className={classes.flexItems}
              >
                <InputLabel>
                  Número máximo de tentativas consecutivas de login
                </InputLabel>
                <Controller
                  as={<Input />}
                  rules={{ required: true }}
                  control={control}
                  name="max_tentativas_login"
                />
                <FormHelperText>
                  {errors?.max_tentativas_login?.message}
                </FormHelperText>
              </FormControl>
              <FormControl
                error={!!errors.tempo_de_sessao}
                className={classes.flexItems}
              >
                <InputLabel>Tempo de sessão do usuário (minutos)</InputLabel>
                <Controller
                  as={<Input />}
                  rules={{ required: true }}
                  control={control}
                  name="tempo_de_sessao"
                />
                <FormHelperText>
                  {errors?.tempo_de_sessao?.message}
                </FormHelperText>
              </FormControl>
              <FormControl
                error={!!errors.validade_senha}
                className={classes.flexItems}
              >
                <InputLabel>Validade de senha (meses)</InputLabel>
                <Controller
                  as={<Input />}
                  rules={{ required: true }}
                  control={control}
                  name="validade_senha"
                />
                <FormHelperText>
                  {errors?.validade_senha?.message}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box className={classes.flexTopicSeparator}>
              <Typography component="p" variant="button">
                Configurações de Assinatura digital de documentos (Integração
                Assinatura Digital Simples)
              </Typography>
            </Box>
            <Box className={classes.flexContainer}>
              <FormControl
                error={!!errors.hash_assinatura_simples}
                className={classes.flexItems}
              >
                <InputLabel>Hash de autenticação</InputLabel>
                <Controller
                  as={<Input />}
                  rules={{ required: true }}
                  control={control}
                  name="hash_assinatura_simples"
                />
                <FormHelperText>
                  {errors?.hash_assinatura_simples?.message}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box className={classes.flexContainer}></Box>
            <Box className={classes.flexContainerButton}>
              <Button
                size="large"
                variant="contained"
                type="submit"
                color="primary"
              >
                Atualizar parâmetros
              </Button>
            </Box>
          </form>
        </Box>
      </Grid>
    </Content>
  );
}

const useStyles = makeStyles((theme) => ({
  gridRoot: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paperStyle: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  flexTopicSeparator: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-2),
  },
  clientImage: {
    borderRadius: "4px",
    height: "200px",
    width: "200px",
    objectFit: "contain",
    cursor: "pointer",
  },
  flexImageContainer: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  flexContainer: {
    marginTop: theme.spacing(1),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  flexContainerButton: {
    marginTop: theme.spacing(5),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "center",
  },
  flexItems: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
    },
    width: "100%",
  },
  flexItemImage: {
    alignItems: "center",
  },
  flexItem: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
      maxWidth: "33%",
    },
    width: "100%",
  },
}));
