import api from "../../api";

export const checkToken = async (token) => {
  return api.get(`public/documento/check_token/${token}`);
};

export const getPatientDocuments = async (params) => {
  return api.post("public/documento", params);
};

export const readPatientDocuments = async (params) => {
  return api.post("public/documento/read", params, {
    responseType: "arraybuffer",
    headers: {
      "Content-Type": "application/json",
    },
  });
};