import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  CircularProgress,
  Backdrop,
  Select,
  MenuItem,
  Fab,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from "@material-ui/core";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor";
import { editorConfiguration } from "../../../../helpers/ckeditorConfig";
import { useHistory, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Content from "../../../../components/Content";
import { makeStyles } from "@material-ui/core/styles";
import { defaultAlert } from "../../../../helpers/validation/alerts";
import { CreateDocumentTypeSchema } from "../../../../helpers/validation/Schemas/DocumentTypeSchema/schema";
import {
  createDocumentType,
  updateDocumenType,
  getDocumentTypeById,
  getFooterPreview,
} from "../../../../services/endpoints/documentTypes/endpoints";
import { getSignatureTypes } from "../../../../services/endpoints/enums/endpoints";
import TagsSignModal from "../../../../components/TagsSignModal";
import LabelIcon from "@material-ui/icons/Label";
import PostAddIcon from "@material-ui/icons/PostAdd";
import "../../../../locale/ckeditor-pt";
import "./style.css";
export default function CreateDocumentTypes() {
  const history = useHistory();
  const { id } = useParams();

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showFooterEditor, setShowFooterEditor] = useState(false);
  const [includeFooter, setIncludeFooter] = useState("");
  const [tpSignatureTypesOptions, setTpSignatureTypesOptions] = useState([]);
  const [documentEditor, setDocumentEditor] = useState("");
  const [openTagsModal, setOpenTagsModal] = useState(false);

  const {
    register,
    errors,
    handleSubmit,
    setError,
    reset,
    watch,
    control,
    setValue,
    formState,
  } = useForm({
    validationSchema: CreateDocumentTypeSchema,
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      nome_tipo: "",
      descricao: "",
      tipo_de_assinatura: "",
      incluir_rodape: "",
      pertence_prontuario: false,
      rodape_html: "",
    },
  });

  useEffect(() => {
    register({ name: "rodape_html" }, { required: false });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const populateTpSignatureTyperOptions = async () => {
      await getSignatureTypes().then(({ data }) => {
        setTpSignatureTypesOptions(data.list);
        setIsLoading(false);
      });
      setIsLoading(false);
    };

    populateTpSignatureTyperOptions();
    setIsLoading(true);
    if (id) {
      setIsEditing(true);
      const fetchDocumentType = async () => {
        await getDocumentTypeById(id)
          .then(({ data }) => {
            const {
              nome_tipo,
              tipo_de_assinatura,
              descricao,
              incluir_rodape,
              pertence_prontuario,
              rodape_html,
            } = data.tipo_de_documento;
            setValue([
              { nome_tipo },
              { tipo_de_assinatura },
              { descricao },
              { incluir_rodape },
              { pertence_prontuario },
              { rodape_html },
            ]);
            setIncludeFooter(incluir_rodape);
            setDocumentEditor(rodape_html);
            setIsLoading(false);
          })
          .catch(() => {
            history.push("/documentTypes/list");
            setIsEditing(false);
          });
        setIsLoading(false);
      };
      fetchDocumentType();
    } // eslint-disable-next-line
  }, [id, history]);

  async function handleForm(data) {
    setIsLoading(true);
    if (!isEditing) {
      handleCreateDocumentType(data);
    } else {
      handleUpdateDocumentType(id, data);
    }
  }

  function handleCreateDocumentType(data) {
    data.pertence_prontuario = data.pertence_prontuario === "" ? true : false;
    createDocumentType(data)
      .then(({ data }) => {
        if (data.status) {
          defaultAlert(
            "Tipo de documento criado com sucesso!",
            false,
            "Ver lista de Tipos de Documentos",
            "success",
            "Tudo certo!"
          ).then((result) => {
            if (result.value) {
              history.push("/documentTypes/list");
            }
          });
          setIsLoading(false);
          reset();
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let { errors } = error.response.data;

          Object.keys(errors).map((erro) => {
            return setError(erro, "notMatch", errors[erro]);
          });
          setIsLoading(false);
          return false;
        }
      });
  }

  function handleUpdateDocumentType(documentTypeId, documentTypeForm) {
    updateDocumenType(documentTypeId, documentTypeForm)
      .then(({ data }) => {
        if (data.status) {
          defaultAlert(
            "Tipo de documento atualizado com sucesso!",
            false,
            "Ver lista de Tipos de Documentos",
            "success",
            "Tudo certo!"
          ).then((result) => {
            if (result.value) {
              history.push("/documentTypes/list");
            }
          });
          setIsLoading(false);
          reset();
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let { errors } = error.response.data;

          Object.keys(errors).map((erro) => {
            return setError(erro, "notMatch", errors[erro]);
          });
          setIsLoading(false);
          return false;
        }
        setIsLoading(false);
      });
  }

  const watchFooterEditor = watch("incluir_rodape");
  const { dirty } = formState;

  function handleDocumentEditor(event, editor) {
    setDocumentEditor(editor.getData());
    setValue("rodape_html", editor.getData());
  }

  function handleTagsModal() {
    setOpenTagsModal(!openTagsModal);
  }

  function handlePreviewFooter() {
    setIsLoading(true);
    var data = {};
    data["rodape_html"] = documentEditor;
    var formData = data;
    getFooterPreview(formData)
      .then(({ data }) => {
        const blobContent = new Blob([data], { type: "application/pdf" });
        const generatedUrl =
          window.URL && window.URL.createObjectURL
            ? window.URL.createObjectURL(blobContent)
            : window.webkitURL.createObjectURL(blobContent);

        window.open(generatedUrl, "", "width=800,height=600");
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <Content formIsDirty={dirty}>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box className={classes.paperStyle} component={Paper} elevation={2}>
          <Typography component="h1" variant="h6">
            {isEditing ? "Editar tipo de documento" : "Criar tipo de documento"}
          </Typography>
          <form
            method="POST"
            onSubmit={handleSubmit((data) => handleForm(data))}
          >
            <Grid item xs={12}>
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.nome_tipo}
                  className={classes.flexItems}
                >
                  <InputLabel>Tipo</InputLabel>
                  <Controller
                    as={<Input />}
                    rules={{ required: true }}
                    control={control}
                    name="nome_tipo"
                  />
                  <FormHelperText>{errors?.nome_tipo?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  error={!!errors.tipo_de_assinatura}
                  className={classes.flexItems}
                >
                  <InputLabel>Padrão assinatura digital</InputLabel>
                  <Controller
                    as={
                      <Select>
                        <MenuItem value="">Selecione</MenuItem>
                        {tpSignatureTypesOptions.map(
                          (singnatureType, index) => (
                            <MenuItem
                              key={singnatureType.value + index}
                              value={singnatureType.value}
                            >
                              {singnatureType.description}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    }
                    rules
                    control={control}
                    name="tipo_de_assinatura"
                  />
                  <FormHelperText>
                    {errors?.tipo_de_assinatura?.message}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  error={!!errors.incluir_rodape}
                  className={classes.flexItems}
                >
                  <InputLabel>Possibilitar a assinatura digital</InputLabel>
                  <Controller
                    as={
                      <Select>
                        <MenuItem value="">Selecione</MenuItem>
                        <MenuItem key="A" value="A">
                          Sim
                        </MenuItem>
                        <MenuItem key="I" value="I">
                          Não
                        </MenuItem>
                      </Select>
                    }
                    rules
                    control={control}
                    name="incluir_rodape"
                  />
                  <FormHelperText>
                    {errors?.incluir_rodape?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.descricao}
                  className={classes.flexItems}
                >
                  <InputLabel>Descrição</InputLabel>
                  <Controller
                    as={<Input />}
                    rules={{ required: true }}
                    control={control}
                    name="descricao"
                  />
                  <FormHelperText>{errors?.descricao?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  // error={!!errors.is_profissional_de_saude}
                  className={classes.flexItems}
                >
                  <Tooltip
                    arrow
                    placement="top-start"
                    title={`Indica que os documentos vinculados ao tipo de documento, compõem o prontuário. 
                  Documentos do tipo selecionado, serão enviados ao paciente no término da consulta.`}
                    className={classes.tooltipCustomWidth}
                  >
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={
                          <Controller
                            name="pertence_prontuario"
                            as={<Checkbox color="primary" />}
                            control={control}
                            type="checkbox"
                            value={true}
                            disabled={watchFooterEditor === "A" ? false : true}
                          />
                        }
                        defaultValue={false}
                        label="Pertence ao Prontuário?"
                      />
                    </FormGroup>
                  </Tooltip>
                  <FormHelperText>
                    {errors?.pertence_prontuario?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{
                display: watchFooterEditor === "A" ? "unset" : "none",
              }}
            >
              <Grid item xs={12} lg={8} xl={8} className={classes.documentBox}>
                <FormControl fullWidth error={!!errors.rodape_html}>
                  <Typography
                    component="h1"
                    variant="h6"
                    className={classes.documentTitle}
                  >
                    Rodapé de assinatura
                  </Typography>
                  <div id="toolbar-container"></div>
                  <div className="document-editor">
                    <div id="wordCount"></div>
                    <div className="document-editor__toolbar" />
                    <div className="document-editor__editable-container">
                      <div className="document-editor__editable">
                        <CKEditor
                          id="rodape_html"
                          onReady={(editor) => {
                            const toolbarContainer = document.querySelector(
                              "#toolbar-container"
                            );
                            toolbarContainer.appendChild(
                              editor.ui.view.toolbar.element
                            );

                            window.editor = editor;
                            editor.editing.view.change((writer) => {
                              writer.setStyle(
                                "height",
                                "500px",
                                editor.editing.view.document.getRoot()
                              );
                            });

                            const wordCountPlugin = editor.plugins.get(
                              "WordCount"
                            );
                            const wordCountWrapper = document.getElementById(
                              "wordCount"
                            );

                            wordCountWrapper.appendChild(
                              wordCountPlugin.wordCountContainer
                            );
                          }}
                          editor={DecoupledEditor}
                          config={editorConfiguration}
                          data={documentEditor}
                          onChange={handleDocumentEditor}
                        />
                      </div>
                    </div>
                  </div>
                  <FormHelperText>
                    {includeFooter === "A"
                      ? documentEditor.trim() === ""
                        ? errors?.rodape_html?.message
                        : ""
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Box className={classes.flexContainerButton}>
              <Button
                size="large"
                variant="contained"
                type="button"
                color="primary"
                style={{
                  display: watchFooterEditor === "A" ? "" : "none",
                }}
                onClick={handlePreviewFooter}
              >
                Pré-visualizar rodapé
              </Button>
              <Button
                size="large"
                variant="contained"
                type="submit"
                color="primary"
              >
                {isEditing ? "Atualizar" : "Cadastrar"}
              </Button>
            </Box>
          </form>
        </Box>
        <TagsSignModal
          open={openTagsModal}
          handleCloseModal={handleTagsModal}
          editorContent={documentEditor}
          setEditorContent={setDocumentEditor}
        />
        <Fab
          onClick={handleTagsModal}
          variant="extended"
          size="medium"
          color="primary"
          className={classes.fab}
          style={{
            display: watchFooterEditor === "A" ? "" : "none",
          }}
        >
          <LabelIcon />
          Tags
        </Fab>
      </Content>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    flexGrow: 1,
    margin: "unset",
    width: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paperStyle: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  documentTitle: {
    marginBottom: theme.spacing(1),
  },
  documentBox: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.between("lg", "xl")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  flexContainer: {
    gap: "16px",
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  flexContainerTopButtons: {
    marginTop: theme.spacing(2),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "flex-end",
  },
  flexContainerButton: {
    marginTop: theme.spacing(5),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "center",
    width: "100%",
    display: "flex",
    gap: "10px",
    margin: theme.spacing(2, 0),
  },
  editorAndPreviewBox: {
    display: "flex",
    flexBasis: "50%",
    gap: "10px",
  },
  flexItems: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  fab: {
    position: "fixed",
    bottom: 90,
    right: 20,
  },
  fab2: {
    position: "fixed",
    bottom: 40,
    right: 20,
  },
  tooltipCustomWidth: {
    maxWidth: 300,
  },
}));
