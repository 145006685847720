import React, { useState } from "react";
import { Chip, Menu, MenuItem, Tooltip } from "@material-ui/core";
import { updateStatusScheduled } from "../../services/endpoints/scheduling/endpoints";
import { getReasonsMissing, getDescriptionReasonsMissing } from "../../services/endpoints/enums/endpoints";
import {
  defaultAlert,
  confirmationAlert,
} from "../../helpers/validation/alerts";
import { SCHEDULE_STATUS, AVAILABILITY_STATUS } from "../../helpers/constants";

export default function DropDownMenuStatus(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickChipStatus = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseChipStatus = () => {
    setAnchorEl(null);
  };

  async function handleUpdateStatus(scheduleId, cdStatus) {
    setAnchorEl(null);
    switch (cdStatus) {
      case SCHEDULE_STATUS.atendido:
        props.setIsLoading(true);
        confirmationAlert({
          messageBody:
            "Deseja realmente alterar o status deste agendamento para ATENDIDO?",
          footerMessage:
            "<div style='text-align:center'><i>Assim que ATENDIDO, este agendamento não poderá mais ser modificado!</i></div>",
        }).then(async (result) => {
          if (result.value) {
            props.setIsLoading(true);
            handleUpdateStatusSchedule(scheduleId, cdStatus, "Atendido");
          }
        });
        break;

      case SCHEDULE_STATUS.desmarcado:
        props.setIsLoading(true);
        const { data } = await getReasonsMissing();
        props.setIsLoading(false);
        confirmationAlert({
          messageBody:
            "Deseja realmente alterar o status deste agendamento para DESMARCADO?",
          configs: {
            input: "select",
            inputOptions: {
              0: "Selecione o motivo do cancelamento...",
              ...data.list,
            },
          },
          footerMessage:
            "<div style='text-align:center'><i>Assim que DESMARCADO, o paciente receberá um SMS contendo as informações sobre o cancelamento e este agendamento não poderá mais ser modificado!</i></div>",
        }).then(async (result) => {
          if (result.isConfirmed && result.value !== "0") {
            props.setIsLoading(true);
            handleUpdateStatusSchedule(
              scheduleId,
              cdStatus,
              "Desmarcado",
              result.value
            );
          } else {
            defaultAlert(
              "É necessário selecionar um motivo para efetuar o cancelamento.",
              true,
              "Entendi",
              "error",
              "Ops!"
            );
          }
        });
        break;

      case SCHEDULE_STATUS.em_espera:
        props.setIsLoading(true);
        confirmationAlert({
          messageBody:
            "Deseja realmente mudar o status deste agendamento para EM ESPERA?",
          footerMessage:
            "<div style='text-align:center'><i>Assim que EM ESPERA, este agendamento não poderá mais ser modificado!</i></div>",
        }).then(async (result) => {
          if (result.value) {
            props.setIsLoading(true);
            handleUpdateStatusSchedule(scheduleId, cdStatus, "Em espera");
          }
        });
        break;

      case SCHEDULE_STATUS.falta:
        props.setIsLoading(true);
        confirmationAlert({
          messageBody:
            "Deseja realmente mudar o status deste agendamento para FALTA?",
        }).then(async (result) => {
          if (result.value) {
            props.setIsLoading(true);
            handleUpdateStatusSchedule(scheduleId, cdStatus, "Falta");
          }
        });
        break;

      default:
    }
    props.setIsLoading(false);
  }

  async function handleUpdateStatusSchedule(
    scheduleId,
    cdStatus,
    descriptionStatus,
    reasonMissing = null
  ) {
    props.setIsLoading(true);
    const dataForm = {
      cd_status: cdStatus,
      motivo_falta: reasonMissing,
    };
    await updateStatusScheduled(scheduleId, dataForm)
      .then(async (response) => {
        props.setIsLoading(true);
        if (response.data.status) {
          defaultAlert(response.data.msg, true, "OK", "success", "Sucesso!");
          props.setIsLoading(true);
          const scheduleIndex = props.schedule.findIndex(
            (schedule) => schedule.id_agenda === scheduleId
          );
          let newSchedule = [...props.schedule];
          newSchedule[scheduleIndex] = {
            ...newSchedule[scheduleIndex],
            descricao_status: descriptionStatus,
          };
          newSchedule[scheduleIndex] = {
            ...newSchedule[scheduleIndex],
            cd_status: cdStatus,
          };
          if(!!reasonMissing){
            const { data } = await getDescriptionReasonsMissing(reasonMissing);
            newSchedule[scheduleIndex] = {
              ...newSchedule[scheduleIndex],
              motivo_falta: data.motivo,
            };
          }
          props.setSchedule(newSchedule);
          props.setIsLoading(true);
        } else {
          defaultAlert(response.data.msg, true, "OK", "error", "Error!");
        }
        props.setIsLoading(false);
      })
      .catch((error) => {
        props.setIsLoading(false);
      });
  }

  function handleShowReasonMissing(reason) {
    confirmationAlert({
      titleText: " ",
      showCancelButton: false,
      confirmationButtonText: "Entendi",
      htmlMessage: `Este agendamento foi desmarcado<br><br> <b>Motivo:</b> ${reason}<br><br>`,
    });
  }

  const { data } = props;
  return (
    <>
      <Tooltip
        title={
          data.status === AVAILABILITY_STATUS.indisponivel
            ? data.cd_status === SCHEDULE_STATUS.desmarcado
              ? `Motivo: ${data.motivo_falta}`
              : "Alterar status do agendamento"
            : ""
        }
      >
        <Chip
          size="small"
          label={
            data.status === AVAILABILITY_STATUS.indisponivel
              ? data.cd_status
                ? data.descricao_status
                : "Indisponível"
              : data.descricao_status
          }
          color={
            data.status === AVAILABILITY_STATUS.indisponivel
              ? data.cd_status
                ? data.cd_status !== SCHEDULE_STATUS.desmarcado
                  ? data.cd_status !== SCHEDULE_STATUS.atendido
                    ? "primary"
                    : "default"
                  : "default"
                : "default"
              : "primary"
          }
          style={
            data.status === AVAILABILITY_STATUS.disponivel
              ? data.cd_status
                ? {}
                : { borderColor: "green", color: "green" }
              : {}
          }
          variant="outlined"
          clickable={
            data.status === AVAILABILITY_STATUS.indisponivel
              ? data.cd_status
                ? data.cd_status !== SCHEDULE_STATUS.atendido
                  ? true
                  : false
                : false
              : false
          }
          onClick={
            data.status === AVAILABILITY_STATUS.indisponivel
              ? data.cd_status
                ? data.cd_status === SCHEDULE_STATUS.desmarcado
                  ? () => handleShowReasonMissing(data.motivo_falta)
                  : data.cd_status !== SCHEDULE_STATUS.atendido
                  ? handleClickChipStatus
                  : null
                : null
              : null
          }
        />
      </Tooltip>
      <Menu
        id="menu-update-status"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseChipStatus}
      >
        <MenuItem
          onClick={() => {
            handleUpdateStatus(data.id_agenda, SCHEDULE_STATUS.atendido);
          }}
        >
          Atendido
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleUpdateStatus(data.id_agenda, SCHEDULE_STATUS.desmarcado);
          }}
        >
          Desmarcado
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleUpdateStatus(data.id_agenda, SCHEDULE_STATUS.falta);
          }}
        >
          Falta
        </MenuItem>
      </Menu>
    </>
  );
}
