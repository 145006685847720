import api from "../../api";

export const getAllVersionedPrivacyPolicies = async () => {
  return api.get("politica/privacidade");
};

export const createPrivacyPolicy = async (params) => {
  return api.post("politica/privacidade/create", params);
};

export const updatePrivacyPolicy = async (termId, params) => {
  return api.put(`politica/privacidade/update/${termId}`, params);
};
