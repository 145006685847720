import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { defaultAlert } from "../../helpers/validation/alerts";
import {
  recoverPassword,
  recoverLoginUser,
} from "../../services/endpoints/recover/endpoints";

import isValidCpf from "../../helpers/validation/cpfValidator";

export default function RecoverDialog({ open, close, type, isLoading }) {
  const classes = useStyles();
  const [cpf, setCpf] = useState("");
  const [cpfError, setCpfError] = useState(false);

  function handleSendRecoverData() {
    if (!cpfError) {
      isLoading(true);
      if (type === "P") {
        recoverPassword({ cpf: cpf })
          .then(() => {
            defaultAlert(
              "Informações enviadas com sucesso! Você receberá um e-mail com as instruções em alguns instantes!",
              true,
              "Entendi",
              "success",
              "Tudo Certo!"
            );
            setCpf("");
            close();
            isLoading(false);
          })
          .catch(() => {
            isLoading(false);
          });
      } else if (type === "L") {
        isLoading(true);
        recoverLoginUser({ cpf: cpf })
          .then(() => {
            defaultAlert(
              "Informações enviadas com sucesso! Você receberá um e-mail com as instruções em alguns instantes!",
              true,
              "Entendi",
              "success",
              "Tudo Certo!"
            );
            setCpf("");
            close();
            isLoading(false);
          })
          .catch(() => {
            isLoading(false);
          });
      }
    }
  }

  function handleCpfChange(e) {
    setCpf(
      e.target.value
        .trim()
        .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
    );
    validateCpf(e.target.value);
  }

  function validateCpf(cpf) {
    if (cpf.length > 13) {
      setCpfError(!isValidCpf(cpf));
    } else {
      setCpfError(false);
    }
  }

  function handleClose() {
    setCpf("");
    setCpfError(false);
    close("");
  }

  return (
    <>
      <Dialog
        scroll="paper"
        className={classes.backdrop}
        open={open}
        aria-labelledby="Recuperar informações"
        aria-describedby="Caixa de dialogo para recuperação de informações de acesso"
      >
        <DialogTitle id="alert-dialog-title">
          Recuperar Informações de acesso
        </DialogTitle>
        <DialogContent dividers={true}>
          <Typography>
            Digite abaixo o CPF cadastrado para seu usuário. <br />
            As informações de recuperação serão enviadas para o E-mail de
            cadastro.
          </Typography>
          <TextField
            placeholder="000.000.000-00"
            inputProps={{ maxLength: 14 }}
            type="text"
            error={cpfError}
            fullWidth
            label="CPF"
            value={cpf}
            onChange={handleCpfChange}
            helperText={cpfError ? "CPF inválido!" : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancelar
          </Button>
          <GreenButton
            disabled={cpfError || cpf === ""}
            onClick={handleSendRecoverData}
            variant="contained"
            color="primary"
          >
            Enviar
          </GreenButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: "1 !important",
  },
}));

const GreenButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
