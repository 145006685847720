import React, { useEffect, useState } from "react";
import MaterialTable from "@material-table/core";
import { Chip, Icon, Typography, Button, Box } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { green } from "@material-ui/core/colors";
import Content from "../../../components/Content";
import pt_BR from "../../../locale/material-table-locale";
import {
  confirmationAlert,
  defaultAlert,
} from "../../../helpers/validation/alerts";
import {
  getUsers,
  deleteUser,
  updateStatusAccessUser,
} from "../../../services/endpoints/users/endpoints";

export default function UsersList() {
  const classes = useStyles();
  const history = useHistory();

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      await getUsers().then(({ data }) => {
        setUsers(data.list_usuario);
      });
      setIsLoading(false);
    };
    fetchUsers();
    return () => { };
  }, []);

  async function handleDeleteUser(userId) {
    setIsLoading(true);
    confirmationAlert({
      messageBody: "Deseja realmente excluir este usuário?",
      footerMessage:
        "<div style='text-align:center'><i>O paciente não será deletado, para fins de relatórios, registros e rastreabilidade a consulta será possível.</i></div>",
    }).then(async (result) => {
      if (result.value) {
        await deleteUser(userId)
          .then(() => {
            defaultAlert(
              "Usuário excluído!",
              true,
              "OK",
              "success",
              "Sucesso!"
            );
            setUsers((users) =>
              users.filter((user) => user.id_usuario !== userId)
            );
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
          });
      }
      setIsLoading(false);
    });
  }

  function handleUpdateAccess(userId, status) {
    if (status === "A") {
      setIsLoading(true);
      confirmationAlert({
        messageBody: "Deseja realmente bloquear o acesso deste usuário?",
        footerMessage:
          "<div style='text-align:center'><i>Assim que bloqueado, o usuário não conseguirá mais logar-se no sistema!</i></div>",
      }).then(async (result) => {
        if (result.value) {
          await updateStatusAccessUser(userId)
            .then((data) => {
              defaultAlert(
                "Usuário bloqueado!",
                true,
                "OK",
                "success",
                "Sucesso!"
              );
              const userIndex = users.findIndex(
                (user) => user.id_usuario === userId
              );
              let newUsers = [...users];
              newUsers[userIndex] = {
                ...newUsers[userIndex],
                tp_situacao_description: "Bloqueado",
              };
              newUsers[userIndex] = {
                ...newUsers[userIndex],
                tp_situacao: "B",
              };
              setUsers(newUsers);
              setIsLoading(true);
            })
            .catch((error) => {
              setIsLoading(false);
            });
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(true);
      confirmationAlert({
        messageBody: "Deseja realmente desbloquear o acesso deste usuário?",
        footerMessage:
          "<div style='text-align:center'><i>Assim que desbloqueado, o usuário conseguirá logar-se no sistema novamente!</i></div>",
      }).then(async (result) => {
        if (result.value) {
          setIsLoading(true);
          await updateStatusAccessUser(userId)
            .then((data) => {
              defaultAlert(
                "Usuário desbloqueado!",
                true,
                "OK",
                "success",
                "Sucesso!"
              );
              const userIndex = users.findIndex(
                (user) => user.id_usuario === userId
              );
              let newUsers = [...users];
              newUsers[userIndex] = {
                ...newUsers[userIndex],
                tp_situacao_description: "Ativo",
              };
              newUsers[userIndex] = {
                ...newUsers[userIndex],
                tp_situacao: "A",
              };
              setUsers(newUsers);
              setIsLoading(true);
            })
            .catch((error) => {
              setIsLoading(false);
            });
        }
        setIsLoading(false);
      });
    }
  }

  function handleEditUser(idUsuario) {
    history.push(`/users/edit/${idUsuario}`);
  }

  const COLUMNS = [
    { title: "Nome", field: "nome" },
    { title: "Usuário/Login", field: "cd_usuario" },
    { title: "Unidade de saúde", field: "unidade_de_saude" },
    {
      title: "Perfil de acesso",
      render: (data) => (
        <Chip
          size="small"
          label={data.perfil_de_acesso}
          color="primary"
          variant="outlined"
        />
      ),
    },
    {
      title: "Status de acesso",
      render: (data) => (
        <Chip
          size="small"
          label={data.tp_situacao_description}
          color={data.tp_situacao === "A" ? "primary" : "default"}
          variant="outlined"
        />
      ),
    },
    {
      title: "Profissional de Saúde",
      render: (data) =>
        data.is_profissional_de_saude !== "0"
          ? `${data.tp_conselho !== 'Outros' ? data.tp_conselho : data.conselho} (${data.uf_conselho}): ${data.nr_conselho}`
          : "--",
    },
  ];

  const TABLE_ACTIONS_ADMIN = [
    (rowData) => ({
      icon: rowData.tp_situacao === "A" ? "lock_open" : "lock",
      tooltip:
        rowData.tp_situacao === "A"
          ? "Bloquear Usuário"
          : "Desbloquear Usuário",
      onClick: (event, rowData) => {
        handleUpdateAccess(rowData.id_usuario, rowData.tp_situacao);
      },
    }),
    {
      icon: "edit",
      tooltip: "Editar Usuário",
      onClick: (event, rowData) => {
        handleEditUser(rowData.id_usuario);
      },
    },
    {
      icon: "delete",
      tooltip: "Excluir Usuário",
      onClick: (event, rowData) => {
        handleDeleteUser(rowData.id_usuario);
      },
    },
  ];

  return (
    <>
      <Content>
        <MaterialTable
          style={{ margin: "0px 20px", marginBottom: "20px" }}
          title={
            <Box className={classes.titleBox}>
              <Typography variant="h6" component="div">
                Listagem de usuários
              </Typography>
              <GreenButton
                className={classes.newTermButton}
                onClick={() => history.push("/users/new")}
                variant="outlined"
                color="default"
                size="small"
                endIcon={<Icon>add</Icon>}
              >
                Criar
              </GreenButton>
            </Box>
          }
          isLoading={isLoading}
          localization={pt_BR}
          columns={COLUMNS}
          actions={TABLE_ACTIONS_ADMIN}
          data={users}
          options={{
            search: true,
            actionsColumnIndex: -1,
          }}
        />
      </Content>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  newTermButton: {
    marginLeft: theme.spacing(4),
  },
  titleBox: {
    display: "flex",
    maxHeight: "30px",
  },
}));

const GreenButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
