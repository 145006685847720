import * as yup from "yup";
import moment from "moment";
import ptBr from "../../../../locale/yup-translation-pt-BR";
yup.setLocale(ptBr);

const isSameOrBeforeTime = (startTime, endTime) => {
    if(endTime !== "" && endTime !== null){
        return moment(endTime, 'HH:mm').isSameOrBefore(moment(startTime, 'HH:mm'));
    }else{
        return true;
    }
}

const isSameOrBeforeDate = (startTime, endTime) => {
    if(endTime !== "" && endTime !== null){
        return moment(endTime, 'YYYY-MM-DD').isSameOrBefore(moment(startTime, 'YYYY-MM-DD'));
    }else{
        return true;
    }
}

export const CreateSettingScheduleSchema = yup.object().shape({
    id_prestador: yup
        .string()
        .min(1, "Selecionar um profissional de saúde é obrigatório")
        .label("Profissional de saúde")
        .required(),
    data_inicial: yup
        .date()
        .min(moment(new Date(1900, 0, 1)).format("DD/MM/YYYY"))
        .label("Data inicial")
        .required() // eslint-disable-next-line
        .typeError("${path} não contém uma data valida"),
    data_final: yup
        .date()
        .min(moment(new Date(1900, 0, 1)).format("DD/MM/YYYY"))
        .label("Data final")
        .required() // eslint-disable-next-line
        .typeError("${path} não contém uma data valida")
        .test(
            "end_date_test_sun",
            "Data final deve ser maior que a data inicial",
            function (value) {
                const { data_inicial } = this.parent;
                return isSameOrBeforeDate(value, data_inicial);
            }
        ),
    tipo_de_escala: yup
        .string()
        .min(1, "Selecionar o tipo de escala é obrigatório")
        .label("tipo de escala")
        .required(),
    //Validate Sum Row table
    horario_inicial_domingo: yup
        .string()
        .label("Horário inicial")
        .when("domingo", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("domingo", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        }),
    horario_final_domingo: yup
        .string()
        .label("Horário final")
        .when("domingo", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("domingo", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        })
        .test(
            "end_time_test_sun",
            "Horário final deve ser maior que o inicial",
            function (value) {
                const { horario_inicial_domingo } = this.parent;
                return isSameOrBeforeTime(value, horario_inicial_domingo);
            }
        ),
    duracao_domingo: yup
        .string()
        .label("Duração (em minutos)")
        .when("domingo", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("domingo", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        }),
    id_procedimento_domingo: yup
        .string()
        .label("Procedimento")
        .when("domingo", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("domingo", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        }),

    horario_inicial_segunda: yup
        .string()
        .label("Horário inicial")
        .when("segunda", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("segunda", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        }),
    horario_final_segunda: yup
        .string()
        .label("Horário final")
        .when("segunda", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("segunda", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        })
        .test(
            "end_time_test_mon",
            "Horário final deve ser maior que o inicial",
            function (value) {
                const { horario_inicial_segunda } = this.parent;
                return isSameOrBeforeTime(value, horario_inicial_segunda);
            }
        ),
    duracao_segunda: yup
        .string()
        .label("Duração (em minutos)")
        .when("segunda", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("segunda", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        }),
    id_procedimento_segunda: yup
        .string()
        .label("Procedimento")
        .when("segunda", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("segunda", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        }),

    horario_inicial_terca: yup
        .string()
        .label("Horário inicial")
        .when("terca", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("terca", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        }),
    horario_final_terca: yup
        .string()
        .label("Horário final")
        .when("terca", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("terca", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        })
        .test(
            "end_time_test_tue",
            "Horário final deve ser maior que o inicial",
            function (value) {
                const { horario_inicial_terca } = this.parent;
                return isSameOrBeforeTime(value, horario_inicial_terca);
            }
        ),
    duracao_terca: yup
        .string()
        .label("Duração (em minutos)")
        .when("terca", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("terca", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        }),
    id_procedimento_terca: yup
        .string()
        .label("Procedimento")
        .when("terca", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("terca", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        }),

    horario_inicial_quarta: yup
        .string()
        .label("Horário inicial")
        .when("quarta", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("quarta", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        }),
    horario_final_quarta: yup
        .string()
        .label("Horário final")
        .when("quarta", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("quarta", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        })
        .test(
            "end_time_test_wed",
            "Horário final deve ser maior que o inicial",
            function (value) {
                const { horario_inicial_quarta } = this.parent;
                return isSameOrBeforeTime(value, horario_inicial_quarta);
            }
        ),
    duracao_quarta: yup
        .string()
        .label("Duração (em minutos)")
        .when("quarta", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("quarta", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        }),
    id_procedimento_quarta: yup
        .string()
        .label("Procedimento")
        .when("quarta", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("quarta", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        }),

    horario_inicial_quinta: yup
        .string()
        .label("Horário inicial")
        .when("quinta", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("quinta", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        }),
    horario_final_quinta: yup
        .string()
        .label("Horário final")
        .when("quinta", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("quinta", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        })
        .test(
            "end_time_test_thu",
            "Horário final deve ser maior que o inicial",
            function (value) {
                const { horario_inicial_quinta } = this.parent;
                return isSameOrBeforeTime(value, horario_inicial_quinta);
            }
        ),
    duracao_quinta: yup
        .string()
        .label("Duração (em minutos)")
        .when("quinta", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("quinta", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        }),
    id_procedimento_quinta: yup
        .string()
        .label("Procedimento")
        .when("quinta", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("quinta", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        }),

    horario_inicial_sexta: yup
        .string()
        .label("Horário inicial")
        .when("sexta", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("sexta", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        }),
    horario_final_sexta: yup
        .string()
        .label("Horário final")
        .when("sexta", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("sexta", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        })
        .test(
            "end_time_test_fri",
            "Horário final deve ser maior que o inicial",
            function (value) {
                const { horario_inicial_sexta } = this.parent;
                return isSameOrBeforeTime(value, horario_inicial_sexta);
            }
        ),
    duracao_sexta: yup
        .string()
        .label("Duração (em minutos)")
        .when("sexta", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("sexta", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        }),
    id_procedimento_sexta: yup
        .string()
        .label("Procedimento")
        .when("sexta", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("sexta", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        }),

    horario_inicial_sabado: yup
        .string()
        .label("Horário inicial")
        .when("sabado", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("sabado", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        }),
    horario_final_sabado: yup
        .string()
        .label("Horário final")
        .when("sabado", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("sabado", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        })
        .test(
            "end_time_test_sat",
            "Horário final deve ser maior que o inicial",
            function (value) {
                const { horario_inicial_sabado } = this.parent;
                return isSameOrBeforeTime(value, horario_inicial_sabado);
            }
        ),
    duracao_sabado: yup
        .string()
        .label("Duração (em minutos)")
        .when("sabado", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("sabado", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        }),
    id_procedimento_sabado: yup
        .string()
        .label("Procedimento")
        .when("sabado", {
            is: (e) => e !== "" && e !== false,
            then: yup.string().required(),
        })
        .when("sabado", {
            is: (e) => e === true,
            then: yup.string().nullable(),
        }),
});
