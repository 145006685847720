import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  CircularProgress,
  Backdrop,
  Divider,
  MenuItem,
  Select,
  ListItem,
  ListItemText,
  List,
  ClickAwayListener,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputMask from "react-input-mask";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Content from "../../../components/Content";
import { SCHEDULE_STATUS } from "../../../helpers/constants";
import { CreateScheduleSchema } from "../../../helpers/validation/Schemas/SchedulingSchema/schema";
import {
  createPatient,
  updatePatient,
  validatePatientCNS,
  validatePatientCPF,
  getPatientById,
  getPatientByInformation,
} from "../../../services/endpoints/patients/endpoints";
import { getAllProcedure } from "../../../services/endpoints/procedures/endpoints";
import { getProfessional } from "../../../services/endpoints/professionals/endpoints";
import {
  getDatesProfessional,
  getSchedulesProfessional,
} from "../../../services/endpoints/availability/endpoint";
import {
  createSchedule,
  getScheduleById,
  updateSchedule,
  createFitSchedule
} from "../../../services/endpoints/scheduling/endpoints";
import { getReasonsMissing } from "../../../services/endpoints/enums/endpoints";
import {
  defaultAlert,
  confirmationAlert,
} from "../../../helpers/validation/alerts";
import {
  getFormatedDateSystem,
  getFormatedDateUI,
} from "../../../helpers/dateHelpers";
import { isNumeric, getOnlyNumerics } from "../../../helpers/numericHelpers";
import { getCepData } from "../../../services/endpoints/cep/endpoints";

const videoDomain = process.env.REACT_APP_VIDEOIFRAME_DOMAIN;

export default function CreateSchedule(props) {
  const history = useHistory();
  const { id, professional, date, time } = useParams();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEmbed, setIsEmbed] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isScheduleEditing, setIsScheduleEditing] = useState(false);
  const [idPatient, setIdPatient] = useState("");
  const [cpf, setCPF] = useState("");
  const [cns, setCNS] = useState("");
  const [cep, setCEP] = useState("");
  const [celular, setCelular] = useState("");
  const [procedures, setProcedures] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [schedule, setSchedule] = useState({});
  const [pacient, setPacient] = useState({});

  const [openSuggest, setOpenSuggest] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [idProfessional, setIdProfessional] = useState("");
  const [availabilityDays, setAvailabilityDays] = useState([]);
  const [availabilitySchedules, setAvailabilitySchedules] = useState([]);
  const [reasonMissing, setReasonMissing] = useState("");

  const classes = useStyles();

  const {
    register,
    errors,
    handleSubmit,
    control,
    clearError,
    setValue,
    reset,
    watch,
    setError,
    setValues,
    getValues,
  } = useForm({
    validationSchema: CreateScheduleSchema,
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      data_nascimento: "",
      cpf: "",
      cartao_nacional_de_saude: "",
      nome: "",
      nome_social: "",
      celular: "",
      email: "",
      cep: "",
      n_logradouro: "",
      endereco: "",
      bairro: "",
      estado: "",
      cidade: "",
      complemento: "",
      cd_procedimento: "",
      id_prestador: "",
      data: "",
      hora: "",
      cd_status: "A",
    },
  });

  useEffect(() => {
    if (id && pathname === `/scheduling/new/${id}`) {
      setIsLoading(true);
      setIsEditing(true);
      const handleFetchPatient = async () => {
        await getPatientById(id)
          .then(async ({ data }) => {
            const {
              nome,
              nome_social,
              data_nascimento,
              email,
              cpf,
              celular,
              cartao_nacional_de_saude,
              cep,
              n_logradouro,
              endereco,
              bairro,
              cidade,
              estado,
              complemento,
            } = data.paciente;
            setValue([
              { nome },
              { cpf },
              { cartao_nacional_de_saude },
              { nome_social },
              { email },
              { data_nascimento },
              { cep },
              { n_logradouro },
              { cidade },
              { complemento },
              { endereco },
              { bairro },
              { estado },
            ]);
            // campos com máscara
            setCelular(celular);
            setCPF(cpf);
            setCNS(cartao_nacional_de_saude);
            setCEP(cep);
          })
          .catch(() => {
            history.push("/patients/list");
            setIsEditing(false);
          });
        setIsLoading(false);
      };
      handleFetchPatient();
    } else if (id && pathname === `/scheduling/edit/${id}`) {
      setIsLoading(true);
      setIsScheduleEditing(true);
      const handleFetchSchedule = async () => {
        await getScheduleById(id)
          .then(async (result) => {
            const {
              hora,
              data,
              cd_procedimento,
              id_prestador,
              cd_status,
            } = result.data.list_agenda;
            const {
              nome,
              nome_social,
              email,
              data_nascimento,
              celular,
              cpf,
              cartao_nacional_de_saude,
              cep,
              n_logradouro,
              endereco,
              bairro,
              cidade,
              estado,
              complemento,
            } = result.data.list_agenda.paciente;
            setPacient(result.data.list_agenda.paciente);
            if(cd_status === SCHEDULE_STATUS.encaixe){
              setAvailabilityDays([{
                "data": data,
                "atende": true
              }]);
              setAvailabilitySchedules([{
                "horario": hora,
                "atende": true
              }]);
            }else{
              await handleFindDatesProfessional(id_prestador);
              await handleFindScheduleIdProfessional(data, id_prestador);
            }
            setValue([
              { nome },
              { nome_social },
              { email },
              { cpf },
              { cartao_nacional_de_saude },
              { id_prestador },
              { data_nascimento },
              { cep },
              { n_logradouro },
              { cidade },
              { complemento },
              { endereco },
              { bairro },
              { estado },
              { cd_procedimento },
              { cd_status },
              { data },
              { hora },
            ]);
            setIdProfessional(id_prestador);
            //campos com máscara
            setCelular(celular);
            setCPF(cpf);
            setCNS(cartao_nacional_de_saude);
            setCEP(cep);
            setSchedule(result.data.list_agenda);
            if (data && hora) {
              setIsButtonDisabled(false);
            }
          })
          .catch(() => {
            history.push("/patients/list");
            setIsEditing(false);
          });
      };

      handleFetchSchedule();
      
    } else if (
      professional &&
      date &&
      time &&
      pathname === `/scheduling/new/${professional}/${date}/${time}`
    ) {
      const handleApplyDataOnForm = async () => {
        setIsLoading(true);
        setIdProfessional(professional);
        await handleFindDatesProfessional(professional);
        await handleFindScheduleIdProfessional(date, professional);
        setValue("id_prestador", professional);
        setValue("data", date);
        setValue("hora", time);
        setIsLoading(false);
        setIsButtonDisabled(false);
      };
      handleApplyDataOnForm();
    } else if (
      professional &&
      date &&
      time &&
      pathname === `/scheduling/embed/${professional}/${date}/${time}`
    ) {
      const handleApplyDataOnForm = async () => {
        setIsEmbed(true);
        setIsLoading(true);
        setIdProfessional(professional);
        setAvailabilityDays([{
          "data": date,
          "atende": true
        }]);
        setAvailabilitySchedules([{
          "horario": time,
          "atende": true
        }]);
        setValue("id_prestador", professional);
        setValue("data", date);
        setValue("hora", time);
        if(procedures.length === 1){
          setValue("cd_procedimento", procedures[0].id_procedimento);
        }
        setIsLoading(false);
        setIsButtonDisabled(false);
      };
      handleApplyDataOnForm();
    }
    return () => {};
  }, [id, history, setValue, pathname]);

  useEffect(() => {
    if(isEmbed){
      if(procedures.length === 1){
        setValue("cd_procedimento", procedures[0].id_procedimento);
      }
    }
    return () => {};
  }, [isEmbed, procedures])

  useEffect(() => {
    const fetchAllProfessionals = async () => {
      await getProfessional()
        .then(({ data }) => {
          setProfessionals(data.list_prestador);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };
    fetchAllProfessionals();
    return () => {};
  }, []);

  useEffect(() => {
    const fetchProcedures = async () => {
      await getAllProcedure()
        .then(({ data }) => {
          setProcedures(data.list);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };
    fetchProcedures();
    return () => {};
  }, []);

  async function cnsIsValid(data) {
    const jsonCNS = {
      cns: data.cartao_nacional_de_saude,
    };
    return await validatePatientCNS(jsonCNS)
      .then(({ data }) => {
        if (data.status) {
          return data.status;
        } else {
          defaultAlert(data.msg);

          setError("cartao_nacional_de_saude", "notMatch", data.msg);

          return data.status;
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  async function cpfIsValid(data) {
    const jsonCPF = {
      cpf: data.cpf,
    };

    return await validatePatientCPF(jsonCPF)
      .then(({ data }) => {
        if (data.status) {
          return data.status;
        } else {
          defaultAlert(data.msg);
          setError("cpf", "notMatch", data.msg);
          return data.status;
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  async function handleForm(data) {
    setIsLoading(true);
    const userFormData = {
      cpf: data.cpf.replace(/\D+/g, ""),
      cartao_nacional_de_saude: data.cartao_nacional_de_saude,
      data_nascimento: getFormatedDateSystem(data.data_nascimento),
      nome: data.nome,
      nome_social: data.nome_social,
      celular: data.celular,
      email: data.email,
      cep: data.cep,
      n_logradouro: data.n_logradouro,
      cidade: data.cidade,
      complemento: data.complemento,
      endereco: data.endereco,
      bairro: data.bairro,
      estado: data.estado,
    };

    const scheduleFormData = {
      hora: data.hora,
      data: getFormatedDateSystem(data.data),
      id_prestador: data.id_prestador,
      cd_procedimento: data.cd_procedimento,
    };

    const isValid =
      (await cpfIsValid(userFormData)) && (await cnsIsValid(userFormData));
    if (!isScheduleEditing) {
      if (isValid) {
        if (!isEditing) {
          const data = await handleCreatePatient(userFormData);
          if (data) {
            scheduleFormData.id_paciente = data.id_paciente;
          }
        } else {
          scheduleFormData.id_paciente = idPatient;
        }
        handleCreateSchedule(scheduleFormData, userFormData);
      } else {
        setIsLoading(false);
      }
    } else {
      scheduleFormData.cd_status = data.cd_status;
      scheduleFormData.id_prestador = data.id_prestador;
      scheduleFormData.motivo_falta = reasonMissing;
      const isValid = (await cpfIsValid(userFormData)) && (await cnsIsValid(userFormData));
      if (isValid) {
        handleUpdatePatient(pacient.id_paciente, userFormData);
        handleUpdateSchedule(id, scheduleFormData, userFormData);
      } else {
        setIsLoading(false);
      }
    }
  }

  async function handleCreatePatient(data) {
    return createPatient(data)
      .then(({ data }) => {
        if (data.status) {
          return data;
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let { errors } = error.response.data;

          Object.keys(errors).map((erro) => {
            return setError(erro, "notMatch", errors[erro]);
          });

          setIsLoading(false);
          return false;
        }
      });
  }

  async function handleUpdatePatient(idPacient, data) {
    return updatePatient(idPacient, data)
      .then(({ data }) => {
        if (data.status) {
          return data;
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let { errors } = error.response.data;

          Object.keys(errors).map((erro) => {
            return setError(erro, "notMatch", errors[erro]);
          });
          setIsLoading(false);
          return false;
        }
      });
  }

  async function handleCreateSchedule(scheduleFormData, userFormData) {
    setIsLoading(false);
    if(isEmbed){
      confirmationAlert({
        titleText: "Confirma o Encaixe?",
        htmlMessage: `<div style="text-align:center">
        <strong>Informações do Encaixe:</strong>
        <p>
          <span><strong>Paciente:</strong> ${userFormData.nome_social}</span></br>
          <span><strong>Data do Encaixe:</strong> ${getFormatedDateUI(
            scheduleFormData.data
          )}</span></br>
          <span><strong>Horário:</strong> ${scheduleFormData.hora}</span>
          <p>
        </div>
        `,
      }).then((result) => {
        if (result.value) {
          setIsLoading(true);
          createFitSchedule(scheduleFormData)
            .then(({ data }) => {
              if (data.status) {
                defaultAlert(
                  "Encaixe realizado com sucesso!",
                  false,
                  "OK",
                  "success",
                  "Sucesso!"
                );
                history.push("/scheduling/list");
                setIsLoading(false);
                reset();
                setCelular("");
                setCPF("");
                setCNS("");
              }
            })
            .catch((error) => {
              if (error.response.status === 400) {
                let { errors } = error.response.data;

                Object.keys(errors).map((erro) => {
                  return setError(erro, "notMatch", errors[erro]);
                });

                setIsLoading(false);
                return false;
              }
              setIsLoading(false);
            });
        }
      });
    }else{
      confirmationAlert({
        titleText: "Confirma o agendamento?",
        htmlMessage: `<div style="text-align:center">
        <strong>Informações do agendamento:</strong>
        <p>
          <span><strong>Paciente:</strong> ${userFormData.nome_social}</span></br>
          <span><strong>Data do agendamento:</strong> ${getFormatedDateUI(
            scheduleFormData.data
          )}</span></br>
          <span><strong>Horário:</strong> ${scheduleFormData.hora}</span>
          <p>
        </div>
        `,
      }).then((result) => {
        if (result.value) {
          setIsLoading(true);
          createSchedule(scheduleFormData)
            .then(({ data }) => {
              if (data.status) {
                defaultAlert(
                  "Agendamento realizado com sucesso!",
                  false,
                  "OK",
                  "success",
                  "Sucesso!"
                );
                history.push("/scheduling/list");
                setIsLoading(false);
                reset();
                setCelular("");
                setCPF("");
                setCNS("");
              }
            })
            .catch((error) => {
              if (error.response.status === 400) {
                let { errors } = error.response.data;

                Object.keys(errors).map((erro) => {
                  return setError(erro, "notMatch", errors[erro]);
                });

                setIsLoading(false);
                return false;
              }
              setIsLoading(false);
            });
        }
      });
    }
  }

  function handleUpdateSchedule(scheduleId, scheduleFormData, userFormData) {
    setIsLoading(false);
    confirmationAlert({
      titleText: "Confirma as alterações do agendamento?",
      htmlMessage: `<div style="text-align:center"></div>
      <strong>Novas informações do agendamento:</strong>
      <p>
      <span><strong>Paciente:</strong> ${userFormData.nome}</span></br>
      <span><strong>Data do agendamento:</strong> ${getFormatedDateUI(
        scheduleFormData.data
      )}</span></br>
      <span><strong>Horário:</strong> ${scheduleFormData.hora}</span><br>
      <span><strong>Status:</strong> ${handleDescriptionStatus(
        scheduleFormData.cd_status
      )}</span>
      <p>
      `,
    }).then((result) => {
      if (result.value) {
        setIsLoading(true);
        updateSchedule(scheduleId, scheduleFormData)
          .then(({ data }) => {
            if (data.status) {
              defaultAlert(
                "Atualização realizada com sucesso!",
                false,
                "OK",
                "success",
                "Sucesso!"
              );
              history.push("/scheduling/list");
              setIsLoading(false);
              reset();
              setCelular("");
              setCPF("");
              setCNS("");
            } else {
              defaultAlert(data.msg, false, "OK", "error", "Ops!!");
              setIsLoading(false);
            }
          })
          .catch(() => {
            setIsLoading(false);
          });
      }
    });
  }

  function handleChangeCPF(event) {
    setValue("cpf", event.target.value);
    setCPF(event.target.value);
  }

  function handleChangeCelular(event) {
    setValue("celular", event.target.value);
    setCelular(event.target.value);
  }

  function handleChangeCNS(event) {
    setValue("cartao_nacional_de_saude", event.target.value);
    setCNS(event.target.value);
  }

  function handleChangeCEP(event) {
    setValue("cep", event.target.value);
    setCEP(event.target.value);
  }

  async function handleSearch(event) {
    clearError("nome");
    const argument = event.target.value;
    if (argument.length > 4) {
      if (isNumeric(getOnlyNumerics(argument))) {
        if (
          getOnlyNumerics(argument).length === 11 ||
          getOnlyNumerics(argument).length === 15
        ) {
          setOpenSuggest(true);
          getPatientByInformation({
            arg: getOnlyNumerics(argument),
          }).then(({ data }) => {
            if (!data.list_paciente) {
              setSearchResults([]);
            } else {
              setSearchResults(data.list_paciente);
            }
          });
        }
      } else {
        setOpenSuggest(true);
        getPatientByInformation({ arg: argument }).then(({ data }) => {
          if (!data.list_paciente) {
            setSearchResults([]);
          } else {
            setSearchResults(data.list_paciente);
          }
        });
      }
    } else {
      setOpenSuggest(false);
    }
  }

  function handleCEPSearch() {
    if (getValues("cep").length === 9) {
      setIsLoading(true);
      getCepData(getValues("cep"))
        .then(({ data }) => {
          if (data.dados) {
            const { cidade, uf, bairro, endereco, complemento } = data.dados;
            setValue([
              { cidade },
              { estado: uf },
              { endereco },
              { complemento },
              { bairro },
            ]);
            setIsLoading(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }

  async function handleFindDatesProfessional(idProfessional) {
    if (idProfessional) {
      setIsLoading(true);
      setValue("data", "");
      setValue("hora", "");
      setValue("cd_procedimento", "");
      await getDatesProfessional(idProfessional)
        .then(({ data }) => {
          if (data.has_agenda) {
            setIdProfessional(idProfessional);
            setAvailabilityDays(data.dias);
          } else {
            setAvailabilityDays([]);
            setAvailabilitySchedules([]);
            defaultAlert(
              "Profissional de saúde sem nenhuma agenda configurada ou ativa!",
              false,
              "OK",
              "question",
              "Ops!"
            );
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }

  async function handleFindScheduleIdProfessional(date, idProfessional) {
    if (date && idProfessional) {
      setIsLoading(true);
      await getSchedulesProfessional(idProfessional, date)
        .then(({ data }) => {
          if (data.has_horarios) {
            setAvailabilitySchedules(data.horarios);
            setValue("cd_procedimento", data.id_procedimento);
          } else {
            setAvailabilitySchedules([]);
            setValue("cd_procedimento", "");
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }

  function handleSelectSchedule(time) {
    if (time && idProfessional) {
      setIsLoading(false);
    }
  }

  function handleSelectPatient(patientId) {
    setIsLoading(true);
    setIsEditing(true);
    setIdPatient(patientId);
    const handleFetchPatient = async () => {
      await getPatientById(patientId)
        .then(({ data }) => {
          const {
            nome,
            nome_social,
            cpf,
            cartao_nacional_de_saude,
            data_nascimento,
            cep,
            email,
            celular,
            n_logradouro,
            endereco,
            bairro,
            cidade,
            estado,
            complemento,
          } = data.paciente;
          setValue([
            { nome },
            { nome_social },
            { cpf },
            { cartao_nacional_de_saude },
            { data_nascimento },
            { cep },
            { email },
            { celular },
            { n_logradouro },
            { cidade },
            { complemento },
            { endereco },
            { bairro },
            { estado },
          ]);
          setCelular(celular);
          setCPF(cpf);
          setCNS(cartao_nacional_de_saude);
          setCEP(cep);
        })
        .catch(() => {
          history.push("/patients/list");
          setIsEditing(false);
        });
      setIsLoading(false);
    };
    handleFetchPatient();
    clearError("nome");
    setOpenSuggest(false);
  }

  async function handleChekcUpdateStatus(cdStatus) {
    setReasonMissing('');
    switch (cdStatus) {
      case SCHEDULE_STATUS.atendido:
        confirmationAlert({
          messageBody:
            "Deseja realmente alterar o status deste agendamento para ATENDIDO?",
          footerMessage:
            "<div style='text-align:center'><i>Assim que ATENDIDO, este agendamento não poderá mais ser modificado!</i></div>",
        });
        break;

      case SCHEDULE_STATUS.desmarcado:
        setIsLoading(true);
        const { data } = await getReasonsMissing();
        setIsLoading(false);
        confirmationAlert({
          messageBody:
            "Deseja realmente alterar o status deste agendamento para DESMARCADO?",
          configs: {
            input: "select",
            inputOptions: {
              0: "Selecione o motivo do cancelamento...",
              ...data.list,
            },
          },
          footerMessage:
            "<div style='text-align:center'><i>Assim que DESMARCADO, o paciente receberá um SMS contendo as informações sobre o cancelamento e este agendamento não poderá mais ser modificado!</i></div>",
        }).then(async (result) => {
          if (result.isConfirmed && result.value !== "0") {
            setReasonMissing(result.value);
          } else if(result.value === "0") {
            defaultAlert(
              "É necessário selecionar um motivo para efetuar o cancelamento.",
              true,
              "Entendi",
              "error",
              "Ops!"
            );
            setReasonMissing('');
            setValue('cd_status', schedule.cd_status);
          }else{
            setReasonMissing('');
            setValue('cd_status', schedule.cd_status);
          }
        });
        break;

      case SCHEDULE_STATUS.em_espera:
        confirmationAlert({
          messageBody:
            "Deseja realmente mudar o status deste agendamento para EM ESPERA?",
          footerMessage:
            "<div style='text-align:center'><i>Assim que EM ESPERA, este agendamento não poderá mais ser modificado!</i></div>",
        });
        break;

      default:
    }
  }

  function handleDescriptionStatus(cdStatus) {
    switch (cdStatus) {
      case SCHEDULE_STATUS.agendado:
        return "AGENDADO";
        break;
      case SCHEDULE_STATUS.atendido:
        return "ATENDIDO";
        break;

      case SCHEDULE_STATUS.desmarcado:
        return "DESMARCADO";
        break;

      case SCHEDULE_STATUS.em_espera:
        return "EM ESPERA";
        break;
      
      case SCHEDULE_STATUS.encaixe:
        return "ENCAIXE";
        break;

      default:
    }
  }

  return (
    <>
      <Content>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.paperStyle} component={Paper} elevation={2}>
              <Typography component="h2" variant="h6">
                {isScheduleEditing
                  ? "Edição de agendamento"
                  : isEmbed ? "Novo Encaixe" : "Novo Agendamento"}
              </Typography>
              <Typography
                className={classes.subtitles}
                component="h2"
                variant="subtitle2"
              >
                Informações do Paciente
              </Typography>
              <form
                method="POST"
                onSubmit={handleSubmit((data) => handleForm(data))}
              >
                <Box className={classes.flexContainer}>
                  <FormControl
                    error={!!errors.nome}
                    className={classes.flexItems}
                  >
                    <InputLabel shrink={true}>Nome</InputLabel>
                    <Input
                      name="nome"
                      onChange={!isScheduleEditing ? handleSearch : null}
                      inputRef={register()}
                    />
                    <FormHelperText>{errors?.nome?.message}</FormHelperText>
                    {openSuggest && !isScheduleEditing ? (
                      <ClickAwayListener
                        onClickAway={() => setOpenSuggest(false)}
                      >
                        {searchResults.length > 0 ? (
                          <Paper className={classes.searchList}>
                            <List>
                              {searchResults.map((result, index) => (
                                <ListItem
                                  key={result.id_paciente}
                                  onClick={() =>
                                    handleSelectPatient(result.id_paciente)
                                  }
                                  button
                                >
                                  <ListItemText primary={result.nome} />
                                </ListItem>
                              ))}
                            </List>
                          </Paper>
                        ) : (
                          <Typography variant="caption">
                            Nenhum paciente encontrado...
                          </Typography>
                        )}
                      </ClickAwayListener>
                    ) : (
                      ""
                    )}
                  </FormControl>
                  <FormControl
                    error={!!errors.nome_social}
                    className={classes.flexItems}
                  >
                    <InputLabel>Nome Social</InputLabel>
                    <Controller
                      as={<Input />}
                      name="nome_social"
                      control={control}
                    />
                    <FormHelperText>
                      {errors?.nome_social?.message}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box className={classes.flexContainer}>
                  <FormControl
                    error={!!errors.cpf}
                    className={classes.flexItems}
                  >
                    <InputLabel>CPF</InputLabel>
                    <Controller
                      as={
                        <InputMask
                          mask="999.999.999-99"
                          disableUnderline
                          maskChar={null}
                          value={cpf}
                          onChange={handleChangeCPF}
                          onFocus={() => clearError("cpf")}
                        >
                          {() => <Input />}
                        </InputMask>
                      }
                      control={control}
                      name="cpf"
                    />
                    <FormHelperText>{errors?.cpf?.message}</FormHelperText>
                  </FormControl>
                  <FormControl
                    error={!!errors.cartao_nacional_de_saude}
                    className={classes.flexItems}
                  >
                    <InputLabel>Cartão Nacional de Saúde</InputLabel>
                    <Controller
                      as={
                        <InputMask
                          mask="999999999999999"
                          disableUnderline
                          maskChar={null}
                          value={cns}
                          onChange={handleChangeCNS}
                          onFocus={() => clearError("cartao_nacional_de_saude")}
                        >
                          {() => <Input />}
                        </InputMask>
                      }
                      control={control}
                      name="cartao_nacional_de_saude"
                    />
                    <FormHelperText>
                      {errors?.cartao_nacional_de_saude?.message}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box className={classes.flexContainer}>
                  <FormControl
                    error={!!errors.celular}
                    className={classes.flexItems}
                  >
                    <InputLabel>Celular</InputLabel>
                    <InputMask
                      mask="(99)99999-9999"
                      disableUnderline
                      maskChar={null}
                      value={celular}
                      onChange={handleChangeCelular}
                      onFocus={() => clearError("celular")}
                    >
                      {() => <Input name="celular" inputRef={register()} />}
                    </InputMask>
                    <FormHelperText>{errors?.celular?.message}</FormHelperText>
                  </FormControl>
                  <FormControl
                    error={!!errors.data_nascimento}
                    className={classes.flexItems}
                  >
                    <InputLabel shrink={true}>Data de Nascimento</InputLabel>
                    <Input
                      name="data_nascimento"
                      type="date"
                      inputProps={{
                        min: "01/01/2020",
                        max: new Date().toISOString().split("T")[0],
                      }}
                      inputRef={register()}
                    />
                    <FormHelperText>
                      {errors?.data_nascimento?.message}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box className={classes.flexContainer}>
                  <FormControl
                    error={!!errors.cep}
                    className={classes.flexItems}
                  >
                    <InputLabel>CEP</InputLabel>
                    <Controller
                      onBlur={handleCEPSearch}
                      as={
                        <InputMask
                          mask="99999-999"
                          disableUnderline
                          maskChar={null}
                          value={cep}
                          onChange={handleChangeCEP}
                          onFocus={() => clearError("cep")}
                        >
                          {() => <Input />}
                        </InputMask>
                      }
                      control={control}
                      name="cep"
                    />
                    <FormHelperText>{errors?.cep?.message}</FormHelperText>
                  </FormControl>
                  <FormControl
                    error={!!errors.endereco}
                    className={classes.flexItems}
                  >
                    <InputLabel>Logradouro</InputLabel>
                    <Controller
                      as={<Input type="text" />}
                      control={control}
                      name="endereco"
                    />
                    <FormHelperText>{errors?.endereco?.message}</FormHelperText>
                  </FormControl>
                  <FormControl
                    error={!!errors.n_logradouro}
                    className={classes.flexItems}
                  >
                    <InputLabel>Número</InputLabel>
                    <Controller
                      as={<Input type="text" />}
                      control={control}
                      name="n_logradouro"
                    />
                    <FormHelperText>
                      {errors?.n_logradouro?.message}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box className={classes.flexContainer}>
                  <FormControl
                    error={!!errors.bairro}
                    className={classes.flexItems}
                  >
                    <InputLabel>Bairro</InputLabel>
                    <Controller
                      as={<Input type="text" />}
                      control={control}
                      name="bairro"
                    />
                    <FormHelperText>{errors?.bairro?.message}</FormHelperText>
                  </FormControl>
                  <FormControl
                    error={!!errors.cidade}
                    className={classes.flexItems}
                  >
                    <InputLabel>Cidade</InputLabel>
                    <Controller
                      as={<Input type="text" />}
                      control={control}
                      name="cidade"
                    />
                    <FormHelperText>{errors?.cidade?.message}</FormHelperText>
                  </FormControl>
                  <FormControl
                    error={!!errors.estado}
                    className={classes.flexItems}
                  >
                    <InputLabel>Estado</InputLabel>
                    <Controller
                      as={<Input type="text" />}
                      control={control}
                      name="estado"
                    />
                    <FormHelperText>{errors?.estado?.message}</FormHelperText>
                  </FormControl>
                </Box>
                <Box className={classes.flexContainer}>
                  <FormControl
                    error={!!errors.complemento}
                    className={classes.flexItems}
                  >
                    <InputLabel>Complemento</InputLabel>
                    <Controller
                      as={<Input type="text" />}
                      control={control}
                      name="complemento"
                    />
                    <FormHelperText>
                      {errors?.complemento?.message}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box className={classes.flexContainer}>
                  <FormControl
                    error={!!errors.email}
                    className={classes.flexItems}
                  >
                    <InputLabel>Email</InputLabel>
                    <Controller as={<Input />} name="email" control={control} />
                    <FormHelperText>{errors?.email?.message}</FormHelperText>
                  </FormControl>
                </Box>
                <Box className={classes.dividerContainer}>
                  <Divider variant="middle" />
                </Box>
                <Typography
                  className={classes.subtitles}
                  component="h2"
                  variant="subtitle2"
                >
                  {isEmbed ? "Informações do Encaixe" : "Informações do Agendamento"}
                </Typography>
                <Box className={classes.flexContainer}>
                  <FormControl
                    error={!!errors.id_prestador}
                    className={classes.flexItems}
                  >
                    <InputLabel>Profissional de saúde</InputLabel>
                    <Controller
                      onChange={([selected]) => {
                        handleFindDatesProfessional(selected.target.value);
                        return selected;
                      }}
                      as={
                        <Select disabled={isScheduleEditing || professionals.length === 1}>
                          <MenuItem value={""}>Selecione</MenuItem>
                          {professionals.map((type, index) => (
                            <MenuItem
                              key={type.id_prestador}
                              value={type.id_prestador}
                            >
                              {type.prestador}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      control={control}
                      name="id_prestador"
                    />
                    <FormHelperText>
                      {errors?.id_prestador?.message}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    error={!!errors.data}
                    className={classes.flexItems}
                  >
                    <InputLabel>Datas disponíveis</InputLabel>
                    <Controller
                      as={
                        <Select disabled={isScheduleEditing || isEmbed}>
                          {availabilityDays.map((type, index) => (
                            <MenuItem
                              key={type.data}
                              value={type.data}
                              disabled={type.atende ? false : true}
                            >
                              {getFormatedDateUI(type.data)}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      onChange={([selected]) => {
                        handleFindScheduleIdProfessional(
                          selected.target.value,
                          getValues("id_prestador")
                        );
                        return selected;
                      }}
                      control={control}
                      name="data"
                    />
                    <FormHelperText>{errors?.data?.message}</FormHelperText>
                  </FormControl>
                  <FormControl
                    error={!!errors.hora}
                    className={classes.flexItems}
                  >
                    <InputLabel>Horários disponíveis</InputLabel>
                    <Controller
                      onChange={([selected]) => {
                        if (selected.target.value === "") {
                          setIsButtonDisabled(true);
                        } else {
                          setIsButtonDisabled(false);
                        }
                        return selected;
                      }}
                      as={
                        <Select
                          disabled={
                            isScheduleEditing ||
                            availabilitySchedules.length < 1 ||
                            isEmbed
                          }
                        >
                          <MenuItem value={""}>Selecione</MenuItem>
                          {availabilitySchedules.map((type, index) => (
                            <MenuItem
                              key={type.horario}
                              value={type.horario}
                              disabled={type.atende ? false : true}
                            >
                              {type.horario}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      onClick={(e) => {
                        handleSelectSchedule(e.target.value);
                        return e.target.value;
                      }}
                      control={control}
                      name="hora"
                    />
                    <FormHelperText>{errors?.hora?.message}</FormHelperText>
                  </FormControl>
                  <FormControl
                    error={!!errors.cd_procedimento}
                    className={classes.flexItems}
                    style={{ display: !!videoDomain ? '' : 'none' }}
                  >
                    <InputLabel>Procedimento</InputLabel>
                    <Controller
                      as={
                        <Select disabled={isEmbed && procedures.length === 1}>
                          <MenuItem value={""}>Selecione</MenuItem>
                          {procedures.map((type, index) => (
                            <MenuItem
                              key={type.id_procedimento}
                              value={type.id_procedimento}
                            >
                              {type.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      control={control}
                      name="cd_procedimento"
                    />
                    <FormHelperText>
                      {errors?.cd_procedimento?.message}
                    </FormHelperText>
                  </FormControl>

                  <FormControl
                    error={!!errors.cd_status}
                    className={classes.flexItems}
                    style={isScheduleEditing ? {} : { display: "none" }}
                  >
                    <InputLabel>Status do Agendamento</InputLabel>
                    <Controller
                      onChange={([selected]) => {
                        handleChekcUpdateStatus(selected.target.value);
                        return selected;
                      }}
                      as={
                        <Select
                          disabled={
                            isScheduleEditing &&
                            (schedule.cd_status === SCHEDULE_STATUS.desmarcado ||
                              schedule.cd_status === SCHEDULE_STATUS.atendido)
                              ? true
                              : false
                          }
                        >
                          {schedule.cd_status !== SCHEDULE_STATUS.encaixe ?
                          <MenuItem value={SCHEDULE_STATUS.agendado}>
                            AGENDADO
                          </MenuItem> : null }
                          <MenuItem value={SCHEDULE_STATUS.atendido}>
                            ATENDIDO
                          </MenuItem>
                          <MenuItem value={SCHEDULE_STATUS.em_espera}>
                            EM ESPERA
                          </MenuItem>
                          <MenuItem value={SCHEDULE_STATUS.desmarcado}>
                            DESMARCADO
                          </MenuItem>
                          {schedule.cd_status === SCHEDULE_STATUS.encaixe ?
                          <MenuItem value={SCHEDULE_STATUS.encaixe}>
                            ENCAIXE
                          </MenuItem> : null }
                        </Select>
                      }
                      control={control}
                      name="cd_status"
                    />
                    <FormHelperText>
                      {errors?.cd_status?.message}
                    </FormHelperText>
                  </FormControl>
                </Box>

                <Box className={classes.flexContainerButton}>
                  <Button
                    size="large"
                    variant="contained"
                    type="submit"
                    color="primary"
                    style={
                      isScheduleEditing &&
                      (schedule.cd_status === SCHEDULE_STATUS.desmarcado ||
                        schedule.cd_status === SCHEDULE_STATUS.atendido)
                        ? { display: "none" }
                        : {}
                    }
                    disabled={isLoading || isButtonDisabled}
                  >
                    {isScheduleEditing
                      ? "Atualizar Agendamento"
                      : isEmbed ? "Realizar Encaixe" : "Realizar Agendamento"}
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Content>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  searchList: {
    zIndex: 200,
  },
  subtitles: {
    textTransform: "uppercase",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paperStyle: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
  },
  dividerContainer: {
    margin: theme.spacing(2, 0),
  },
  flexContainer: {
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  flexContainerButton: {
    marginTop: theme.spacing(5),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "flex-start",
  },
  flexItems: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
    },
    width: "100%",
  },
  flexItem: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
      maxWidth: "33%",
    },
    width: "100%",
  },
  calendarBox: {
    marginBottom: theme.spacing(2),
    width: "100%",
    marginLeft: 2,
    [theme.breakpoints.between("xs", "sm")]: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      padding: theme.spacing(2),
    },
  },
}));
