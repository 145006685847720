import * as yup from "yup";
import ptBr from "../../../../../locale/yup-translation-pt-BR";
yup.setLocale(ptBr);

export const SmsSchema = yup.object().shape({
  usuario: yup.string().max(100).label("Usuário Zenvia(SMS)").required(),
  senha: yup.string().max(100).label("Senha Zenvia(SMS)").required(),
  msg_agendamento: yup.string().max(160).label("SMS de Agendamento").required(),
  msg_cancelamento: yup
    .string()
    .max(160)
    .label("SMS de Cancelamento")
    .required(),
  msg_reagendamento: yup
    .string()
    .max(160)
    .label("SMS de Reagendamento")
    .required(),
  msg_lembrete_agendamento: yup
    .string()
    .max(160)
    .label("SMS De Lembrete")
    .required(),
  msg_lembrete_agendamento_10: yup
    .string()
    .max(160)
    .label("SMS De Lembrete do dia")
    .required(),
  msg_link_documentos: yup
    .string()
    .max(160)
    .label("SMS De Link de documentos do paciente")
    .required(),
});
