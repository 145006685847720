export function isNumeric(num) {
  if (num) {
    return !isNaN(num);
  } else {
    return false;
  }
}

export function getOnlyNumerics(string) {
  return string.replace(/[^\d]/g, "");
}
