import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Box,
  InputAdornment,
  FilledInput,
  IconButton
} from "@material-ui/core";
import FileCopy from "@material-ui/icons/FileCopy";
import { toastAlertSuccess } from "../../helpers/validation/alerts";
import { makeStyles } from "@material-ui/core/styles";

const PatientRoomLInkDialog = ({ open, close, link }) => {
  const classes = useStyles();

  function handleCopyClipBoard(){
    navigator.clipboard.writeText(link).then(function() {
      toastAlertSuccess("Link copiado!");
    }, function(err) {});
  }

  return (
    <>
      <Dialog
        scroll="paper"
        open={open}
        fullWidth={true}
        maxWidth="sm"
        style={{ zIndex: 1 }}
        aria-labelledby="Convidar paciente para videoconferência"
        aria-describedby="Caixa de dialogo para convidar paciente para videoconferência"
      >
        <DialogTitle id="alert-dialog-title">
          Convidar paciente para videoconferência
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText style={{ justifyContent: "center" }}>
            Compartilhe com paciente o seguinte link para videoconferência
          </DialogContentText>
          <Box className={classes.flexContainer}>
            <FilledInput fullWidth label="" id="outlined-size-small" size="small" value={link}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {handleCopyClipBoard()}}
                >
                  <FileCopy />
                </IconButton>
              </InputAdornment>
            }/>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {close()}}
            color="default"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  gridRoot: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paperStyle: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  flexContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  flexContainerButton: {
    marginTop: theme.spacing(5),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "flex-start",
    width: "100%",
    gap: "10px",
    margin: theme.spacing(2, 0),
  },
  flexItems: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2),
    },
    width: "100%",
  },
}));
export default PatientRoomLInkDialog;
