export const states = [
  {
    sigla: "SP",
  },
  {
    sigla: "RJ",
  },
  {
    sigla: "BA",
  },
  {
    sigla: "SC",
  },
  {
    sigla: "CE",
  },
  {
    sigla: "RS",
  },
  {
    sigla: "SE",
  },
  {
    sigla: "AM",
  },
  {
    sigla: "AC",
  },
  {
    sigla: "MT",
  },
  {
    sigla: "ES",
  },
  {
    sigla: "TO",
  },
  {
    sigla: "MS",
  },
  {
    sigla: "PE",
  },
  {
    sigla: "RN",
  },
  {
    sigla: "DF",
  },
  {
    sigla: "PR",
  },
  {
    sigla: "GO",
  },
  {
    sigla: "PA",
  },
  {
    sigla: "RR",
  },
  {
    sigla: "AP",
  },
  {
    sigla: "MA",
  },
  {
    sigla: "PI",
  },
  {
    sigla: "PB",
  },
  {
    sigla: "AL",
  },
  {
    sigla: "MG",
  },
  {
    sigla: "RO",
  },
];
